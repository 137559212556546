import React from "react";
import { Box, Chip, CircularProgress, SxProps, Theme, Typography } from "@mui/material";
import { IObjectiveItem } from "../../types";
import { FlexBox, SecondaryText, StyledBadge } from "../../styles";
import { useTranslation } from "react-i18next";
import { getObjectiveDescription } from "../../helpers/functions";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CheckBoxIcon from "@mui/icons-material/RadioButtonUnchecked";
import CommentsIcon from "@mui/icons-material/ChatBubbleOutline";

const cardStyle: SxProps = {
  // position: "relative",
  width: "100%",
  height: "100%",
  borderRadius: 3,
};

const contentStyle: SxProps = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const disabledContent: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  opacity: 0.7,
};

const buttonProgress: SxProps = {
  position: "absolute",
  opacity: 0.8,
  mt: 0.5,
  ml: 6,
};

export const objChip: SxProps = {
  "&:hover": {
    opacity: 0.6,
  },
};

export const objTypeChip: SxProps<Theme> = (theme) => ({
  bgcolor: "secondary.light",
  fontWeight: 500,
  px: 0.5,
  color: theme.palette.mode === "dark" ? "black" : undefined,
});

const infoColor: SxProps = {
  opacity: 0.5,
};

interface Props {
  data: IObjectiveItem;
  selected?: number;
  loading?: boolean;
  isAppOnline?: boolean;
  onClick: (data: IObjectiveItem | null) => void;
  onChangeAchieved?: (data: Partial<IObjectiveItem>) => void;
}

const ManualObjectiveCard = ({ data, loading, isAppOnline, onClick, onChangeAchieved }: Props) => {
  const { t } = useTranslation();

  const handleAchieved = () => {
    if (isAppOnline && onChangeAchieved)
      onChangeAchieved({ ...data, objectiveAchieved: !data.objectiveAchieved });
  };

  const renderState = () => {
    if (data.objectiveAchieved)
      return (
        <Box>
          <Chip label={t("labels.reached")} icon={<CheckIcon />} color="success" sx={{ px: 0.5 }} />
        </Box>
      );

    return (
      <Box sx={objChip} onClick={handleAchieved}>
        {loading && <CircularProgress size={24} sx={buttonProgress} />}
        <Chip
          label={t("labels.reached")}
          icon={<CheckBoxIcon />}
          color="default"
          sx={{ px: 0.5, cursor: "pointer" }}
          disabled={loading || !isAppOnline}
        />
      </Box>
    );
  };

  return (
    <Box sx={cardStyle}>
      <Box sx={loading ? disabledContent : contentStyle}>
        <Box sx={{ width: "100%", cursor: "pointer" }} onClick={() => onClick(data)}>
          <FlexBox>
            <Chip label={getObjectiveDescription(data, "type")} size="small" sx={objTypeChip} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledBadge badgeContent="" color="secondary" sx={{ ml: 2 }}>
                <CommentsIcon sx={{ ...infoColor, height: 21, width: 21 }} />
              </StyledBadge>
              <Typography ml={1} sx={infoColor}>
                {data?.comments?.length || 0}
              </Typography>
            </Box>
          </FlexBox>
          <Typography variant="h6" sx={{ fontWeight: 400, mt: 0.5 }}>
            {data.objectiveTypeId === "OT"
              ? data.objectiveDescription
              : getObjectiveDescription(data, "item")}
          </Typography>
          <SecondaryText variant="body1">
            {data.objectiveTypeId !== "OT" ? data.objectiveDescription : "-"}
          </SecondaryText>
        </Box>
        {renderState()}
      </Box>
    </Box>
  );
};

export default ManualObjectiveCard;
