import { SalesState } from "./types";

interface State {
  sales: SalesState;
}

export const userStateSelector = (state: State) => state.sales;
export const segmentsDataSelector = (state: State) => state.sales.segments.data;
export const totalsDataSelector = (state: State) => state.sales.totals.data;
export const dailyTotalsDataSelector = (state: State) => state.sales.dailyTotals.data;
export const formattedSegmentsSelector = (state: State) => state.sales.formattedSegments;
export const isLoadingSelector = (state: State) => state.sales.segments.data;
