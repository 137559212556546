import { IAreaType } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, AreasState } from "./types";

const initialState: AreasState = {
  areas: initialRemoteResource([]),
  loading: false,
};

const areasReducer = (state: AreasState = initialState, action: Actions): AreasState => {
  switch (action.type) {
    /** AREAS */
    case ActionType.getAreasRequested:
      return {
        ...state,
        areas: requestedRemoteResource([]),
        loading: true,
      };
    case ActionType.getAreasDone:
      return {
        ...state,
        areas: doneRemoteResource(action.payload.areas),
        loading: false,
      };
    case ActionType.getAreasError:
      return {
        ...state,
        areas: errorRemoteResource([]),
        loading: false,
      };
    case ActionType.setAreasRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.setAreasDone:
      return {
        ...state,
        areas: doneRemoteResource(action.payload.areas as IAreaType[]),
        loading: false,
      };
    case ActionType.setAreasError:
      return {
        ...state,
        loading: false,
      };
    /** CLEAR DATA */
    case ActionType.clearData:
      return {
        ...state,
        areas: initialRemoteResource([]),
        loading: false,
      };

    default:
      return state;
  }
};

export default areasReducer;
