import { shiftApi } from "../config/axiosInstances";
import { PromiseResult, promiseResultWrapper } from "../helpers/functions";
import {
  IManager,
  IQueryParams,
  IActivityItem,
  IObjectiveItem,
  INoteItem,
  ICommentItem,
  IHourlyReviewItem,
} from "../types";
import { ApiResponse, MyActivitiesResponse } from "./interfaces";

export interface ManagersResponse {
  data: IManager;
}

export interface GetCheckListResponse {
  data: IActivityItem[];
}

export interface GetObjectivesResponse {
  data: IObjectiveItem[];
}

export interface ObjectivesResponse {
  data: IObjectiveItem;
}

export interface ObjectiveCommentsResponse {
  data: ICommentItem;
}

export interface GetNotesResponse {
  data: INoteItem[];
}

export interface NotesResponse {
  data: INoteItem;
}

export interface NoteCommentsResponse {
  data: ICommentItem;
}

class ActivityServices {
  static async getCheckList(params: IQueryParams): Promise<PromiseResult<GetCheckListResponse>> {
    const { storePlanId, activityTypesId } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.get("/plan_checklist", { params: { storePlanId, activityTypesId } })
    );
  }

  static async setCheckList(
    data: IActivityItem[],
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const body = data.map((e) => ({ id: e.id, checked: e.checked }));
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put("/plan_checklist", {
        body,
        params,
      })
    );
  }

  static async setMyActivities(
    data: IActivityItem[],
    params: IQueryParams
  ): Promise<PromiseResult<MyActivitiesResponse>> {
    return promiseResultWrapper<MyActivitiesResponse>(() =>
      shiftApi.put("/plan_custom_checklist", {
        body: data,
        params,
      })
    );
  }

  static async getObjectives(params: IQueryParams): Promise<PromiseResult<GetObjectivesResponse>> {
    return promiseResultWrapper<GetObjectivesResponse>(() =>
      shiftApi.get("/store_objectives", { params })
    );
  }

  static async addObjective(
    objective: Partial<IObjectiveItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ObjectivesResponse>> {
    const { shiftTypeId, storePlanId, username } = params;
    return promiseResultWrapper<ObjectivesResponse>(() =>
      shiftApi.post(`/store_objectives`, {
        body: objective,
        params: { shiftTypeId, storePlanId, username },
      })
    );
  }

  static async updateObjective(
    objective: Partial<IObjectiveItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const id = objective.id;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/store_objectives/${id}`, {
        body: objective,
        params,
      })
    );
  }

  static async deleteObjective(
    id: number,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { shiftTypeId, storePlanId, username } = params;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.delete(`/store_objectives/${id}`, { params: { shiftTypeId, storePlanId, username } })
    );
  }

  static async addObjectiveComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ObjectiveCommentsResponse>> {
    const { shiftTypeId, username, country, storeAcronym } = params;
    return promiseResultWrapper<ObjectiveCommentsResponse>(() =>
      shiftApi.post(`/objectives_comments`, {
        body: comment,
        params: { shiftTypeId, username, country, storeAcronym },
      })
    );
  }

  static async updateObjectiveComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { id, description, lastUpdate } = comment;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/objectives_comments/${id}`, {
        body: { description, lastUpdate },
        params,
      })
    );
  }

  static async deleteObjectiveComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { id } = comment;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.delete(`/objectives_comments/${id}`, { params })
    );
  }

  static async updateHourlyReview(
    hourlyReview: Partial<IHourlyReviewItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const id = hourlyReview.id;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/hourly_review/${id}`, {
        body: hourlyReview,
        params,
      })
    );
  }

  static async getNotes(params: IQueryParams): Promise<PromiseResult<GetNotesResponse>> {
    return promiseResultWrapper<GetNotesResponse>(() => shiftApi.get("/notes", { params }));
  }

  static async addNote(
    note: Partial<INoteItem>,
    params: IQueryParams
  ): Promise<PromiseResult<NotesResponse>> {
    const { shiftTypeId, username, country, storeAcronym } = params;
    return promiseResultWrapper<NotesResponse>(() =>
      shiftApi.post(`/notes`, {
        body: note,
        params: { shiftTypeId, username, country, storeAcronym },
      })
    );
  }

  static async updateNote(
    note: Partial<INoteItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { id, state, lastUpdateDate, description, priority } = note;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/notes/${id}`, {
        body: { state, lastUpdateDate, description, priority },
        params,
      })
    );
  }

  static async deleteNote(id: number, params: IQueryParams): Promise<PromiseResult<ApiResponse>> {
    const { shiftTypeId, storePlanId, username } = params;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.delete(`/notes/${id}`, { params: { shiftTypeId, storePlanId, username } })
    );
  }

  static async addNoteComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<NoteCommentsResponse>> {
    const { shiftTypeId, username, country, storeAcronym } = params;
    return promiseResultWrapper<NoteCommentsResponse>(() =>
      shiftApi.post(`/comments`, {
        body: comment,
        params: { shiftTypeId, username, country, storeAcronym },
      })
    );
  }

  static async updateNoteComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { id, description, lastUpdate } = comment;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/comments/${id}`, {
        body: { description, lastUpdate },
        params,
      })
    );
  }

  static async deleteNoteComment(
    comment: Partial<ICommentItem>,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const { id } = comment;
    return promiseResultWrapper<ApiResponse>(() => shiftApi.delete(`/comments/${id}`, { params }));
  }
}

export default ActivityServices;
