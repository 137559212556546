import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { IdTokenClaims } from "@azure/msal-common";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { IHttpRequestError } from "../types";
import { msalInstance, loginRequest } from "./auth.config";

const ID_TOKEN_CLAIMS = "id-token-claims";

const getNormalizedError = (axiosError: AxiosError): IHttpRequestError => {
  const data = axiosError.response || { data: {}, status: -1 };
  return {
    notified: false,
    code: data.status,
    data: data.data,
    innerException: axiosError,
  };
};

const errorResponseHandler = (originalError: AxiosError): Promise<AxiosError> => {
  const error: IHttpRequestError = getNormalizedError(originalError);
  return Promise.reject(error);
};

function forceRefresh(currentTime: number, exp?: number) {
  if (!exp) return true;
  if (exp - currentTime < 600) return true;
  else return false;
}

const setIdTokenClaims = (itc: IdTokenClaims) => {
  localStorage.setItem(ID_TOKEN_CLAIMS, JSON.stringify(itc));
};

const getIdTokenClaims = (): IdTokenClaims | null =>
  JSON.parse(localStorage.getItem(ID_TOKEN_CLAIMS) || "null");

const requestHandler = async (config: InternalAxiosRequestConfig) => {
  let result: AuthenticationResult | null = null;
  try {
    const currentTime = new Date().getTime() / 1000; // seconds
    const itc = getIdTokenClaims();
    result = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      forceRefresh: forceRefresh(currentTime, itc?.exp),
    });

    setIdTokenClaims(result.idTokenClaims);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      result = await msalInstance.acquireTokenPopup(loginRequest);
    }
  }
  if (!result) throw new Error("token-error");
  if (config && config.headers) {
    const { accessToken, idToken } = result;
    config.headers["access-token"] = accessToken;
    config.headers.Authorization = `Bearer ${idToken}`;
    config.headers.appId = "miturno";
  }
  return config;
};

export const rsApi = axios.create({
  baseURL: process.env.REACT_APP_RS_API_URL,
});
rsApi.interceptors.response.use((response) => response, errorResponseHandler);
rsApi.interceptors.request.use(requestHandler, Promise.reject);

export const shiftApi = axios.create({
  baseURL: process.env.REACT_APP_MITURNO_API_URL,
});
shiftApi.interceptors.response.use((response) => response, errorResponseHandler);
shiftApi.interceptors.request.use(requestHandler, Promise.reject);

const sosRequestHandler = async (config: InternalAxiosRequestConfig) => {
  let result: AuthenticationResult | null = null;
  try {
    const currentTime = new Date().getTime() / 1000; // seconds
    const itc = getIdTokenClaims();
    result = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      forceRefresh: forceRefresh(currentTime, itc?.exp),
    });

    setIdTokenClaims(result.idTokenClaims);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      result = await msalInstance.acquireTokenPopup(loginRequest);
    }
  }
  if (!result) throw new Error("token-error");
  if (config && config.headers) {
    const { accessToken, idToken } = result;
    config.headers["access-token"] = accessToken;
    config.headers.Authorization = `Bearer ${idToken}`;
    config.headers.appId = "sos";
  }
  return config;
};

export const sosApi = axios.create({
  baseURL: process.env.REACT_APP_SOS_API_URL,
});
sosApi.interceptors.response.use((response) => response, errorResponseHandler);
sosApi.interceptors.request.use(sosRequestHandler, Promise.reject);
