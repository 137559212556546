import React from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Paper,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NoteCard from "./cards/NoteCard";
import { INoteItem } from "../types";
import { FlexBox } from "../styles";
import { Droppable } from "react-beautiful-dnd";

const loader: SxProps = {
  position: "absolute",
  left: "50%",
  opacity: 0.8,
};

const mobileStyle = {
  overflow: "auto",
  height: "100%",
  minHeight: 100,
  display: "flex",
  paddingBottom: "12px",
};

const desktopStyle = {
  overflow: "auto",
  height: "calc(100vh - 238px)",
  display: "block",
};

interface Props {
  notes: INoteItem[];
  title: string;
  state: "active" | "pending" | "done";
  loading?: boolean;
  onClick: (note?: INoteItem) => void;
}

const NoteList = ({ notes, title, state, loading, onClick }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const getStateColor = () => {
    switch (state) {
      case "pending":
        return "secondary.main";
      case "active":
        return "info.light";
      case "done":
        return "success.light";
      default:
        return undefined;
    }
  };

  return (
    <Box component={Paper} p={2} pt={0}>
      <FlexBox pt={1} sx={{ position: "relative" }}>
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        <Avatar sx={{ width: 20, height: 20, ml: 1 }}>
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            {notes.length}
          </Typography>
        </Avatar>
        {loading && <CircularProgress size={18} sx={loader} />}
      </FlexBox>
      {state && <Divider sx={{ bgcolor: getStateColor(), height: 3, mt: 1, mb: 2 }} />}

      <Droppable droppableId={`${state}`}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={isMobile ? mobileStyle : desktopStyle}
          >
            {notes.map((note, index) => (
              <Box my={1} mx={2} key={`note-list-${note.id}`} sx={{ minWidth: 320 }}>
                <NoteCard data={note} state={state} index={index} onClick={onClick} />
              </Box>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};

export default NoteList;
