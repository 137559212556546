import { IShiftSchedules, IScheduleItem } from "../../../types";
import {
  ActionType,
  GetSchedulesRequestedAction,
  GetSchedulesDoneAction,
  GetSchedulesErrorAction,
  GetSchedulesPayload,
  SetSchedulesRequestedAction,
  SetSchedulesDoneAction,
  SetSchedulesErrorAction,
  SetSchedulesPayload,
  SetCurrentScheduleAction,
  GetClearDataAction,
} from "./types";

/** SCHEDULES */

export const getSchedulesRequested = (): GetSchedulesRequestedAction => {
  return { type: ActionType.getSchedulesRequested };
};

export const getSchedulesDone = (result: GetSchedulesPayload): GetSchedulesDoneAction => {
  return { type: ActionType.getSchedulesDone, payload: result };
};

export const getSchedulesError = (): GetSchedulesErrorAction => {
  return { type: ActionType.getSchedulesError };
};

export const setSchedulesRequested = (schedules: IShiftSchedules): SetSchedulesRequestedAction => {
  return { type: ActionType.setSchedulesRequested, payload: { schedules } };
};

export const setSchedulesDone = (result: SetSchedulesPayload): SetSchedulesDoneAction => {
  return { type: ActionType.setSchedulesDone, payload: result };
};

export const setSchedulesError = (): SetSchedulesErrorAction => {
  return { type: ActionType.setSchedulesError };
};

export const setCurrentSchedule = (result: {
  schedule: IScheduleItem[];
}): SetCurrentScheduleAction => {
  return { type: ActionType.setCurrentSchedule, payload: result };
};

/** CLEAR DATA */

export const clearData = (): GetClearDataAction => {
  return { type: ActionType.clearData };
};
