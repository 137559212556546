import { INoteItem, ICommentItem } from "../../../types";
import {
  ActionType,
  AddNoteDoneAction,
  AddNoteErrorAction,
  AddNotePayload,
  AddNoteRequestedAction,
  DeleteNoteDoneAction,
  DeleteNoteErrorAction,
  DeleteNotePayload,
  DeleteNoteRequestedAction,
  GetNotesDoneAction,
  GetNotesErrorAction,
  GetNotesPayload,
  GetNotesRequestedAction,
  UpdateNoteDoneAction,
  UpdateNoteErrorAction,
  UpdateNotePayload,
  UpdateNoteRequestedAction,
  GetNotesPayloadRequest,
  ToggleNoteModalAction,
  SetSelectedNoteAction,
  AddCommentDoneAction,
  AddCommentErrorAction,
  AddCommentPayload,
  AddCommentRequestedAction,
  DeleteCommentDoneAction,
  DeleteCommentErrorAction,
  DeleteCommentPayload,
  DeleteCommentRequestedAction,
  UpdateCommentDoneAction,
  UpdateCommentErrorAction,
  UpdateCommentPayload,
  UpdateCommentRequestedAction,
  ClearNotesAction,
} from "./types";

/** NOTES */

export const toggleNoteModal = (): ToggleNoteModalAction => ({
  type: ActionType.toggleNoteModal,
});

export const setSelectedNote = (result: { note: INoteItem | null }): SetSelectedNoteAction => {
  return { type: ActionType.setSelectedNote, payload: result };
};

export const getNotesRequested = (payload: GetNotesPayloadRequest): GetNotesRequestedAction => {
  return { type: ActionType.getNotesRequested, payload };
};

export const getNotesDone = (result: GetNotesPayload): GetNotesDoneAction => {
  return { type: ActionType.getNotesDone, payload: result };
};

export const getNotesError = (): GetNotesErrorAction => {
  return { type: ActionType.getNotesError };
};

export const addNoteRequested = (note: Partial<INoteItem>): AddNoteRequestedAction => {
  return { type: ActionType.addNoteRequested, payload: { note } };
};

export const addNoteDone = (result: AddNotePayload): AddNoteDoneAction => {
  return { type: ActionType.addNoteDone, payload: result };
};

export const addNoteError = (): AddNoteErrorAction => {
  return { type: ActionType.addNoteError };
};

export const updateNoteRequested = (note: Partial<INoteItem>): UpdateNoteRequestedAction => {
  return { type: ActionType.updateNoteRequested, payload: { note } };
};

export const updateNoteDone = (result: UpdateNotePayload): UpdateNoteDoneAction => {
  return { type: ActionType.updateNoteDone, payload: result };
};

export const updateNoteError = (): UpdateNoteErrorAction => {
  return { type: ActionType.updateNoteError };
};

export const deleteNoteRequested = (id: number): DeleteNoteRequestedAction => {
  return { type: ActionType.deleteNoteRequested, payload: { id } };
};

export const deleteNoteDone = (result: DeleteNotePayload): DeleteNoteDoneAction => {
  return { type: ActionType.deleteNoteDone, payload: result };
};

export const deleteNoteError = (): DeleteNoteErrorAction => {
  return { type: ActionType.deleteNoteError };
};

/** COMMENTS */

export const addCommentRequested = (comment: Partial<ICommentItem>): AddCommentRequestedAction => {
  return { type: ActionType.addCommentRequested, payload: { comment } };
};

export const addCommentDone = (result: AddCommentPayload): AddCommentDoneAction => {
  return { type: ActionType.addCommentDone, payload: result };
};

export const addCommentError = (): AddCommentErrorAction => {
  return { type: ActionType.addCommentError };
};

export const updateCommentRequested = (
  comment: Partial<ICommentItem>
): UpdateCommentRequestedAction => {
  return { type: ActionType.updateCommentRequested, payload: { comment } };
};

export const updateCommentDone = (result: UpdateCommentPayload): UpdateCommentDoneAction => {
  return { type: ActionType.updateCommentDone, payload: result };
};

export const updateCommentError = (): UpdateCommentErrorAction => {
  return { type: ActionType.updateCommentError };
};

export const deleteCommentRequested = (
  comment: Partial<ICommentItem>
): DeleteCommentRequestedAction => {
  return { type: ActionType.deleteCommentRequested, payload: { comment } };
};

export const deleteCommentDone = (result: DeleteCommentPayload): DeleteCommentDoneAction => {
  return { type: ActionType.deleteCommentDone, payload: result };
};

export const deleteCommentError = (): DeleteCommentErrorAction => {
  return { type: ActionType.deleteCommentError };
};

export const clearNotes = (): ClearNotesAction => ({
  type: ActionType.clearNotes,
});
