import { IAreaType } from "../../../types";
import {
  ActionType,
  GetAreasRequestedAction,
  GetAreasDoneAction,
  GetAreasErrorAction,
  GetAreasPayload,
  SetAreasRequestedAction,
  SetAreasDoneAction,
  SetAreasErrorAction,
  SetAreasPayload,
  GetClearDataAction,
} from "./types";

/** AREAS */

export const getAreasRequested = (): GetAreasRequestedAction => {
  return { type: ActionType.getAreasRequested };
};

export const getAreasDone = (result: GetAreasPayload): GetAreasDoneAction => {
  return { type: ActionType.getAreasDone, payload: result };
};

export const getAreasError = (): GetAreasErrorAction => {
  return { type: ActionType.getAreasError };
};

export const setAreasRequested = (areas: IAreaType[]): SetAreasRequestedAction => {
  return { type: ActionType.setAreasRequested, payload: { areas } };
};

export const setAreasDone = (result: SetAreasPayload): SetAreasDoneAction => {
  return { type: ActionType.setAreasDone, payload: result };
};

export const setAreasError = (): SetAreasErrorAction => {
  return { type: ActionType.setAreasError };
};

/** CLEAR DATA */

export const clearData = (): GetClearDataAction => {
  return { type: ActionType.clearData };
};
