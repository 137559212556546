import { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import SidebarNav from "./SidebarNav";
import Copyright from "./Copyright";
import { useSelector } from "react-redux";
import { isSidebarOpenSelector } from "../redux/app/selectors";
import { useNavigate } from "react-router-dom";
import { routes } from "../router/routes";
import { IManager, UserRol } from "../types";
import SidebarNavMobile from "./SidebarNavMobile";

const drawerWidth = 200;

const ListContainer = styled("div")({
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, property }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: property ? drawerWidth : "1px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: property ? theme.spacing(7) : "1px",
      [theme.breakpoints.up("sm")]: {
        width: property ? theme.spacing(7) : "1px",
      },
    }),
  },
}));

function getDrawerHeight(rol?: UserRol) {
  if (!rol) return 260;
  if (rol === "manager") return 365;
  if (rol === "admin") return 300;
}

interface Props {
  isAppOnline: boolean;
  user?: IManager;
  showSidebar?: true | undefined;
}

const Sidebar = ({ isAppOnline, user, showSidebar }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:480px)");
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const navigate = useNavigate();

  const drawerHeight = getDrawerHeight(user?.rol);

  useEffect(() => {
    if (showSidebar && user?.rol === "manager") navigate(routes.main.activitiesDashboard.path);
    // eslint-disable-next-line
  }, [showSidebar]);

  if (isMobile)
    return (
      <Drawer
        variant={"permanent"}
        open={isSidebarOpen}
        property={showSidebar && "showSidebar"}
        anchor="bottom"
        sx={{
          "& .MuiDrawer-paper": {
            whiteSpace: "nowrap",
            height: isSidebarOpen ? drawerHeight : "1px",
            transition: theme.transitions.create("height", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            ...(!isSidebarOpen && {
              overflowX: "hidden",
              transition: theme.transitions.create("height", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              height: showSidebar ? 50 : "1px",
              width: "100%",
            }),
          },
        }}
      >
        <ListContainer>
          <SidebarNavMobile />
          {isSidebarOpen && <Copyright yearSecondLine />}
        </ListContainer>
      </Drawer>
    );
  else
    return (
      <DrawerStyled
        variant={"permanent"}
        open={isSidebarOpen}
        property={showSidebar && "showSidebar"}
      >
        <Toolbar />
        <ListContainer>
          <SidebarNav isAppOnline={isAppOnline} />
          {isSidebarOpen && <Copyright yearSecondLine />}
        </ListContainer>
      </DrawerStyled>
    );
};

export default Sidebar;
