import React from "react";
import { TextField, Box, Autocomplete, Theme, useMediaQuery } from "@mui/material";
import { IStore } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { setFilters } from "../../redux/admin/masterdata/actions";
import { useTranslation } from "react-i18next";

interface Props {
  options: IStore[];
}

const StoreSelector = ({ options }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const disabled = !Boolean(filters.division) || !Boolean(filters.country);

  const handleChange = (value: IStore) => {
    dispatch(setFilters({ filters: { ...filters, store: value } }));
  };

  return (
    <Box mb={1} mx={2} width={"100%"}>
      <Autocomplete
        disablePortal
        id="store-selector"
        value={filters.store || null}
        options={options}
        getOptionLabel={(option) => option.storeAcronym}
        renderInput={(params) => (
          <TextField variant="standard" {...params} label={t("labels.store")} />
        )}
        onChange={(e, value) => value && handleChange(value)}
        disabled={disabled}
        sx={{ minWidth: isMobile ? 110 : 150 }}
        size="small"
      />
    </Box>
  );
};

export default StoreSelector;
