import { IActivityItem, ICustomChecklistItem } from "../../../types";
import {
  ActionType,
  GetChecklistsRequestedAction,
  GetChecklistsDoneAction,
  GetChecklistsErrorAction,
  GetChecklistsPayload,
  AddChecklistRequestedAction,
  AddChecklistDoneAction,
  AddChecklistErrorAction,
  AddChecklistPayload,
  UpdateChecklistRequestedAction,
  UpdateChecklistDoneAction,
  UpdateChecklistErrorAction,
  UpdateChecklistPayload,
  DeleteChecklistRequestedAction,
  DeleteChecklistDoneAction,
  DeleteChecklistErrorAction,
  DeleteChecklistPayload,
  GetClearDataAction,
  AddActivitiesDoneAction,
  AddActivitiesErrorAction,
  AddActivitiesPayload,
  AddActivitiesRequestedAction,
  DeleteActivitiesDoneAction,
  DeleteActivitiesErrorAction,
  DeleteActivitiesPayload,
  DeleteActivitiesRequestedAction,
} from "./types";

/** CHECKLIST */

export const getChecklistsRequested = (): GetChecklistsRequestedAction => {
  return { type: ActionType.getChecklistsRequested };
};

export const getChecklistsDone = (result: GetChecklistsPayload): GetChecklistsDoneAction => {
  return { type: ActionType.getChecklistsDone, payload: result };
};

export const getChecklistsError = (): GetChecklistsErrorAction => {
  return { type: ActionType.getChecklistsError };
};

export const addChecklistRequested = (
  checklist: ICustomChecklistItem
): AddChecklistRequestedAction => {
  return { type: ActionType.addChecklistRequested, payload: { checklist } };
};

export const addChecklistDone = (result: AddChecklistPayload): AddChecklistDoneAction => {
  return { type: ActionType.addChecklistDone, payload: result };
};

export const addChecklistError = (): AddChecklistErrorAction => {
  return { type: ActionType.addChecklistError };
};

export const updateChecklistRequested = (
  checklist: Partial<ICustomChecklistItem>,
  ignoreFetch?: boolean
): UpdateChecklistRequestedAction => {
  return { type: ActionType.updateChecklistRequested, payload: { checklist, ignoreFetch } };
};

export const updateChecklistDone = (result: UpdateChecklistPayload): UpdateChecklistDoneAction => {
  return { type: ActionType.updateChecklistDone, payload: result };
};

export const updateChecklistError = (): UpdateChecklistErrorAction => {
  return { type: ActionType.updateChecklistError };
};

export const deleteChecklistRequested = (id: number): DeleteChecklistRequestedAction => {
  return { type: ActionType.deleteChecklistRequested, payload: { id } };
};

export const deleteChecklistDone = (result: DeleteChecklistPayload): DeleteChecklistDoneAction => {
  return { type: ActionType.deleteChecklistDone, payload: result };
};

export const deleteChecklistError = (): DeleteChecklistErrorAction => {
  return { type: ActionType.deleteChecklistError };
};

/** ACTIVITIES */

export const addActivitiesRequested = (
  activities: Partial<IActivityItem>[],
  customChecklistId: number
): AddActivitiesRequestedAction => {
  return { type: ActionType.addActivitiesRequested, payload: { activities, customChecklistId } };
};

export const addActivitiesDone = (result: AddActivitiesPayload): AddActivitiesDoneAction => {
  return { type: ActionType.addActivitiesDone, payload: result };
};

export const addActivitiesError = (): AddActivitiesErrorAction => {
  return { type: ActionType.addActivitiesError };
};

export const deleteActivitiesRequested = (
  ids: number[],
  customChecklistId: number,
): DeleteActivitiesRequestedAction => {
  return { type: ActionType.deleteActivitiesRequested, payload: { ids, customChecklistId, } };
};

export const deleteActivitiesDone = (
  result: DeleteActivitiesPayload
): DeleteActivitiesDoneAction => {
  return { type: ActionType.deleteActivitiesDone, payload: result };
};

export const deleteActivitiesError = (): DeleteActivitiesErrorAction => {
  return { type: ActionType.deleteActivitiesError };
};

/** CLEAR DATA */

export const clearData = (): GetClearDataAction => {
  return { type: ActionType.clearData };
};
