import { Action } from "@reduxjs/toolkit";
import { RemoteResource } from "../../utils";
import { INoteItem, ICommentItem } from "../../../types";
import { ActionPayload } from "../../actions";

export interface NotesState {
  notes: RemoteResource<INoteItem[]>;
  lastRequest: GetNotesPayloadRequest;
  noteModalOpen: boolean;
  selectedNote: INoteItem | null;
  loadingNote: boolean;
  loadingComment: boolean;
  notesError: boolean;
  commentError: boolean;
}

export enum ActionType {
  getNotesRequested = "notes/get-notes-requested",
  toggleNoteModal = "notes/toggle-note-modal",
  setSelectedNote = "notes/set-selected-note",
  getNotesDone = "notes/get-notes-done",
  getNotesError = "notes/get-notes-error",
  addNoteRequested = "notes/add-note-requested",
  addNoteDone = "notes/add-note-done",
  addNoteError = "notes/add-note-error",
  updateNoteRequested = "notes/update-note-requested",
  updateNoteDone = "notes/update-note-done",
  updateNoteError = "notes/update-note-error",
  deleteNoteRequested = "notes/delete-note-requested",
  deleteNoteDone = "notes/delete-note-done",
  deleteNoteError = "notes/delete-note-error",
  addCommentRequested = "notes/add-comment-requested",
  addCommentDone = "notes/add-comment-done",
  addCommentError = "notes/add-comment-error",
  updateCommentRequested = "notes/update-comment-requested",
  updateCommentDone = "notes/update-comment-done",
  updateCommentError = "notes/update-comment-error",
  deleteCommentRequested = "notes/delete-comment-requested",
  deleteCommentDone = "notes/delete-comment-done",
  deleteCommentError = "notes/delete-comment-error",
  clearNotes = "notes/clear-notes",
}

/** NOTES */

export type ToggleNoteModalAction = Action<ActionType.toggleNoteModal>;

export interface SetSelectedNoteAction
  extends ActionPayload<
    ActionType.setSelectedNote,
    { note: INoteItem | null }
  > {}

export interface NoteDateRangePayload {
  range: { fromDate: string; toDate: string };
}

/** GET NOTES */

export interface GetNotesPayloadRequest {
  from?: string;
  to?: string;
}
export interface GetNotesPayload {
  notes: INoteItem[];
}

export interface GetNotesRequestedAction
  extends ActionPayload<ActionType.getNotesRequested, GetNotesPayloadRequest> {}

export interface GetNotesDoneAction
  extends ActionPayload<ActionType.getNotesDone, GetNotesPayload> {}

export interface GetNotesErrorAction extends Action<ActionType.getNotesError> {}

/** ADD NOTE */

export interface AddNotePayload {
  note: Partial<INoteItem>;
}
export interface AddNoteRequestedAction
  extends ActionPayload<ActionType.addNoteRequested, AddNotePayload> {}

export interface AddNoteDoneAction
  extends ActionPayload<ActionType.addNoteDone, AddNotePayload> {}

export interface AddNoteErrorAction extends Action<ActionType.addNoteError> {}

/** UPDATE NOTES */

export interface UpdateNotePayload {
  note: Partial<INoteItem>;
}
export interface UpdateNoteRequestedAction
  extends ActionPayload<ActionType.updateNoteRequested, UpdateNotePayload> {}

export interface UpdateNoteDoneAction
  extends ActionPayload<ActionType.updateNoteDone, UpdateNotePayload> {}

export interface UpdateNoteErrorAction
  extends Action<ActionType.updateNoteError> {}

/** DELETE NOTE */

export interface DeleteNotePayload {
  id: number;
}
export interface DeleteNoteRequestedAction
  extends ActionPayload<ActionType.deleteNoteRequested, DeleteNotePayload> {}

export interface DeleteNoteDoneAction
  extends ActionPayload<ActionType.deleteNoteDone, DeleteNotePayload> {}

export interface DeleteNoteErrorAction
  extends Action<ActionType.deleteNoteError> {}

/** ADD COMMENT */

export interface AddCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface AddCommentRequestedAction
  extends ActionPayload<ActionType.addCommentRequested, AddCommentPayload> {}

export interface AddCommentDoneAction
  extends ActionPayload<ActionType.addCommentDone, AddCommentPayload> {}

export interface AddCommentErrorAction
  extends Action<ActionType.addCommentError> {}

/** UPDATE COMMENT */

export interface UpdateCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface UpdateCommentRequestedAction
  extends ActionPayload<
    ActionType.updateCommentRequested,
    UpdateCommentPayload
  > {}

export interface UpdateCommentDoneAction
  extends ActionPayload<ActionType.updateCommentDone, UpdateCommentPayload> {}

export interface UpdateCommentErrorAction
  extends Action<ActionType.updateCommentError> {}

/** DELETE COMMENT */

export interface DeleteCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface DeleteCommentRequestedAction
  extends ActionPayload<
    ActionType.deleteCommentRequested,
    DeleteCommentPayload
  > {}

export interface DeleteCommentDoneAction
  extends ActionPayload<ActionType.deleteCommentDone, DeleteCommentPayload> {}

export interface DeleteCommentErrorAction
  extends Action<ActionType.deleteCommentError> {}

/** CLEAR */

export type ClearNotesAction = Action<ActionType.clearNotes>;

/** ACTIONS TYPES */

export type Actions =
  | ToggleNoteModalAction
  | SetSelectedNoteAction
  | GetNotesRequestedAction
  | GetNotesDoneAction
  | GetNotesErrorAction
  | AddNoteRequestedAction
  | AddNoteDoneAction
  | AddNoteErrorAction
  | UpdateNoteRequestedAction
  | UpdateNoteDoneAction
  | UpdateNoteErrorAction
  | DeleteNoteRequestedAction
  | DeleteNoteDoneAction
  | DeleteNoteErrorAction
  | AddCommentRequestedAction
  | AddCommentDoneAction
  | AddCommentErrorAction
  | UpdateCommentRequestedAction
  | UpdateCommentDoneAction
  | UpdateCommentErrorAction
  | DeleteCommentRequestedAction
  | DeleteCommentDoneAction
  | DeleteCommentErrorAction
  | ClearNotesAction;
