import { StorePlanState } from "./types";

interface State {
  storePlan: StorePlanState;
}

export const storePlanSelector = (state: State) => state.storePlan.storePlan;
export const storePlanDataSelector = (state: State) => state.storePlan.storePlan.data;
export const storePlanIdSelector = (state: State) => state.storePlan.storePlan.data?.storePlanId;
export const activityTypesSelector = (state: State) => state.storePlan.activityTypes;
export const activityTypesDataSelector = (state: State) => state.storePlan.activityTypes.data;
export const objectivesTypesSelector = (state: State) => state.storePlan.objectivesTypes;
export const objectivesTypesDataSelector = (state: State) => state.storePlan.objectivesTypes.data;
export const hourlyReviewTypesSelector = (state: State) => state.storePlan.hourlyReviewTypes;
export const hourlyReviewTypesDataSelector = (state: State) =>
  state.storePlan.hourlyReviewTypes.data;
export const appParametersDataSelector = (state: State) => state.storePlan.appParameters.data;
export const loadingStorePlanSelector = (state: State) => state.storePlan.loadingStorePlan;
export const loadingObjectivesSelector = (state: State) => state.storePlan.loadingObjectives;
export const loadingHourlyReviewSelector = (state: State) => state.storePlan.loadingHourlyReview;
