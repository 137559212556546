import { takeLatest, call, put, select } from "redux-saga/effects";
import StorePlanServices from "../../../services/StorePlanServices";
import {
  PromiseResult,
  getQueryParams,
  scheduleRawFormatter,
  scheduleResponseFormatter,
} from "../../../helpers/functions";
import { AlertCode, IHttpRequestError, IQueryParams } from "../../../types";
import {
  getSchedulesDone,
  getSchedulesError,
  setSchedulesDone,
  setSchedulesError,
} from "./actions";
import { ActionType, GetSchedulesRequestedAction, SetSchedulesRequestedAction } from "./types";
import { setSnackbar } from "../../app/actions";
import { ApiResponse, GetSchedulesResponse } from "../../../services/interfaces";
import { filtersSelector } from "../masterdata/selectors";

function* getSchedulesSagas(action: GetSchedulesRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: getSchedulesResponse,
      hasError,
      error,
    }: PromiseResult<GetSchedulesResponse> = yield call(StorePlanServices.getSchedules, params);
    if (hasError || !getSchedulesResponse) throw error;
    const formattedData = scheduleResponseFormatter(getSchedulesResponse.data.data);
    yield put(getSchedulesDone({ schedules: formattedData }));
  } catch (error) {
    yield put(getSchedulesError());
  }
}

function* setSchedulesSagas(action: SetSchedulesRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const schedules = action.payload.schedules;
    const {
      result: setSchedulesResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      StorePlanServices.setSchedules,
      scheduleRawFormatter(schedules),
      params
    );
    if (hasError || !setSchedulesResponse) throw error;
    yield put(setSchedulesDone({ schedules }));
  } catch (error) {
    yield put(setSchedulesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.SCHEDULES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const adminSagas = [
  takeLatest(ActionType.getSchedulesRequested, getSchedulesSagas),
  takeLatest(ActionType.setSchedulesRequested, setSchedulesSagas),
];

export default adminSagas;
