import { IShiftSchedules, IScheduleItem } from "../../types";

export const defaultRH: IScheduleItem = {
  weekday: "",
  shiftTypeId: "RH",
  shiftTypeDescription_es: "",
  shiftTypeDescription_en: "",
  shiftTypeDescription_pt: "",
  shiftTypeDescription_fr: "",
  shiftStart: "",
  shiftEnd: "",
  enabled: false,
  rushHours: [],
};

export function defaultGenerator(data: IShiftSchedules, weekday: string): IScheduleItem[] {
  if (data["monday"]) {
    const newData = data["monday"].map((item) => ({
      ...item,
      shiftStart: "",
      shiftEnd: "",
      enabled: item.enabled,
      weekday,
      rushHours: item.rushHours ? [...item.rushHours] : [],
    }));
    return newData;
  } else return [];
}

export function getVariant(day: string, dayIndex: string) {
  if (day === dayIndex) return "contained";
  return "outlined";
}

export function getColor(day: string, dayIndex: string) {
  if (day === dayIndex) return "primary";
  return "inherit";
}

export function isComplete(schedule: IShiftSchedules) {
  if (Object.keys(schedule).length < 7) {
    return false;
  }
  let result = true;
  Object.keys(schedule).forEach((key) => {
    schedule[key].forEach((e) => {
      if (e.shiftTypeId === "RH" && e.enabled && e.rushHours.length === 0) {
        result = false;
        return;
      } else if (
        e.shiftTypeId !== "RH" &&
        e.enabled &&
        (e.shiftStart === "" || e.shiftEnd === "")
      ) {
        result = false;
        return;
      }
    });
  });
  return result;
}

export function isEnabled(item: IScheduleItem) {
  return item.enabled && (item.shiftStart !== "" || item.shiftEnd !== "");
}

export function parseTimes(data: IScheduleItem) {
  return {
    start: parseInt(data.shiftStart.replace(":", "")),
    end: parseInt(data.shiftEnd.replace(":", "")),
  };
}

export function newScheduleItemInstance(
  data: IScheduleItem[],
  weekday: string,
  rushHours?: number[]
): IScheduleItem[] {
  const newData: IScheduleItem[] = [];
  let haveRH = false;

  data.forEach((e) => {
    if (e.shiftTypeId === "RH") {
      haveRH = true;
      const newRushHours = e.rushHours ? [...e.rushHours] : [];
      newData.push({
        ...e,
        rushHours: rushHours ? [...rushHours] : newRushHours,
      });
    } else newData.push({ ...e });
  });

  if (!haveRH) {
    newData.push({ ...defaultRH, weekday, rushHours: rushHours || [] });
  }

  return newData;
}

export function newScheduleInstance(data: IShiftSchedules): IShiftSchedules {
  let result: IShiftSchedules = {};
  Object.keys(data).forEach((key) => {
    const weekday = data[key] ? data[key][0]?.weekday || "monday" : "monday";
    const newItems = newScheduleItemInstance(data[key], weekday);
    result[key] = newItems;
  });

  return result;
}

export function getRushHours(data: number[]) {
  let am: number[] = [];
  let pm: number[] = [];
  data.forEach((hour) => {
    if (hour >= 1 && hour <= 12) am.push(hour);
    else pm.push(hour);
  });
  return { am, pm };
}
