import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IStatProgress } from "../../types";
import { palette_primary_medium } from "../../theme/theme";
import StatSimpleProgress from "./StatSimpleProgress";

const containerBox: SxProps = {
  cursor: "pointer",
  position: "relative",
  display: "grid",
  p: 1,
  px: 2,
  width: "100%",
  height: "100%",
  textAlign: "center",
  "&:hover": {
    opacity: 0.8,
  },
};

const timerContainer: SxProps = {
  position: "relative",
  display: "inline-flex",
  pt: 1,
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const timerContent: SxProps = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

function CustomCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          // color: (theme) => theme.palette.primary.light,
          color: palette_primary_medium,
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        //
        sx={{
          color: (theme) => theme.palette.secondary.main,
          animationDuration: "500ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        {...props}
      />
    </Box>
  );
}

interface Props {
  title?: string;
  progress: IStatProgress;
  showTotals?: boolean;
  onClick?: () => void;
}

const CircularStatCard = ({ title, progress, showTotals, onClick }: Props) => {
  const { t } = useTranslation();
  const { total, finished, pending } = progress;
  const percentage = (finished * 100) / total;

  return (
    <Box sx={containerBox} onClick={onClick}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={timerContainer}>
        <CustomCircularProgress value={percentage} size={180} thickness={4} />
        <Box sx={timerContent}>
          <Typography variant="h3">{`${finished}/${total}`}</Typography>
        </Box>
      </Box>
      {showTotals && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <StatSimpleProgress
              title={t("labels.finisheds")}
              value={finished}
              color="success.light"
            />
          </Grid>
          <Grid item xs={6}>
            <StatSimpleProgress title={t("labels.pendings")} value={pending} color="error.light" />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CircularStatCard;
