import { compareSegmentsByHour } from "../../helpers/functions";
import { IFormattedSegments } from "../../types";
import { doneRemoteResource, initialRemoteResource } from "../utils";
import { Actions, ActionType, SalesState } from "./types";

const formattedSegments: IFormattedSegments = {
  segments: {},
  currentSegments: {},
  categories: [],
  lineGcsSeries: [],
  mixedGcsSeries: [],
  mixedHsSeries: [],
  mixedIpSeries: [],
};

const initialState: SalesState = {
  segments: initialRemoteResource(null),
  totals: initialRemoteResource([]),
  dailyTotals: initialRemoteResource(null),
  formattedSegments: formattedSegments,
  isLoading: false,
  isUpdating: false,
};

// function handleSegments(data: ISegmentItem[]) {}

// function updateSaleHours(data: ISegmentItem[], newData: ISegmentItem[]) {
//   const result: ISegmentItem[] = data.map((e) => ({ ...e }));
//   newData.forEach((e) => {
//     const index = result.findIndex((i) => i.hour === e.hour);
//     if (index !== -1) result[index] = { ...result[index], ...e };
//   });
//   return doneRemoteResource(result);
// }

const salesReducer = (state: SalesState = initialState, action: Actions): SalesState => {
  switch (action.type) {
    /** DAILY TOTALS */
    case ActionType.getDailyTotalsRequested:
      return {
        ...state,
        // segments: requestedRemoteResource(null),
        // totals: requestedRemoteResource([]),
        // dailyTotals: requestedRemoteResource(null),
        // formattedSegments: formattedSegments,
        isLoading: true,
      };
    case ActionType.getDailyTotalsDone:
      return {
        ...state,
        segments: doneRemoteResource(action.payload.dailyTotals.segments),
        totals: doneRemoteResource(action.payload.dailyTotals.totals.sort(compareSegmentsByHour)),
        dailyTotals: doneRemoteResource(action.payload.dailyTotals.dailyTotals),
        isLoading: false,
      };
    case ActionType.getDailyTotalsError:
      return {
        ...state,
        // segments: errorRemoteResource(initialSegments),
        // totals: errorRemoteResource([]),
        // dailyTotals: errorRemoteResource(null),
        // formattedSegments: formattedSegments,
        isLoading: false,
      };
    /** SALES HOURS */
    case ActionType.updateSalesHoursRequested:
      return {
        ...state,
        isUpdating: true,
      };
    case ActionType.updateSalesHoursDone:
      return {
        ...state,
        isUpdating: false,
      };
    case ActionType.updateSalesHoursError:
      return {
        ...state,
        isUpdating: false,
      };
    case ActionType.setFormattedSegments:
      return {
        ...state,
        formattedSegments: action.payload,
      };

    default:
      return state;
  }
};

export default salesReducer;
