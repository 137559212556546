import { useMediaQuery } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredOptionsSelector,
  filtersSelector,
  loadingSelector,
} from "../redux/admin/masterdata/selectors";
import DivisionSelector from "./selectors/DivisionSelector";
import StoreSelector from "./selectors/StoreSelector";
import CountrySelector from "./selectors/CountrySelector";
import { ActionButton, FlexBox } from "../styles";
import { getAreasRequested } from "../redux/admin/areas/actions";
import { getManagersRequested } from "../redux/admin/managers/actions";
import { getSchedulesRequested } from "../redux/admin/schedules/actions";
import { setStorePlan } from "../redux/storePlan/actions";
import { setSnackbar } from "../redux/app/actions";
import { AlertCode } from "../types";
import { getChecklistsRequested } from "../redux/admin/activities/actions";

const FiltersToolbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:480px)");
  const filters = useSelector(filtersSelector);
  const filteredOptions = useSelector(filteredOptionsSelector);
  const { countries, stores } = filteredOptions;
  const disableFetch = !filters.country || !filters.country || !filters.store;
  const loading = useSelector(loadingSelector);

  const handleSubmit = () => {
    dispatch(setSnackbar({ alert: { code: AlertCode.LOADING_STORE_SETTINGS } }));
    dispatch(getAreasRequested());
    dispatch(getSchedulesRequested());
    dispatch(getManagersRequested());
    dispatch(getChecklistsRequested());
    dispatch(setStorePlan({ storePlan: null }));
  };

  return (
    <FlexBox my={1} sx={{ flexDirection: isMobile ? "column" : "row" }}>
      <DivisionSelector />
      <CountrySelector options={countries} />
      <StoreSelector options={stores} />
      <ActionButton
        fullWidth={isMobile}
        variant="contained"
        sx={{ ml: isMobile ? 0 : 2, px: 4, mt: isMobile ? 2 : 0 }}
        onClick={handleSubmit}
        loading={loading}
        disabled={disableFetch || loading}
      >
        {t("labels.apply")}
      </ActionButton>
    </FlexBox>
  );
};

export default FiltersToolbar;
