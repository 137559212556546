import { Box, CssBaseline, styled, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { isAppOnlineSelector } from "../redux/app/selectors";
import NoteFormModal from "../components/modals/NoteFormModal";
import { setAlert, setIsAppOnline } from "../redux/app/actions";
import NavigateController from "../components/NavigateController";
import SalesRequestsFormatter from "../components/SalesRequestsFormatter";
import { useTranslation } from "react-i18next";
import AppSnackbar from "../components/AppSnackbar";
import AlertModal from "../components/modals/AlertModal";
import { appUserSelector } from "../redux/user/selectors";
import { RemoteResourceState } from "../redux/utils";
import { notesSelector } from "../redux/activities/notes/selectors";
import { objectivesSelector } from "../redux/activities/objectives/selectors";
import { storePlanSelector } from "../redux/storePlan/selectors";
import ApiRequestsTimer from "../components/ApiRequestsTimer";

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  paddingBlock: theme.spacing(3),
  marginTop: theme.spacing(7),
  backgroundColor:
    theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
}));

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:480px)");
  const isAppOnline = useSelector(isAppOnlineSelector);

  const user = useSelector(appUserSelector);
  const storePlanState = useSelector(storePlanSelector);
  const objectivesState = useSelector(objectivesSelector);
  const notesState = useSelector(notesSelector);

  const activitiesLoaded =
    storePlanState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    objectivesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    notesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED;

  const showSidebar = user?.rol === "admin" || activitiesLoaded ? true : undefined;

  window.addEventListener("offline", (e) => {
    e.stopImmediatePropagation();
    dispatch(setIsAppOnline({ value: false }));
  });

  window.addEventListener("online", (e) => {
    e.stopImmediatePropagation();
    dispatch(setIsAppOnline({ value: true }));
  });

  document.addEventListener("new-version", () => {
    const title = t("messages.newVersion.title").toString();
    const message = t("messages.newVersion.content").toString();
    const actionLabel = t("labels.reloadPage").toString();
    dispatch(setAlert({ alert: { title, message, action: "new-version", actionLabel } }));
  });

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Header
        isAppOnline={isAppOnline}
        activitiesLoaded={activitiesLoaded}
        notesState={notesState}
        storePlan={storePlanState}
        user={user}
        showSidebar={showSidebar}
      />
      <Sidebar isAppOnline={isAppOnline} user={user} showSidebar={showSidebar} />
      <Main sx={{ mb: isMobile && showSidebar ? 5 : 0 }}>{children}</Main>
      <NoteFormModal />
      <NavigateController />
      <ApiRequestsTimer />
      <SalesRequestsFormatter />
      <AppSnackbar />
      <AlertModal />
    </Box>
  );
};

export default Layout;
