import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActionButton } from "../../styles";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  title: string;
  content: string;
  open: boolean;
  onSubmit: (confirm?: boolean) => void;
}

export const ConfirmModal = ({ open, title, content, onSubmit }: Props) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onSubmit(true);
  };

  const handleClose = () => {
    onSubmit();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mx: 1, mb: 1 }}>
        <ActionButton
          id={"btn-close"}
          variant="outlined"
          onClick={handleClose}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          {t("labels.close")}
        </ActionButton>
        <ActionButton id={"btn-confirm"} variant="contained" onClick={handleConfirm}>
          {t("labels.confirm")}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
