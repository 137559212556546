import React from "react";
import { Box, SxProps, Typography } from "@mui/material";

interface SCProps {
  title: string;
  value: number;
  color: string;
}

const StatSimpleProgress = ({ title, value, color }: SCProps) => {
  const statBox: SxProps = {
    borderLeft: 4,
    borderColor: color,
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Typography variant="caption" sx={{ fontWeight: 500, opacity: 0.6 }}>
        {title}
      </Typography>
      <Box sx={statBox}>
        <Typography variant="h5" ml={1}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatSimpleProgress;
