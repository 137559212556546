import { Box, Typography, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import NoDataIcon from "@mui/icons-material/ErrorOutline";

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  color: grey[600],
}));

const NoRowsOverlay = () => {
  let text: string = "Sin datos para mostrar";

  return (
    <StyledGridOverlay sx={{ p: 2 }}>
      <NoDataIcon />
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </StyledGridOverlay>
  );
};

export default NoRowsOverlay;
