import React from "react";
import { Box, Grid, Paper, SxProps, Typography, styled } from "@mui/material";
import { IStatProgress } from "../../types";
import LinearProgressBar from "../LinearProgressBar";
import StatSimpleProgress from "./StatSimpleProgress";
import { useTranslation } from "react-i18next";
import FaceVeryGoodIcon from "@mui/icons-material/SentimentVerySatisfied";
import FaceGoodIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import FaceNeutralIcon from "@mui/icons-material/SentimentNeutral";
import FaceBadIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import FaceVeryBadIcon from "@mui/icons-material/MoodBad";
import { FlexBox } from "../../styles";

const ContainerBox = styled(Box)({
  borderLeft: "6px solid",
  cursor: "pointer",
  height: "100%",
  minWidth: 220,
  position: "relative",
  "&:hover": {
    opacity: 0.7,
  },
});

const ProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const styledIcon: SxProps = {
  opacity: 0.8,
};

interface Props {
  title: string;
  progress: IStatProgress;
  showStats?: boolean;
  onClick: () => void;
}

const ProgressStatCard = ({ title, progress, showStats, onClick }: Props) => {
  const { t } = useTranslation();
  const { total, finished, pending } = progress;
  const percentage = (finished * 100) / total;

  const getProgress = () => {
    if (total === 0) return -1;
    return (finished * 100) / total;
  };

  const renderFaceIcon = () => {
    if (percentage > 99) return <FaceVeryGoodIcon sx={styledIcon} />;
    if (percentage > 75) return <FaceGoodIcon sx={styledIcon} />;
    if (percentage > 50) return <FaceNeutralIcon sx={styledIcon} />;
    if (percentage > 25) return <FaceBadIcon sx={styledIcon} />;
    return <FaceVeryBadIcon sx={styledIcon} />;
  };

  return (
    <ContainerBox
      component={Paper}
      sx={{ borderColor: percentage > 50 ? "success.main" : "error.main" }}
      p={2}
      pt={1}
      onClick={onClick}
    >
      <FlexBox sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">{title}</Typography>
        {renderFaceIcon()}
      </FlexBox>
      <ProgressBox>
        <Typography>{t("labels.progress")}</Typography>
        <Typography sx={{ fontWeight: 500 }}>{`${finished}/${total}`}</Typography>
      </ProgressBox>
      <LinearProgressBar value={getProgress()} />
      {showStats && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <StatSimpleProgress
              title={t("labels.finisheds")}
              value={finished}
              color="success.light"
            />
          </Grid>
          <Grid item xs={6}>
            <StatSimpleProgress title={t("labels.pendings")} value={pending} color="error.light" />
          </Grid>
        </Grid>
      )}
    </ContainerBox>
  );
};

export default ProgressStatCard;
