import { IManager } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, AdminState } from "./types";

const initialState: AdminState = {
  managers: initialRemoteResource([]),
  loading: false,
};

const addManager = (managers: IManager[], manager: IManager) => {
  const newManagers = [...managers, manager];
  return newManagers;
};

const updateManagers = (managers: IManager[], manager: IManager) => {
  const index = managers.findIndex((i) => i.identifier === manager.identifier);
  if (index !== -1) {
    let newManagers = [...managers];
    newManagers[index] = { ...manager };
    return newManagers;
  } else {
    return managers;
  }
};

const deleteManager = (managers: IManager[], identifier: number) => {
  const newManagers = [...managers.filter((i) => i.identifier !== identifier)];
  return newManagers;
};

const managerReducer = (state: AdminState = initialState, action: Actions): AdminState => {
  switch (action.type) {
    /** MANAGERS */
    case ActionType.getManagersRequested:
      return {
        ...state,
        managers: requestedRemoteResource([]),
        loading: true,
      };
    case ActionType.getManagersDone:
      return {
        ...state,
        managers: doneRemoteResource(action.payload.managers),
        loading: false,
      };
    case ActionType.getManagersError:
      return {
        ...state,
        managers: errorRemoteResource([]),
        loading: false,
      };
    case ActionType.addManagerRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.addManagerDone:
      return {
        ...state,
        managers: doneRemoteResource(
          addManager(state.managers.data || [], action.payload.manager as IManager)
        ),
        loading: false,
      };
    case ActionType.addManagerError:
      return {
        ...state,
        loading: false,
      };
    case ActionType.updateManagerRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.updateManagerDone:
      return {
        ...state,
        managers: doneRemoteResource(
          updateManagers(state.managers.data || [], action.payload.manager as IManager)
        ),
        loading: false,
      };
    case ActionType.updateManagerError:
      return {
        ...state,
        loading: false,
      };

    case ActionType.deleteManagerRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.deleteManagerDone:
      return {
        ...state,
        managers: doneRemoteResource(
          deleteManager(state.managers.data || [], action.payload.identifier)
        ),
        loading: false,
      };
    case ActionType.deleteManagerError:
      return {
        ...state,
        loading: false,
      };
    case ActionType.clearData:
      return {
        ...state,
        managers: initialRemoteResource([]),
        loading: false,
      };

    default:
      return state;
  }
};

export default managerReducer;
