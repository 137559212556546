import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  filtersSelector,
  masterdataSelector,
  masterdataStateSelector,
} from "../redux/admin/masterdata/selectors";
import { areasSelector } from "../redux/admin/areas/selectors";
import { managersSelector } from "../redux/admin/managers/selectors";
import { schedulesSelector } from "../redux/admin/schedules/selectors";

import { getMasterdataRequested, setFilters } from "../redux/admin/masterdata/actions";
import { clearData as clearAreasData, getAreasRequested } from "../redux/admin/areas/actions";
import {
  clearData as clearManagersData,
  getManagersRequested,
} from "../redux/admin/managers/actions";
import {
  clearData as clearSchedulesData,
  getSchedulesRequested,
} from "../redux/admin/schedules/actions";

import { RemoteResourceState } from "../redux/utils";
import AppStorage from "../services/AppStorage";
import { setPreferences } from "../redux/user/actions";
import {
  AlertCode,
  AppPermissions,
  IHourlyReviewItem,
  IHourlyReviewState,
  IUserPreferences,
} from "../types";
import {
  getActivityTypesRequested,
  getObjectivesTypesRequested,
  setStorePlan,
} from "../redux/storePlan/actions";
import {
  activityTypesSelector,
  objectivesTypesSelector,
  storePlanDataSelector,
} from "../redux/storePlan/selectors";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { getChangelogsReportRequested, setSnackbar } from "../redux/app/actions";
import { getISOString } from "../helpers/functions";
import { getDailyTotalsRequested } from "../redux/sales/actions";
import { getObjectivesRequested } from "../redux/activities/objectives/actions";
import { getNotesRequested } from "../redux/activities/notes/actions";
import { setChecklistsData } from "../redux/activities/checklists/actions";
import { setHourlyReview, setHourlyReviewState } from "../redux/activities/rev/actions";
import { permissionsDataSelector } from "../redux/user/selectors";
import { getChecklistsRequested } from "../redux/admin/activities/actions";
import { customChecklistsSelector } from "../redux/admin/activities/selectors";
import { getLocationsRequested, getPositioningRequested } from "../redux/orquest/actions";

const handleHourlyReviewState = (hourlyReviews: IHourlyReviewItem[]): IHourlyReviewState => {
  const hour = new Date().getHours();
  const current = hourlyReviews.find((e) => e.reviewHour === hour);

  return {
    hour,
    confirmed: current?.checked ? true : false,
    completed: current ? current.checked : false,
  };
};

interface Props {
  isAuthenticated?: boolean;
}

const InitialRequests = ({ isAuthenticated }: Props) => {
  const dispatch = useDispatch();
  const masterdataState = useSelector(masterdataStateSelector);
  const masterdata = useSelector(masterdataSelector);
  const filters = useSelector(filtersSelector);
  const activityTypes = useSelector(activityTypesSelector);
  const objectivesTypes = useSelector(objectivesTypesSelector);
  const areas = useSelector(areasSelector);
  const schedules = useSelector(schedulesSelector);
  const managers = useSelector(managersSelector);
  const storePlan = useSelector(storePlanDataSelector);
  const permissions = useSelector(permissionsDataSelector);
  const customActivities = useSelector(customChecklistsSelector);

  const { divisions, countries, stores } = masterdata;
  const { division, country, store } = filters;
  const uniqueFilters = divisions.length === 1 && countries.length === 1 && stores.length === 1;
  const filtersSelected = division && country && store;
  const enableRequests = isAuthenticated && permissions.includes(AppPermissions.APP);

  useEffect(() => {
    if (enableRequests) {
      dispatch(setSnackbar({ alert: { code: AlertCode.LOADING_MASTERDATA } }));
      if (masterdataState.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getMasterdataRequested());
      if (activityTypes?.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getActivityTypesRequested());
      if (objectivesTypes.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getObjectivesTypesRequested());

      dispatch(getChangelogsReportRequested());
      const preferences = AppStorage.getUserPreferences();
      if (preferences) dispatch(setPreferences({ preferences }));
      else {
        const newPreferences: IUserPreferences = {
          language: "es",
          paletteMode: "light",
          zoom: 1,
          showMRTGrid: true,
        };
        dispatch(setPreferences({ preferences: newPreferences }));
        AppStorage.setUserPreferences(newPreferences);
      }
    }
    // eslint-disable-next-line
  }, [enableRequests]);

  useEffect(() => {
    if (uniqueFilters)
      dispatch(
        setFilters({ filters: { division: divisions[0], country: countries[0], store: stores[0] } })
      );
    // eslint-disable-next-line
  }, [masterdata]);

  useEffect(() => {
    dispatch(clearAreasData());
    dispatch(clearManagersData());
    dispatch(clearSchedulesData());
    dispatch(setStorePlan({ storePlan: null }));

    if (uniqueFilters && filtersSelected) {
      dispatch(setSnackbar({ alert: { code: AlertCode.LOADING_STORE_SETTINGS } }));
      if (areas.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED) dispatch(getAreasRequested());
      if (schedules.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getSchedulesRequested());
      if (managers.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getManagersRequested());
      if (customActivities.state !== RemoteResourceState.REMOTE_RESOURCE_LOADED)
        dispatch(getChecklistsRequested());
    }
    // eslint-disable-next-line
  }, [division, country, store]);

  useEffect(() => {
    if (storePlan && storePlan.storePlanId) {
      const from = getISOString(startOfDay(subDays(new Date(), 6)));
      const to = getISOString(endOfDay(new Date()));

      dispatch(setChecklistsData(storePlan));
      dispatch(setHourlyReview({ value: storePlan.REV }));
      dispatch(setHourlyReviewState({ value: handleHourlyReviewState(storePlan.REV) }));
      dispatch(setSnackbar({ alert: { code: AlertCode.LOADING_ACTIVITIES } }));
      dispatch(getObjectivesRequested());
      dispatch(getNotesRequested({ from, to }));
      dispatch(getDailyTotalsRequested());
      dispatch(getPositioningRequested());
      dispatch(getLocationsRequested());
      dispatch(getChangelogsReportRequested());
    }
    // eslint-disable-next-line
  }, [storePlan]);

  return null;
};

export default InitialRequests;
