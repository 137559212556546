import { AdminState } from "./types";

interface State {
  managers: AdminState;
}

export const managersSelector = (state: State) => state.managers.managers;

export const managersDataSelector = (state: State) => state.managers.managers.data;
export const loadingSelector = (state: State) => state.managers.loading;
