import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../utils";
import { Actions, ActionType, UserState } from "./types";

const initialState: UserState = {
  appUser: undefined,
  isAuthenticated: false,
  info: undefined,
  permissions: initialRemoteResource([]),
  preferences: null,
};

const userReducer = (state: UserState = initialState, action: Actions): UserState => {
  switch (action.type) {
    case ActionType.msalSignInSuccess:
      return {
        ...state,
        info: { username: action.username, name: action.name },
        permissions: requestedRemoteResource([]),
      };
    case ActionType.setAppUser:
      return {
        ...state,
        appUser: action.payload.appUser,
      };
    case ActionType.getPermissionsRequested:
      return {
        ...state,
        permissions: requestedRemoteResource([]),
        isAuthenticated: false,
      };
    case ActionType.getPermissionsDone:
      return {
        ...state,
        permissions: doneRemoteResource(action.payload.permissions),
        isAuthenticated: true,
      };
    case ActionType.getPermissionsError:
      return {
        ...state,
        permissions: errorRemoteResource([]),
      };
    case ActionType.setPreferences:
      return {
        ...state,
        preferences: action.payload.preferences,
      };

    default:
      return state;
  }
};

export default userReducer;
