import { Action } from "@reduxjs/toolkit";
import { RemoteResource } from "../../utils";
import { IHourlyReviewState, IHourlyReviewItem, IHourlyReviewTypes } from "../../../types";
import { ActionPayload } from "../../actions";

export interface RevState {
  hourlyReviewTypes: RemoteResource<IHourlyReviewTypes | null>;
  hourlyReviews: RemoteResource<IHourlyReviewItem[]>;
  hourlyReviewState: IHourlyReviewState;
  loadingHourlyReview: boolean;
  hourlyReviewsSyncError: boolean;
}

export enum ActionType {
  default = "default",
  updateHourlyReviewRequested = "rev/update-hourly-review-requested",
  updateHourlyReviewDone = "rev/update-hourly-review-done",
  updateHourlyReviewError = "rev/update-hourly-review-error",
  setHourlyReview = "rev/set-hourly-review",
  setHourlyReviewState = "rev/set-hourly-review-state",
}

/** HOURLY REVIEW */

export interface DefaultAction extends Action<ActionType.default> {}

export interface UpdateHourlyReviewPayload {
  hourlyReviews: IHourlyReviewItem;
}
export interface UpdateHourlyReviewRequestedAction
  extends ActionPayload<ActionType.updateHourlyReviewRequested, UpdateHourlyReviewPayload> {}

export interface UpdateHourlyReviewDoneAction
  extends ActionPayload<ActionType.updateHourlyReviewDone, UpdateHourlyReviewPayload> {}

export interface UpdateHourlyReviewErrorAction
  extends ActionPayload<ActionType.updateHourlyReviewError, UpdateHourlyReviewPayload> {}

export interface SetHourlyReviewAction
  extends ActionPayload<ActionType.setHourlyReview, { value: IHourlyReviewItem[] }> {}

export interface SetHourlyReviewStateAction
  extends ActionPayload<ActionType.setHourlyReviewState, { value: IHourlyReviewState }> {}

/** ACTIONS TYPES */

export type Actions =
  | DefaultAction
  | UpdateHourlyReviewRequestedAction
  | UpdateHourlyReviewDoneAction
  | UpdateHourlyReviewErrorAction
  | SetHourlyReviewAction
  | SetHourlyReviewStateAction;
