import { ActivityType, IActivitiesState, IAreaChecklist } from "../../../types";
import { ChecklistState } from "./types";

export interface IChkState {
  checklists: ChecklistState;
}

function isLoadingActivity(state: IChkState) {
  const loading =
    state.checklists.loadingOpening ||
    state.checklists.loadingClosing ||
    state.checklists.loadingPreshift ||
    state.checklists.loadingPosShift ||
    state.checklists.loadingChecklist;

  return loading;
}

function isLoadingChecklist(state: IChkState) {
  const result: { [key: string]: boolean } = {
    OPN: state.checklists.loadingOpening,
    CLO: state.checklists.loadingClosing,
    PRE: state.checklists.loadingPreshift,
    POS: state.checklists.loadingPosShift,
    CHK: state.checklists.loadingChecklist,
  };

  return result;
}

function getActivitiesState(state: IChkState): IActivitiesState {
  const syncRequired =
    state.checklists.openingSyncError ||
    state.checklists.closingSyncError ||
    state.checklists.preShiftSyncError ||
    state.checklists.posShiftSyncError ||
    state.checklists.checklistSyncError ||
    state.checklists.myActivitiesSyncError;

  const syncError: { [key: string]: boolean } = {
    OPN: state.checklists.openingSyncError,
    CLO: state.checklists.closingSyncError,
    PRE: state.checklists.preShiftSyncError,
    POS: state.checklists.posShiftSyncError,
    CHK: state.checklists.checklistSyncError,
    CUS: state.checklists.myActivitiesSyncError,
  };

  return { syncRequired, syncError };
}

function getChecklistData(state: IChkState, type: ActivityType): IAreaChecklist {
  switch (type) {
    case ActivityType.OPENING:
      return state.checklists.opening.data || {};
    case ActivityType.CLOSING:
      return state.checklists.closing.data || {};
    case ActivityType.CHECKLIST:
      return state.checklists.checklist.data || {};

    default:
      return {};
  }
}

export const preShiftSelector = (state: IChkState) => state.checklists.preShift;
export const preShiftDataSelector = (state: IChkState) => state.checklists.preShift.data || [];
export const posShiftSelector = (state: IChkState) => state.checklists.posShift;
export const posShiftDataSelector = (state: IChkState) => state.checklists.posShift.data || [];
export const openingSelector = (state: IChkState) => state.checklists.opening;
export const openingDataSelector = (state: IChkState) => state.checklists.opening.data || {};
export const closingSelector = (state: IChkState) => state.checklists.closing;
export const closingDataSelector = (state: IChkState) => state.checklists.closing.data || {};
export const areasChecklistSelector = (state: IChkState) => state.checklists.checklist;
export const areasChecklistDataSelector = (state: IChkState) =>
  state.checklists.checklist.data || {};
export const loadingChecklistSelector = (state: IChkState) => isLoadingChecklist(state);
export const isLoadingActivitySelector = (state: IChkState) => isLoadingActivity(state);
export const checklistsStateSelector = (state: IChkState) => getActivitiesState(state);
export const checklistDataSelector = (state: IChkState, type: ActivityType) =>
  getChecklistData(state, type);
export const myActivitiesSelector = (state: IChkState) => state.checklists.myActivities;
export const myActivitiesDataSelector = (state: IChkState) =>
  state.checklists.myActivities.data || [];
