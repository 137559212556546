import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActionButton } from "../styles";
import { useTranslation } from "react-i18next";
import { DialogContentText, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isAppOnlineSelector, navigateSelector } from "../redux/app/selectors";
import { useNavigate } from "react-router-dom";
import { setNavigateController } from "../redux/app/actions";
import { routes } from "../router/routes";
import { initialNotesDates } from "../pages/notes/NoteSummary";
import { endOfDay, startOfDay } from "date-fns";
import { getNotesRequested } from "../redux/activities/notes/actions";
import { getISOString } from "../helpers/functions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NavigateController = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const navigateController = useSelector(navigateSelector);
  const open =
    Boolean(navigateController.path) && Boolean(navigateController.hasChanges);

  React.useEffect(() => {
    if (navigateController.path && !navigateController.hasChanges) {
      navigate(navigateController.path);
      dispatch(setNavigateController({ navigateController: {} }));

      if (
        isAppOnline &&
        navigateController.currentPath?.includes(routes.main.notesSummary.path)
      )
        dispatch(
          getNotesRequested({
            from: getISOString(startOfDay(initialNotesDates.from)),
            to: getISOString(endOfDay(initialNotesDates.to)),
          })
        );
    }
    // eslint-disable-next-line
  }, [navigateController, isAppOnline]);

  const onSubmit = (action: "cancel" | "leave" | "save") => {
    if (action === "save") {
      dispatch(
        setNavigateController({
          navigateController: { ...navigateController, saveChanges: true },
        })
      );
    } else if (action === "leave" && navigateController.path) {
      navigate(navigateController.path);
      dispatch(setNavigateController({ navigateController: {} }));
    } else {
      dispatch(setNavigateController({ navigateController: {} }));
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onSubmit("cancel")}
      aria-describedby="description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("messages.unsavedChanges.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="content">
          {t("messages.unsavedChanges.content")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mx: 1, mb: 1 }}>
        <ActionButton
          variant="outlined"
          onClick={() => onSubmit("cancel")}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          {t("labels.cancel")}
        </ActionButton>
        <ActionButton
          variant="outlined"
          onClick={() => onSubmit("leave")}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          {t("labels.leavePage")}
        </ActionButton>
        <ActionButton variant="contained" onClick={() => onSubmit("save")}>
          {t("labels.saveChanges")}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default NavigateController;
