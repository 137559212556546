import { IMasterdata } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, MasterdataState } from "./types";

const initialMD: IMasterdata = { divisions: [], countries: [], stores: [] };

const initialState: MasterdataState = {
  masterdata: initialRemoteResource(initialMD),
  filters: {},
  filteredOptions: initialMD,
  loading: false,
};

const masterdataReducer = (
  state: MasterdataState = initialState,
  action: Actions
): MasterdataState => {
  switch (action.type) {
    /** MASTER DATA */
    case ActionType.getMasterdataRequested:
      return {
        ...state,
        masterdata: requestedRemoteResource(initialMD),
        loading: true,
      };
    case ActionType.getMasterdataDone:
      return {
        ...state,
        masterdata: doneRemoteResource(action.payload.masterdata),
        loading: false,
      };
    case ActionType.getMasterdataError:
      return {
        ...state,
        masterdata: errorRemoteResource(initialMD),
        loading: false,
      };
    /** FILTERS */
    case ActionType.setFilters:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case ActionType.setFilteredOptions:
      return {
        ...state,
        filteredOptions: action.payload.options,
      };

    default:
      return state;
  }
};

export default masterdataReducer;
