import { IStorePlan } from "../../types";
import {
  ActionType,
  GetActivityTypesDoneAction,
  GetActivityTypesErrorAction,
  GetActivityTypesPayload,
  GetActivityTypesRequestedAction,
  GetStorePlanDoneAction,
  GetStorePlanErrorAction,
  GetStorePlanPayload,
  GetStorePlanRequestedAction,
  GetObjectivesTypesDoneAction,
  GetObjectivesTypesErrorAction,
  GetObjectivesTypesPayload,
  GetObjectivesTypesRequestedAction,
  GetHourlyReviewTypesDoneAction,
  GetHourlyReviewTypesErrorAction,
  GetHourlyReviewTypesPayload,
  GetHourlyReviewTypesRequestedAction,
  SetStorePlanAction,
  GetAppParametersDoneAction,
  GetAppParametersErrorAction,
  GetAppParametersPayload,
  GetAppParametersRequestedAction,
} from "./types";

/** STORE PLAN */

export const getStorePlanRequested = (params: {
  activityTypesId: string;
}): GetStorePlanRequestedAction => {
  return { type: ActionType.getStorePlanRequested, payload: params };
};

export const getStorePlanDone = (result: GetStorePlanPayload): GetStorePlanDoneAction => {
  return { type: ActionType.getStorePlanDone, payload: result };
};

export const getStorePlanError = (): GetStorePlanErrorAction => {
  return { type: ActionType.getStorePlanError };
};

export const setStorePlan = (result: { storePlan: IStorePlan | null }): SetStorePlanAction => {
  return { type: ActionType.setStorePlan, payload: result };
};

/** GET ACTIVITY TYPES */

export const getActivityTypesRequested = (): GetActivityTypesRequestedAction => {
  return { type: ActionType.getActivityTypesRequested };
};

export const getActivityTypesDone = (
  result: GetActivityTypesPayload
): GetActivityTypesDoneAction => {
  return { type: ActionType.getActivityTypesDone, payload: result };
};

export const getActivityTypesError = (): GetActivityTypesErrorAction => {
  return { type: ActionType.getActivityTypesError };
};

/** GET OBJECTIVES TYPES */

export const getObjectivesTypesRequested = (): GetObjectivesTypesRequestedAction => {
  return { type: ActionType.getObjectivesTypesRequested };
};

export const getObjectivesTypesDone = (
  result: GetObjectivesTypesPayload
): GetObjectivesTypesDoneAction => {
  return { type: ActionType.getObjectivesTypesDone, payload: result };
};

export const getObjectivesTypesError = (): GetObjectivesTypesErrorAction => {
  return { type: ActionType.getObjectivesTypesError };
};

/** GET HOURLY REVIEW TYPES */

export const getHourlyReviewTypesRequested = (): GetHourlyReviewTypesRequestedAction => {
  return { type: ActionType.getHourlyReviewTypesRequested };
};

export const getHourlyReviewTypesDone = (
  result: GetHourlyReviewTypesPayload
): GetHourlyReviewTypesDoneAction => {
  return { type: ActionType.getHourlyReviewTypesDone, payload: result };
};

export const getHourlyReviewTypesError = (): GetHourlyReviewTypesErrorAction => {
  return { type: ActionType.getHourlyReviewTypesError };
};

/** GET APP PARAMETERS */

export const getAppParametersRequested = (): GetAppParametersRequestedAction => {
  return { type: ActionType.getAppParametersRequested };
};

export const getAppParametersDone = (
  result: GetAppParametersPayload
): GetAppParametersDoneAction => {
  return { type: ActionType.getAppParametersDone, payload: result };
};

export const getAppParametersError = (): GetAppParametersErrorAction => {
  return { type: ActionType.getAppParametersError };
};