import React from "react";
import { Box, Collapse, Grid, Paper, SxProps, Typography, styled } from "@mui/material";
import { IAreaType, IStatProgress } from "../../types";
import { getAreaDescription } from "../../helpers/functions";
import LinearProgressBar from "../LinearProgressBar";
import { useTranslation } from "react-i18next";
import { selectedStyle } from "../../styles";

const ContainerBox = styled(Box)({
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
});

const ProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

interface Props {
  area: IAreaType;
  selected: boolean;
  progress: IStatProgress;
  isSingleArea?: boolean;
  onClick: (area: IAreaType) => void;
}

const AreaCard = ({ area, selected, progress, isSingleArea, onClick }: Props) => {
  const { t } = useTranslation();
  const { total, finished, pending } = progress;

  const getProgress = () => {
    if (total === 0) return -1;
    return (finished * 100) / total;
  };

  return (
    <ContainerBox
      component={Paper}
      p={2}
      pt={1}
      onClick={() => onClick(area)}
      sx={selected && !isSingleArea ? selectedStyle : undefined}
    >
      <Typography variant="h6">{getAreaDescription(area)}</Typography>
      <ProgressBox>
        <Typography>{t("labels.progress")}</Typography>
        <Typography sx={{ fontWeight: 500 }}>{`${finished}/${total}`}</Typography>
      </ProgressBox>
      <LinearProgressBar value={getProgress()} />
      <Collapse in={selected}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <StatCard title={t("labels.finisheds")} value={finished} color="success.light" />
          </Grid>
          <Grid item xs={6}>
            <StatCard title={t("labels.pendings")} value={pending} color="error.light" />
          </Grid>
        </Grid>
      </Collapse>
    </ContainerBox>
  );
};

interface SCProps {
  title: string;
  value: number;
  color: string;
}

const StatCard = ({ title, value, color }: SCProps) => {
  const statBox: SxProps = {
    borderLeft: 4,
    borderColor: color,
  };

  return (
    <Box>
      <Typography variant="caption" sx={{ fontWeight: 500, opacity: 0.6 }}>
        {title}
      </Typography>
      <Box sx={statBox}>
        <Typography variant="h5" ml={1}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default AreaCard;
