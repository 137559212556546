import React from "react";
import { Avatar, Box, Paper, SxProps } from "@mui/material";
import { BoldTypography, FlexBox, LightTypography } from "../../styles";
import StoreMall from "@mui/icons-material/StoreMallDirectory";
import Car from "@mui/icons-material/DirectionsCar";
import LocalCafe from "@mui/icons-material/LocalCafe";
import PedalBike from "@mui/icons-material/PedalBike";
import Fastfood from "@mui/icons-material/Fastfood";
import Icecream from "@mui/icons-material/Icecream";
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import OtherIcon from "@mui/icons-material/ReportProblem";
import GcIcon from "@mui/icons-material/Sell";

import { ISegmentItem } from "../../types";

const containerBox: SxProps = {
  cursor: "pointer",
  p: 1,
  height: "100%",
  margin: "auto",
  "&:hover": {
    opacity: 0.8,
  },
};

const iconSize: SxProps = {
  height: 26,
  width: 26,
};

const gcIcon: SxProps = {
  height: 16,
  width: 16,
  mb: 0.8,
  mr: 0.5,
};

const iconMapper: { [key: string]: JSX.Element } = {
  DELIVERY: <PedalBike sx={iconSize} />,
  MOP: <PhoneIphone sx={iconSize} />,
  "FRONT COUNTER": <Fastfood sx={iconSize} />,
  CSO: <StoreMall sx={iconSize} />,
  "COLD KIOSK": <Icecream sx={iconSize} />,
  "DRIVE THRU": <Car sx={iconSize} />,
  MCCAFE: <LocalCafe sx={iconSize} />,
  OTHERS: <OtherIcon sx={iconSize} />,
};

interface Props {
  color?: string;
  data?: ISegmentItem;
  segment: string;
}

const SegmentStatCard = ({ color, data, segment }: Props) => {
  return (
    <Box component={Paper} sx={containerBox}>
      <FlexBox>
        <Avatar sx={{ mr: 1, width: 48, height: 48, bgcolor: color }}>{iconMapper[segment]}</Avatar>
        <Box>
          <LightTypography variant="caption">{segment}</LightTypography>
          <FlexBox>
            <GcIcon color="primary" sx={gcIcon} />
            <BoldTypography variant="h6" sx={{ lineHeight: 1, mb: 0.7 }}>
              {data?.gcReal || "---"}
            </BoldTypography>
          </FlexBox>
        </Box>
      </FlexBox>
    </Box>
  );
};

export default SegmentStatCard;
