import { IFormattedSegments, ISalesHoursRequest } from "../../types";
import {
  ActionType,
  GetDailyTotalsDoneAction,
  GetDailyTotalsErrorAction,
  GetDailyTotalsRequestedAction,
  DailyTotalsPayload,
  SalesHoursPayload,
  UpdateSalesHoursDoneAction,
  UpdateSalesHoursErrorAction,
  UpdateSalesHoursRequestedAction,
  SetFormattedSegmentsAction,
} from "./types";

/** DAILY TOTALS */

export const getDailyTotalsRequested = (): GetDailyTotalsRequestedAction => {
  return { type: ActionType.getDailyTotalsRequested };
};

export const getDailyTotalsDone = (result: DailyTotalsPayload): GetDailyTotalsDoneAction => {
  return { type: ActionType.getDailyTotalsDone, payload: result };
};

export const getDailyTotalsError = (): GetDailyTotalsErrorAction => {
  return { type: ActionType.getDailyTotalsError };
};

/** SALES HOURS */

export const updateSalesHoursRequested = (
  data: ISalesHoursRequest
): UpdateSalesHoursRequestedAction => {
  return { type: ActionType.updateSalesHoursRequested, payload: { data } };
};

export const updateSalesHoursDone = (result: SalesHoursPayload): UpdateSalesHoursDoneAction => {
  return { type: ActionType.updateSalesHoursDone, payload: result };
};

export const updateSalesHoursError = (): UpdateSalesHoursErrorAction => {
  return { type: ActionType.updateSalesHoursError };
};

export const setFormattedSegments = (result: IFormattedSegments): SetFormattedSegmentsAction => {
  return { type: ActionType.setFormattedSegments, payload: result };
};
