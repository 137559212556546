import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormattedSegments } from "../redux/sales/actions";
import { currentScheduleSelector } from "../redux/admin/schedules/selectors";
import { compareSegmentsByHour, timeLabelToInt } from "../helpers/functions";
import {
  ISegmentsMapper,
  IScheduleRange,
  IChartSerie,
  ICurrentSegmentsMapper,
  ISegmentItem,
} from "../types";
import { segmentsDataSelector, totalsDataSelector } from "../redux/sales/selectors";
import { SEGMENTS_ID } from "../helpers/constants";

function compareHours(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function inRange(hour: number, start: number, end: number) {
  if (start === end) return true;
  else if (end >= start && end <= 23) return hour >= start && hour <= end;
  else return (hour >= start && hour <= 23) || (hour >= 0 && hour <= end);
}

const SalesRequestsFormatter = () => {
  const dispatch = useDispatch();
  const segmentsSelector = useSelector(segmentsDataSelector);
  const totalsSelector = useSelector(totalsDataSelector);
  const currentSchedule = useSelector(currentScheduleSelector);

  const defaultSegmentItem: ISegmentItem = {
    id: "",
    hour: "",
    segment: "",
    gcReal: 0,
    gcProy: 0,
    gcDif: 0,
    hsReal: 0,
    hsProy: 0,
    ipReal: 0,
    ipProy: 0,
  };

  function joinSegments(oldData: ISegmentItem[], newData: ISegmentItem[]): ISegmentItem[] {
    let result = [...oldData];
    const sortedData = newData.map((e) => ({ ...e })).sort(compareSegmentsByHour);
    sortedData.forEach((item) => {
      const index = result.findIndex((i) => i.segment === item.segment && i.hour === item.hour);
      if (index !== -1) result[index] = { ...result[index], ...item };
      else result.push({ ...defaultSegmentItem, ...item });
    });
    return result;
  }

  function joinSegmentsMapper(
    oldData: ISegmentsMapper,
    newData: ISegmentItem[],
    totalHours: string[],
    currentSegments: ICurrentSegmentsMapper,
    currentHour: number,
    scheduleRange: IScheduleRange
  ) {
    const sortedData = newData.map((e) => ({ ...e })).sort(compareSegmentsByHour);
    let mapResult = { ...oldData };
    let currentSegmentResult: ICurrentSegmentsMapper = { ...currentSegments };
    let totalHoursResult: string[] = [...totalHours];

    sortedData.forEach((item) => {
      if (mapResult[item.segment]) {
        let itemSegment = mapResult[item.segment];
        const index = itemSegment.findIndex((i) => i.hour === item.hour);
        if (index !== -1) mapResult[item.segment][index] = { ...itemSegment[index], ...item };
        else mapResult[item.segment].push({ ...defaultSegmentItem, ...item });
      } else {
        mapResult[item.segment] = [{ ...defaultSegmentItem, ...item }];
      }

      if (currentHour - 1 === parseInt(item.hour))
        currentSegmentResult[item.segment]
          ? (currentSegmentResult[item.segment] = {
              ...currentSegmentResult[item.segment],
              ...item,
            })
          : (currentSegmentResult[item.segment] = { ...defaultSegmentItem, ...item });

      if (
        !totalHoursResult.includes(item.hour) &&
        inRange(parseInt(item.hour), scheduleRange.start || 0, scheduleRange.end || 0)
      )
        totalHoursResult.push(item.hour);
    });

    return { mapResult, totalHoursResult, currentSegmentResult };
  }

  /** Formatted data */
  useEffect(() => {
    if (currentSchedule.length > 0 && segmentsSelector) {
      const currentHour = new Date().getHours();
      const totalsData: ISegmentItem[] = joinSegments([], totalsSelector);
      let segments: ISegmentsMapper = { TOTALS: [...totalsData] };
      let totalHours: string[] = [];
      let currentSegments: ICurrentSegmentsMapper = {};

      let scheduleRange: IScheduleRange = { start: null, end: null };
      currentSchedule.forEach((item) => {
        if (item.enabled && scheduleRange.start === null)
          scheduleRange.start = timeLabelToInt(item.shiftStart);
        if (item.enabled) scheduleRange.end = timeLabelToInt(item.shiftEnd);
      });

      Object.keys(segmentsSelector).forEach((key) => {
        const { mapResult, currentSegmentResult, totalHoursResult } = joinSegmentsMapper(
          segments,
          segmentsSelector[key],
          totalHours,
          currentSegments,
          currentHour,
          scheduleRange
        );
        segments = mapResult;
        currentSegments = currentSegmentResult;
        totalHours = totalHoursResult.sort(compareHours);
      });

      const startIndex = totalHours.findIndex((e) => scheduleRange.start === parseInt(e));
      const sameDay = totalHours.slice(startIndex);
      const nextDay = totalHours.slice(0, startIndex);
      totalHours = sameDay.concat(nextDay);

      let lineGcsSeries: IChartSerie[] = [];
      let mixedGcsSeries: IChartSerie[] = [];
      let mixedHsSeries: IChartSerie[] = [];
      let mixedIpSeries: IChartSerie[] = [];

      // Object.keys(segments).forEach((key) => {})
      SEGMENTS_ID.forEach((key) => {
        if (segments[key]) {
          const hours = segments[key].map((item) => item.hour);
          const gcs = segments[key].map((item) => item.gcReal || 0);
          const gcsProy = segments[key].map((item) => item.gcProy || 0);
          const hs = segments[key].map((item) => item.hsReal || 0);
          const hsProy = segments[key].map((item) => item.hsProy || 0);
          const ip = segments[key].map((item) => item.ipReal || 0);
          const ipProy = segments[key].map((item) => item.ipProy || 0);

          let gcsData: (number | null)[] = [];
          let gcsProyData: (number | null)[] = [];
          let hsData: (number | null)[] = [];
          let hsProyData: (number | null)[] = [];
          let ipData: (number | null)[] = [];
          let ipProyData: (number | null)[] = [];

          totalHours.forEach((h) => {
            const index = hours.findIndex((e) => e === h);
            if (hours.includes(h)) {
              gcsData.push(gcs[index]);
              gcsProyData.push(gcsProy[index]);
              hsData.push(hs[index]);
              hsProyData.push(hsProy[index]);
              ipData.push(ip[index]);
              ipProyData.push(ipProy[index]);
            } else {
              gcsData.push(0);
              gcsProyData.push(0);
              hsData.push(0);
              hsProyData.push(0);
              ipData.push(0);
              ipProyData.push(0);
            }
          });

          /** DailyTotals Segments Chart */
          key !== "TOTALS" &&
            lineGcsSeries.push({
              name: key,
              data: gcsData,
              type: "line",
            });
          /** SalesHours Gcs Real/Proy Chart */
          mixedGcsSeries.push({
            name: `gcs-${key}`,
            data: gcsData,
            type: "column",
          });
          mixedGcsSeries.push({
            name: `proy-${key}`,
            data: gcsProyData,
            type: "area",
          });
          /** SalesHours Hs Real/Proy Chart */
          mixedHsSeries.push({
            name: `hs-${key}`,
            data: hsData,
            type: "column",
          });
          mixedHsSeries.push({
            name: `proy-${key}`,
            data: hsProyData,
            type: "area",
          });
          /** SalesHours IP Real/Proy Chart */
          mixedIpSeries.push({
            name: `ip-${key}`,
            data: ipData,
            type: "column",
          });
          mixedIpSeries.push({
            name: `proy-${key}`,
            data: ipProyData,
            type: "area",
          });
        }
      });

      dispatch(
        setFormattedSegments({
          segments,
          currentSegments,
          categories: totalHours.map((h) => `${parseInt(h)}:00`),
          lineGcsSeries,
          mixedGcsSeries,
          mixedHsSeries,
          mixedIpSeries,
        })
      );
    }
    // eslint-disable-next-line
  }, [segmentsSelector, currentSchedule]);

  return null;
};

export default SalesRequestsFormatter;
