import { Action } from "@reduxjs/toolkit";
import { RemoteResource } from "../../utils";
import { IAreaChecklist, IActivityItem, IStorePlan } from "../../../types";
import { ActionPayload } from "../../actions";

export interface ChecklistState {
  preShift: RemoteResource<IActivityItem[]>;
  posShift: RemoteResource<IActivityItem[]>;
  opening: RemoteResource<IAreaChecklist>;
  closing: RemoteResource<IAreaChecklist>;
  checklist: RemoteResource<IAreaChecklist>;
  myActivities: RemoteResource<IActivityItem[]>;
  loadingPreshift: boolean;
  loadingPosShift: boolean;
  loadingOpening: boolean;
  loadingClosing: boolean;
  loadingChecklist: boolean;
  loadingMyActivities: boolean;
  preShiftSyncError: boolean;
  posShiftSyncError: boolean;
  openingSyncError: boolean;
  closingSyncError: boolean;
  checklistSyncError: boolean;
  myActivitiesSyncError: boolean;
}

export enum ActionType {
  setPreShiftRequested = "checklist/set-preshift-requested",
  setPreShiftDone = "checklist/set-preshift-done",
  setPreShiftError = "checklist/set-preshift-error",
  setPosShiftRequested = "checklist/set--requested",
  setPosShiftDone = "checklist/set-posshift-done",
  setPosShiftError = "checklist/set-posshift-error",
  setAreasChecklistRequested = "checklist/set-areas-checklist-requested",
  setAreasChecklistDone = "checklist/set-areas-checklist-done",
  setAreasChecklistError = "checklist/set-areas-checklist-error",
  setChecklistsData = "checklist/set-checklists-data",
  setMyActivitiesRequested = "checklist/set-my-activities-requested",
  setMyActivitiesDone = "checklist/set-my-activities-done",
  setMyActivitiesError = "checklist/set-my-activities-error",
}

/** PRESHIFT */

export interface PreShiftRequestPayload {
  activityTypesId: string;
}
export interface PreShiftPayload {
  data: IActivityItem[];
}

export interface SetPreShiftRequestedAction
  extends ActionPayload<ActionType.setPreShiftRequested, PreShiftPayload> {}

export interface SetPreShiftDoneAction
  extends ActionPayload<ActionType.setPreShiftDone, PreShiftPayload> {}

export interface SetPreShiftErrorAction extends Action<ActionType.setPreShiftError> {}

/** POSTSHIFT */

export interface PosShiftRequestPayload {
  activityTypesId: string;
}
export interface PosShiftPayload {
  data: IActivityItem[];
}

export interface SetPosShiftRequestedAction
  extends ActionPayload<ActionType.setPosShiftRequested, PosShiftPayload> {}

export interface SetPosShiftDoneAction
  extends ActionPayload<ActionType.setPosShiftDone, PosShiftPayload> {}

export interface SetPosShiftErrorAction extends Action<ActionType.setPosShiftError> {}

/** AREAS CHECKLIST */

export interface AreasChecklistPayload {
  data: IAreaChecklist;
  activityTypesId: string;
  areas?: string[];
}

export interface SetAreasChecklistRequestedAction
  extends ActionPayload<ActionType.setAreasChecklistRequested, AreasChecklistPayload> {}

export interface SetAreasChecklistDoneAction
  extends ActionPayload<ActionType.setAreasChecklistDone, AreasChecklistPayload> {}

export interface SetAreasChecklistErrorAction
  extends ActionPayload<ActionType.setAreasChecklistError, { activityTypesId: string }> {}

/** CHECKLISTS DATA */

export interface ChecklistsDataPayload {
  data: IStorePlan;
}

export interface SetChecklistsDataAction
  extends ActionPayload<ActionType.setChecklistsData, ChecklistsDataPayload> {}

/** MY ACTIVITIES */

export interface MyActivitiesRequestPayload {
  activityTypesId: string;
}
export interface MyActivitiesPayload {
  data: IActivityItem[];
}

export interface SetMyActivitiesRequestedAction
  extends ActionPayload<ActionType.setMyActivitiesRequested, MyActivitiesPayload> {}

export interface SetMyActivitiesDoneAction
  extends ActionPayload<ActionType.setMyActivitiesDone, MyActivitiesPayload> {}

export interface SetMyActivitiesErrorAction extends Action<ActionType.setMyActivitiesError> {}

/** ACTIONS TYPES */

export type Actions =
  | SetPreShiftRequestedAction
  | SetPreShiftDoneAction
  | SetPreShiftErrorAction
  | SetPosShiftRequestedAction
  | SetPosShiftDoneAction
  | SetPosShiftErrorAction
  | SetAreasChecklistRequestedAction
  | SetAreasChecklistDoneAction
  | SetAreasChecklistErrorAction
  | SetChecklistsDataAction
  | SetMyActivitiesRequestedAction
  | SetMyActivitiesDoneAction
  | SetMyActivitiesErrorAction;
