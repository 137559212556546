import { call, put, select, takeEvery } from "redux-saga/effects";
import { PromiseResult, getQueryParams } from "../../../helpers/functions";
import ActivityServices, {
  NoteCommentsResponse,
  GetNotesResponse,
  NotesResponse,
} from "../../../services/ActivityServices";
import { AlertCode, IHttpRequestError, IManager, IQueryParams, IStorePlan } from "../../../types";
import { setSnackbar } from "../../app/actions";
import {
  addCommentDone,
  addCommentError,
  addNoteDone,
  addNoteError,
  deleteCommentDone,
  deleteCommentError,
  deleteNoteDone,
  deleteNoteError,
  getNotesDone,
  getNotesError,
  updateCommentDone,
  updateCommentError,
  updateNoteDone,
  updateNoteError,
} from "./actions";
import {
  ActionType,
  AddCommentRequestedAction,
  AddNoteRequestedAction,
  DeleteCommentRequestedAction,
  DeleteNoteRequestedAction,
  GetNotesRequestedAction,
  UpdateCommentRequestedAction,
  UpdateNoteRequestedAction,
} from "./types";
import { filtersSelector } from "../../admin/masterdata/selectors";
import { appUserSelector } from "../../user/selectors";
import { storePlanDataSelector } from "../../storePlan/selectors";
import { ApiResponse } from "../../../services/interfaces";

function* getNotesSagas(action: GetNotesRequestedAction) {
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    storePlanId: storePlan.storePlanId,
    fromDate: action.payload.from,
    toDate: action.payload.to,
  };
  try {
    const {
      result: getNotesResponse,
      hasError,
      error,
    }: PromiseResult<GetNotesResponse> = yield call(ActivityServices.getNotes, params);
    if (hasError || !getNotesResponse) throw error;
    yield put(getNotesDone({ notes: getNotesResponse.data }));
  } catch (error) {
    yield put(getNotesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.NOTES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* addNoteSagas(action: AddNoteRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    username: user.username,
  };
  try {
    const {
      result: addNoteResponse,
      hasError,
      error,
    }: PromiseResult<NotesResponse> = yield call(
      ActivityServices.addNote,
      action.payload.note,
      params
    );
    if (hasError || !addNoteResponse) throw error;
    yield put(addNoteDone({ note: addNoteResponse.data }));
  } catch (error) {
    yield put(addNoteError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.NOTES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateNoteSagas(action: UpdateNoteRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    username: user.username,
    storePlanId: storePlan.storePlanId,
    shiftTypeId: action.payload.note.shiftTypeId,
  };
  try {
    const {
      result: updateNoteResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.updateNote,
      action.payload.note,
      params
    );
    if (hasError || !updateNoteResponse) throw error;
    yield put(updateNoteDone({ note: action.payload.note }));
  } catch (error) {
    yield put(updateNoteError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.NOTES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteNoteSagas(action: DeleteNoteRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    username: user.username,
    storePlanId: storePlan.storePlanId,
  };
  try {
    const {
      result: deleteNoteResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.deleteNote,
      action.payload.id,
      params
    );
    if (hasError || !deleteNoteResponse) throw error;
    yield put(deleteNoteDone({ id: action.payload.id }));
  } catch (error) {
    yield put(deleteNoteError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.NOTES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* addCommentSagas(action: AddCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));
  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
  };
  try {
    const {
      result: addCommentResponse,
      hasError,
      error,
    }: PromiseResult<NoteCommentsResponse> = yield call(
      ActivityServices.addNoteComment,
      action.payload.comment,
      params
    );
    if (hasError || !addCommentResponse) throw error;
    yield put(addCommentDone({ comment: addCommentResponse.data }));
  } catch (error) {
    yield put(addCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateCommentSagas(action: UpdateCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  try {
    const {
      result: updateCommentResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.updateNoteComment,
      action.payload.comment,
      params
    );
    if (hasError || !updateCommentResponse) throw error;
    yield put(updateCommentDone({ comment: action.payload.comment }));
  } catch (error) {
    yield put(updateCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteCommentSagas(action: DeleteCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  try {
    const {
      result: deleteCommentResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.deleteNoteComment,
      action.payload.comment,
      params
    );
    if (hasError || !deleteCommentResponse) throw error;
    yield put(deleteCommentDone({ comment: action.payload.comment }));
  } catch (error) {
    yield put(deleteCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const activitySagas = [
  takeEvery(ActionType.getNotesRequested, getNotesSagas),
  takeEvery(ActionType.addNoteRequested, addNoteSagas),
  takeEvery(ActionType.updateNoteRequested, updateNoteSagas),
  takeEvery(ActionType.deleteNoteRequested, deleteNoteSagas),
  takeEvery(ActionType.addCommentRequested, addCommentSagas),
  takeEvery(ActionType.updateCommentRequested, updateCommentSagas),
  takeEvery(ActionType.deleteCommentRequested, deleteCommentSagas),
];

export default activitySagas;
