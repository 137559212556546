import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { IChartSerie } from "../../types";

interface Props {
  title?: string;
  series: IChartSerie[];
  categories: string[];
  height?: string | number;
}

const MixedChart = ({ title, series, categories, height }: Props) => {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [0, 2],
      curve: "smooth",
    },
    fill: {
      opacity: [0.85, 0.25],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    title: {
      text: title,
      align: "center",
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: "50%",
      },
    },
    labels: categories,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      offsetY: 2,
      style: {
        fontSize: "10px",
        colors: ["#fff"],
      },
      background: {
        enabled: false,
      },
    },
    markers: {
      size: 4,
      fillOpacity: 0.8,
    },
    tooltip: {
      shared: true,
      intersect: false,
      enabled: true,
      y: {
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
    },
    legend: {
      customLegendItems: ["Real", "Proy."],
    },
  };

  return <ReactApexChart options={options} series={series} type="line" height={height || 350} />;
};

export default MixedChart;
