import { call, put, select, takeEvery } from "redux-saga/effects";

import { PromiseResult, getQueryParams } from "../../helpers/functions";
import { AlertCode, IEmployeePositioning, IHttpRequestError, IQueryParams } from "../../types";
import {
  ActionType,
  GetLocationsRequestedAction,
  GetPositioningRequestedAction,
  UpdatePositioningRequestedAction,
} from "./types";
import { setSnackbar } from "../app/actions";
import OrquestServices, {
  GetLocationsResponse,
  GetPositioningResponse,
} from "../../services/OrquestServices";
import { filtersSelector } from "../admin/masterdata/selectors";
import {
  getLocationsDone,
  getLocationsError,
  getPositioningDone,
  getPositioningError,
  updatePositioningDone,
  updatePositioningError,
} from "./actions";

function* getPositioningSagas(action: GetPositioningRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getPositioningResponse,
      hasError,
      error,
    }: PromiseResult<GetPositioningResponse> = yield call(OrquestServices.getPositioning, params);
    if (hasError || !getPositioningResponse) throw error;
    const positioning = getPositioningResponse.data.data;
    yield put(getPositioningDone({ positioning: positioning }));
  } catch (error) {
    console.log("error", error);
    yield put(getPositioningError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.POSITIONING_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updatePositioningSagas(action: UpdatePositioningRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: updatePositioningResponse,
      hasError,
      error,
    }: PromiseResult<IEmployeePositioning> = yield call(
      OrquestServices.updatePositioning,
      params,
      action.payload.positioning
    );
    if (hasError || !updatePositioningResponse) throw error;
    yield put(updatePositioningDone({ positioning: action.payload.positioning }));
  } catch (error) {
    yield put(updatePositioningError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.POSITIONING_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getLocationsSagas(action: GetLocationsRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getLocationsResponse,
      hasError,
      error,
    }: PromiseResult<GetLocationsResponse> = yield call(OrquestServices.getLocations, params);
    if (hasError || !getLocationsResponse) throw error;
    const locations = getLocationsResponse.data.data;
    yield put(getLocationsDone({ locations }));
  } catch (error) {
    yield put(getLocationsError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.POSITIONING_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const orquestSagas = [
  takeEvery(ActionType.getPositioningRequested, getPositioningSagas),
  takeEvery(ActionType.getLocationsRequested, getLocationsSagas),
  takeEvery(ActionType.updatePositioningRequested, updatePositioningSagas),
];

export default orquestSagas;
