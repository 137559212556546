import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n.config";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/auth.config";
import MsalReduxIntegration from "./pages/signIn/MsalReduxIntegration";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import InfoIcon from "@mui/icons-material/Error";
import { styled } from "@mui/material";
import { primary_main } from "./theme/theme";
import { ConnectionSB } from "./components/AppSnackbar";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: primary_main,
  },
}));

declare module "notistack" {
  interface VariantOverrides {
    connectionAlert: true;
  }
}

const AppWrapper = () => {
  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <MsalReduxIntegration />
          <I18nextProvider i18n={i18n}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={4000}
              iconVariant={{ default: <InfoIcon sx={{ width: 18, height: 18, mr: 1 }} /> }}
              Components={{
                default: StyledMaterialDesignContent,
                connectionAlert: ConnectionSB,
              }}
            >
              <App />
            </SnackbarProvider>
          </I18nextProvider>
        </Provider>
      </MsalProvider>
    </React.Fragment>
  );
};

const container = document.getElementById("root");
const root = createRoot(container as never);

root.render(<AppWrapper />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
