import { ObjectivesState } from "./types";

interface State {
  objectives: ObjectivesState;
}

export const objectivesSelector = (state: State) => state.objectives.objectives;
export const objectivesDataSelector = (state: State) => state.objectives.objectives.data || [];
export const invokedServicesSelector = (state: State) => state.objectives.invokedServices;
export const sosDtReportSelector = (state: State) => state.objectives.sosDtReport.data;
export const sosFcReportSelector = (state: State) => state.objectives.sosFcReport.data;
export const sosMfyReportSelector = (state: State) => state.objectives.sosMfyReport.data;
export const sosReportRequestedSelector = (state: State) =>
  Boolean(state.objectives.sosDtReport.data) ||
  Boolean(state.objectives.sosFcReport.data) ||
  Boolean(state.objectives.sosMfyReport.data);

export const isLoadingObjectivesSelector = (state: State) => state.objectives.loadingObjectives;
export const isLoadingCommentSelector = (state: State) => state.objectives.loadingComment;
export const objectiveErrorSelector = (state: State) => state.objectives.objectiveError;
export const commentErrorSelector = (state: State) => state.objectives.commentError;
