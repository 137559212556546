import { IActivitiesState } from "../../../types";
import { RevState } from "./types";

interface State {
  rev: RevState;
}

function getActivitiesState(state: State): IActivitiesState {
  const syncRequired = state.rev.hourlyReviewsSyncError;

  const syncError: { [key: string]: boolean } = {
    REV: state.rev.hourlyReviewsSyncError,
  };

  return { syncRequired, syncError };
}

export const hourlyReviewSelector = (state: State) => state.rev.hourlyReviews;
export const hourlyReviewDataSelector = (state: State) => state.rev.hourlyReviews.data || [];
export const loadingHourlyReviewSelector = (state: State) => state.rev.loadingHourlyReview;
export const hourlyReviewConfirmedSelector = (state: State) => state.rev.hourlyReviewState;
export const revStateSelector = (state: State) => getActivitiesState(state);
