import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { restrictedRoutes, routes } from "./routes";
import { useSelector } from "react-redux";
import { appUserSelector } from "../redux/user/selectors";

interface Props {
  isAuthenticated: boolean;
}

const PrivateRoute = ({ isAuthenticated }: Props) => {
  const location = useLocation();
  const userRol = useSelector(appUserSelector);
  const isAdmin = userRol?.rol === "admin";
  const defaultRoute = routes.default.path;

  const checkUrlAvailable = (url: string) => {
    if (!isAdmin && restrictedRoutes.includes(url)) return false;
    return true;
  };
  const urlAvailable = checkUrlAvailable(location.pathname);

  return !isAuthenticated ? (
    <Navigate
      to={{
        pathname: `${defaultRoute}`,
      }}
      state={{ from: location }}
    />
  ) : urlAvailable ? (
    <Outlet />
  ) : (
    <Navigate to={defaultRoute} />
  );
};

export default PrivateRoute;
