import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActionButton } from "../../styles";
import { useTranslation } from "react-i18next";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { Divider, Grid, Typography } from "@mui/material";
import { addDays, subMonths } from "date-fns";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  dateRange: { from: Date; to: Date };
  onSubmit: (from?: Date, to?: Date) => void;
}

export const DateRangeModal = ({ open, dateRange, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = React.useState(dateRange.from);
  const [toDate, setToDate] = React.useState(dateRange.to);

  const handleClose = () => {
    onSubmit();
  };

  const handleSubmit = () => {
    onSubmit(fromDate, toDate);
  };

  const handleFromDate = (date: Date) => {
    setFromDate(date);
    setToDate(date);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="description"
      maxWidth="md"
    >
      <DialogContent sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 0 }}>
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {t("labels.from")}
            </Typography>
            <Divider sx={{ mx: 3, bgcolor: "primary.main", height: 3 }} />
            <StaticDatePicker
              value={fromDate}
              onChange={(date) => date && handleFromDate(date)}
              slots={{ actionBar: () => null, toolbar: () => null }}
              showDaysOutsideCurrentMonth
              minDate={subMonths(new Date(), 3)}
              maxDate={new Date()}
              disableFuture
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {t("labels.to")}
            </Typography>
            <Divider sx={{ mx: 3, bgcolor: "primary.main", height: 3 }} />
            <StaticDatePicker
              value={toDate}
              onChange={(date) => date && setToDate(date)}
              slots={{ actionBar: () => null, toolbar: () => null }}
              disableHighlightToday
              showDaysOutsideCurrentMonth
              minDate={fromDate}
              maxDate={addDays(fromDate, 6)}
              disableFuture
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 1, mb: 1, pt: 0 }}>
        <ActionButton
          variant="outlined"
          onClick={handleClose}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          {t("labels.close")}
        </ActionButton>
        <ActionButton variant="contained" onClick={handleSubmit}>
          {t("labels.confirm")}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeModal;
