import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Chip, Link, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FeatureIcon from "@mui/icons-material/Check";
import FixIcon from "@mui/icons-material/Close";
import { IChangelogItemDescription, IChangelogItem } from "../../types";
import { getChangelogDescription } from "../../helpers/functions";
import { useTranslation } from "react-i18next";

function isAvailableVersion(appVersion?: string, changelogVersion?: string) {
  if (!appVersion || !changelogVersion) return false;

  const appV = appVersion.split(".").map((v) => parseInt(v));
  const changelogV = changelogVersion.split(".").map((v) => parseInt(v));

  if (appV[0] > changelogV[0]) return true;
  else if (appV[0] === changelogV[0] && appV[1] >= changelogV[1]) return true;
  else return false;
}

interface Props {
  item: IChangelogItem;
  isExpanded: boolean;
  toggle: (id: string, expanded: boolean) => void;
}

const ChangelogAccordion = ({ item, isExpanded, toggle }: Props) => {
  const { t } = useTranslation();

  const ignoreVersion = !window.location.hostname.includes("miturno.arcosdorados.net");

  const getSecondaryText = (item: IChangelogItemDescription) => {
    if (item.type === "fix") return t("pages.changelogs.bugFix");
    else return t("pages.changelogs.newFeat");
  };

  if (!isAvailableVersion(process.env.REACT_APP_VERSION, item.version) && !ignoreVersion)
    return null;

  return (
    <Accordion expanded={isExpanded} onChange={(e, expanded) => toggle(item.id, expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${item.id}-content`}
        id={item.id}
      >
        <Typography sx={{ width: 72, flexShrink: 0 }}>{item.version}</Typography>
        <Typography sx={{ color: "text.secondary" }}>{getChangelogDescription(item)}</Typography>
        {process.env.REACT_APP_VERSION === item.version && (
          <Chip
            color="secondary"
            label={t("labels.currentVersion")}
            size="small"
            sx={{ mx: 5, color: "#FFF" }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6">{`${t("pages.changelogs.listOfChanges")}:`}</Typography>
        <List dense>
          {item.changelogs.map((c, i) => (
            <ListItem key={`${i}-${c.type}`}>
              <ListItemIcon>{c.type === "fix" ? <FixIcon /> : <FeatureIcon />}</ListItemIcon>
              <ListItemText primary={getChangelogDescription(c)} secondary={getSecondaryText(c)} />
            </ListItem>
          ))}
        </List>
        {item.links?.length > 0 && (
          <Box mt={1}>
            <Typography>{"Más información:"}</Typography>
            <List dense sx={{ p: 0 }}>
              {item.links.map((e, i) => (
                <ListItem key={`${i}-${e}`} sx={{ py: 0 }}>
                  <ListItemText
                    primary={
                      <Link href={e} target="_blank" rel="noopener">
                        {t("labels.pdfDocument")}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ChangelogAccordion;
