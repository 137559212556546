import { IAppFilters, IMasterdata } from "../../../types";
import {
  ActionType,
  SetFiltersAction,
  GetMasterdataDoneAction,
  GetMasterdataErrorAction,
  GetMasterdataPayload,
  GetMasterdataRequestedAction,
  SetFilteredOptionsAction,
} from "./types";

/** MASTER DATA */

export const getMasterdataRequested = (): GetMasterdataRequestedAction => {
  return { type: ActionType.getMasterdataRequested };
};

export const getMasterdataDone = (result: GetMasterdataPayload): GetMasterdataDoneAction => {
  return { type: ActionType.getMasterdataDone, payload: result };
};

export const getMasterdataError = (): GetMasterdataErrorAction => {
  return { type: ActionType.getMasterdataError };
};

/** FILTERS OPTIONS */

export const setFilters = (result: { filters: IAppFilters }): SetFiltersAction => {
  return { type: ActionType.setFilters, payload: result };
};

export const setFilteredOptions = (result: { options: IMasterdata }): SetFilteredOptionsAction => {
  return { type: ActionType.setFilteredOptions, payload: result };
};

