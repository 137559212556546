import { useTranslation } from "react-i18next";
import {
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  useTheme,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { routes } from "../router/routes";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";
import SalesDashboardIcon from "@mui/icons-material/MonetizationOn";
import EmployeePositioningIcon from "@mui/icons-material/Badge";
import ShiftOpeningIcon from "@mui/icons-material/AlarmOn";
import ShiftClosingIcon from "@mui/icons-material/AlarmOff";
import UserIcon from "@mui/icons-material/ManageAccounts";
import SchedulesIcon from "@mui/icons-material/PendingActions";
import AreasIcon from "@mui/icons-material/Fastfood";
import PreShiftIcon from "@mui/icons-material/EventAvailable";
import GoalsIcon from "@mui/icons-material/CrisisAlert";
import PreShiftTourIcon from "@mui/icons-material/Rule";
import ShiftTourIcon from "@mui/icons-material/History";
import PosShiftIcon from "@mui/icons-material/ContentPasteOff";
import CustomCheklistIcon from "@mui/icons-material/NoteAltOutlined";
import { useSelector } from "react-redux";
import { isSidebarOpenSelector, navigateSelector } from "../redux/app/selectors";
import { appUserSelector, permissionsDataSelector } from "../redux/user/selectors";
import { useDispatch } from "react-redux";
import { setNavigateController, toggleSidebar } from "../redux/app/actions";
import { activityTypesDataSelector, storePlanIdSelector } from "../redux/storePlan/selectors";
import { getActivityTypeDescription } from "../helpers/functions";
import { ActivityType, AppPermissions } from "../types";
import { myActivitiesDataSelector } from "../redux/activities/checklists/selectors";
import { grey } from "@mui/material/colors";
import { StyledListItemButton } from "./styles";

const SidebarNavMobile = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const permissions = useSelector(permissionsDataSelector);
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const activityTypes = useSelector(activityTypesDataSelector);
  const myActivitiesData = useSelector(myActivitiesDataSelector);
  const user = useSelector(appUserSelector);
  const storePlanId = useSelector(storePlanIdSelector);

  const navigateController = useSelector(navigateSelector);

  const isAdmin = user?.rol === "admin";
  const isManager = user?.rol === "manager" && storePlanId;

  const getIconColor = () => {
    if (theme.palette.mode === "dark") return "inherit";
    return "primary";
  };

  const adminItems = [
    {
      route: routes.main.managers.path,
      label: t("labels.managers"),
      icon: <UserIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.schedules.path,
      label: t("labels.schedules"),
      icon: <SchedulesIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.restaurant.path,
      label: t("labels.areas"),
      icon: <AreasIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.customChecklist.path,
      label: t("labels.activities"),
      icon: <CustomCheklistIcon color={getIconColor()} />,
      enabled: true,
    },
  ];

  const storeItems = [
    {
      route: routes.main.opening.path,
      icon: <ShiftOpeningIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.OPENING]),
      activityType: ActivityType.OPENING,
    },
    {
      route: routes.main.closing.path,
      icon: <ShiftClosingIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CLOSING]),
      activityType: ActivityType.CLOSING,
    },
    {
      route: routes.main.hourlyReview.path,
      icon: <ShiftTourIcon color={getIconColor()} />,
      label: "Peak hour",
      activityType: ActivityType.SHIFT_TOUR,
    },
    {
      route: routes.main.myActivities.path,
      icon: <CustomCheklistIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CUSTOM]),
      activityType: ActivityType.CUSTOM,
      enabled: myActivitiesData.length > 0,
    },
  ];

  const shiftItems = [
    {
      route: routes.main.preShift.path,
      icon: <PreShiftIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.PRE_SHIFT]),
      activityType: ActivityType.PRE_SHIFT,
      enabled: true,
    },
    {
      route: routes.main.objectives.path,
      icon: <GoalsIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.OBJETIVES]),
      activityType: ActivityType.OBJETIVES,
      enabled: true,
    },
    {
      route: routes.main.checklist.path,
      icon: <PreShiftTourIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CHECKLIST]),
      activityType: ActivityType.CHECKLIST,
      enabled: true,
    },
    {
      route: routes.main.posShift.path,
      icon: <PosShiftIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.POS_SHIFT]),
      activityType: ActivityType.POS_SHIFT,
      enabled: true,
    },
  ];

  const handleClick = (value: string) => {
    isMobile && isSidebarOpen && dispatch(toggleSidebar());
    dispatch(
      setNavigateController({
        navigateController: {
          ...navigateController,
          path: value,
          currentPath: location.pathname,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ bgcolor: grey[400], width: 24, alignSelf: "center", mt: 0.5, py: 0.5 }}
        onClick={() => dispatch(toggleSidebar())}
      />

      <List dense component={Stack} direction={"row"}>
        <StyledListItemButton
          onClick={() =>
            handleClick(isManager ? routes.main.activitiesDashboard.path : routes.main.home.path)
          }
          selected={[routes.main.activitiesDashboard.path, routes.main.home.path].includes(
            location.pathname
          )}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <HomeIcon color={getIconColor()} />
          </ListItemIcon>
          <ListItemText primary={t("labels.shiftPlan")} />
        </StyledListItemButton>
        {isManager && permissions.includes(AppPermissions.SALES) && (
          <StyledListItemButton
            onClick={() => handleClick(routes.main.salesDashboard.path)}
            selected={[routes.main.salesDashboard.path].includes(location.pathname)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <SalesDashboardIcon color={getIconColor()} />
            </ListItemIcon>
            <ListItemText primary={t("labels.salesDashboard")} />
          </StyledListItemButton>
        )}
        {isManager && permissions.includes(AppPermissions.POSITIONING) && (
          <StyledListItemButton
            onClick={() => handleClick(routes.main.employeePositioning.path)}
            selected={[routes.main.employeePositioning.path].includes(location.pathname)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <EmployeePositioningIcon color={getIconColor()} />
            </ListItemIcon>
            <ListItemText primary={t("labels.positioning")} />
          </StyledListItemButton>
        )}
      </List>
      {isAdmin && (
        <>
          <List component={Stack} direction={"row"}>
            {adminItems.map(
              (i) =>
                i.enabled && (
                  <StyledListItemButton
                    key={i.route}
                    selected={location.pathname.includes(i.route)}
                    sx={{ px: 0.2 }}
                    onClick={() => handleClick(i.route)}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>{i.icon}</ListItemIcon>
                    <ListItemText primary={i.label} />
                  </StyledListItemButton>
                )
            )}
          </List>
        </>
      )}
      {isManager && (
        <>
          <List dense component={Stack} direction={"row"}>
            {storeItems.map((i) => (
              <ListItem key={i.route} disablePadding>
                <StyledListItemButton
                  role={undefined}
                  selected={location.pathname.includes(i.route)}
                  sx={{ px: 0.2 }}
                  onClick={() => handleClick(i.route)}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>{i.icon}</ListItemIcon>
                  <ListItemText primary={i.label} />
                </StyledListItemButton>
              </ListItem>
            ))}
          </List>
          <List dense component={Stack} direction={"row"}>
            {shiftItems.map(
              (i) =>
                i.enabled && (
                  <ListItem key={i.route} disablePadding>
                    <StyledListItemButton
                      role={undefined}
                      selected={location.pathname.includes(i.route)}
                      sx={{ px: 0.2 }}
                      onClick={() => handleClick(i.route)}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>{i.icon}</ListItemIcon>
                      <ListItemText primary={i.label} />
                    </StyledListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </>
      )}
      <List
        dense
        component={Stack}
        direction={"row"}
        sx={{ display: "grid", justifyItems: "center" }}
      >
        <StyledListItemButton
          onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || "")}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <AppsIcon color={getIconColor()} />
          </ListItemIcon>
          <ListItemText primary="Flex Tiles" />
        </StyledListItemButton>
      </List>
    </>
  );
};

export default SidebarNavMobile;
