import { INoteItem, ICommentItem } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
} from "../../utils";
import { Actions, ActionType, NotesState } from "./types";

const initialState: NotesState = {
  notes: initialRemoteResource([]),
  lastRequest: {},
  noteModalOpen: false,
  selectedNote: null,
  loadingNote: false,
  loadingComment: false,
  notesError: false,
  commentError: false,
};

const addNote = (notes: INoteItem[], note: INoteItem) => {
  const newNotes = [...notes, note];
  return newNotes;
};

const updateNotes = (notes: INoteItem[], note: INoteItem) => {
  const index = notes.findIndex((i) => i.id === note.id);
  if (index !== -1) {
    let newNotes = [...notes];
    newNotes[index] = { ...note };
    return newNotes;
  } else {
    return notes;
  }
};

const deleteNote = (notes: INoteItem[], id: number) => {
  const newNotes = [...notes.filter((i) => i.id !== id)];
  return newNotes;
};

const handleAddComment = (
  state: NotesState,
  comment: ICommentItem
): NotesState => {
  const notes = state.notes.data;
  const noteIndex = notes.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newNotes: INoteItem[] = notes.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        newCmts.push(comment);
      }
      return { ...n, comments: newCmts };
    });
    return {
      ...state,
      notes: doneRemoteResource(newNotes),
      selectedNote: newNotes[noteIndex],
    };
  } else return { ...state };
};

const handleUpdateComment = (
  state: NotesState,
  comment: ICommentItem
): NotesState => {
  const notes = state.notes.data;
  const noteIndex = notes.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newNotes: INoteItem[] = notes.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        const cmtIndex = newCmts.findIndex((c) => c.id === comment.id);
        if (cmtIndex !== -1) newCmts[cmtIndex] = { ...comment };
      }
      return { ...n, comments: newCmts };
    });
    return {
      ...state,
      notes: doneRemoteResource(newNotes),
      selectedNote: newNotes[noteIndex],
    };
  } else return { ...state };
};

const handleDeleteComment = (
  state: NotesState,
  comment: ICommentItem
): NotesState => {
  const notes = state.notes.data;
  const noteIndex = notes.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newNotes: INoteItem[] = notes.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index)
        newCmts = newCmts.filter((c) => c.id !== comment.id);
      return { ...n, comments: newCmts };
    });
    return {
      ...state,
      notes: doneRemoteResource(newNotes),
      selectedNote: newNotes[noteIndex],
    };
  } else return { ...state };
};

/** REDUCER */

const activityReducer = (
  state: NotesState = initialState,
  action: Actions
): NotesState => {
  switch (action.type) {
    /** NOTES */
    case ActionType.toggleNoteModal:
      return {
        ...state,
        noteModalOpen: !state.noteModalOpen,
      };
    case ActionType.setSelectedNote:
      return {
        ...state,
        selectedNote: action.payload.note,
      };
    case ActionType.getNotesRequested:
      return {
        ...state,
        lastRequest: action.payload,
        loadingNote: true,
        notesError: false,
      };
    case ActionType.getNotesDone:
      return {
        ...state,
        notes: doneRemoteResource(action.payload.notes),
        loadingNote: false,
      };
    case ActionType.getNotesError:
      return {
        ...state,
        notes: errorRemoteResource([]),
        loadingNote: false,
      };
    case ActionType.addNoteRequested:
      return {
        ...state,
        loadingNote: true,
      };
    case ActionType.addNoteDone:
      return {
        ...state,
        notes: doneRemoteResource(
          addNote(state.notes.data || [], action.payload.note as INoteItem)
        ),
        loadingNote: false,
      };
    case ActionType.addNoteError:
      return {
        ...state,
        loadingNote: false,
      };
    case ActionType.updateNoteRequested:
      return {
        ...state,
        loadingNote: true,
        notesError: false,
      };
    case ActionType.updateNoteDone:
      return {
        ...state,
        notes: doneRemoteResource(
          updateNotes(state.notes.data || [], action.payload.note as INoteItem)
        ),
        loadingNote: false,
      };
    case ActionType.updateNoteError:
      return {
        ...state,
        loadingNote: false,
        notesError: true,
      };
    case ActionType.deleteNoteRequested:
      return {
        ...state,
        loadingNote: true,
      };
    case ActionType.deleteNoteDone:
      return {
        ...state,
        notes: doneRemoteResource(
          deleteNote(state.notes.data || [], action.payload.id)
        ),
        loadingNote: false,
      };
    case ActionType.deleteNoteError:
      return {
        ...state,
        loadingNote: false,
      };
    /** COMMENTS */
    case ActionType.addCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.addCommentDone:
      return {
        ...handleAddComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.addCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.updateCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.updateCommentDone:
      return {
        ...handleUpdateComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.updateCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.deleteCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.deleteCommentDone:
      return {
        ...handleDeleteComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.deleteCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.clearNotes:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default activityReducer;
