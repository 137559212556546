import { Action } from "@reduxjs/toolkit";
import { IAppFilters, IMasterdata } from "../../../types";
import { ActionPayload } from "../../actions";
import { RemoteResource } from "../../utils";

export interface MasterdataState {
  masterdata: RemoteResource<IMasterdata>;
  filters: IAppFilters;
  filteredOptions: IMasterdata;
  loading: boolean;
}

export enum ActionType {
  getMasterdataRequested = "admin/get-master-data-requested",
  getMasterdataDone = "admin/get-master-data-done",
  getMasterdataError = "admin/get-master-data-error",
  setFilters = "admin/set-filters",
  setFilteredOptions = "admin/set-filtered-options",
}

/** GET MASTER DATA */

export interface GetMasterdataPayload {
  masterdata: IMasterdata;
}

export interface GetMasterdataRequestedAction extends Action<ActionType.getMasterdataRequested> {}

export interface GetMasterdataDoneAction
  extends ActionPayload<ActionType.getMasterdataDone, GetMasterdataPayload> {}

export interface GetMasterdataErrorAction extends Action<ActionType.getMasterdataError> {}

/** FILTERS */

export interface SetFiltersAction
  extends ActionPayload<ActionType.setFilters, { filters: IAppFilters }> {}

export interface SetFilteredOptionsAction
  extends ActionPayload<ActionType.setFilteredOptions, { options: IMasterdata }> {}


/** ACTION TYPES */

export type Actions =
  | GetMasterdataRequestedAction
  | GetMasterdataDoneAction
  | GetMasterdataErrorAction
  | SetFiltersAction
  | SetFilteredOptionsAction;
