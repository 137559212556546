import { UserState } from "./types";

interface State {
  user: UserState;
}

export const userStateSelector = (state: State) => state.user;
export const appUserSelector = (state: State) => state.user.appUser;
export const isAuthenticatedSelector = (state: State) => state.user.isAuthenticated;
export const infoSelector = (state: State) => state.user.info;
export const permissionsSelector = (state: State) => state.user.permissions;
export const permissionsDataSelector = (state: State) => state.user.permissions.data;
export const preferencesSelector = (state: State) => state.user.preferences;
