import { createTheme, PaletteMode } from "@mui/material";

export const primary_main = "#34495E";
export const primary_light = "#E1E7EC"; // E1E7EC F5F5F5
export const secondary_main = "#FFBC0D";
export const secondary_light = "#FFEBC3";

export const getMuiTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      primary: {
        main: primary_main,
        light: primary_light,
      },
      secondary: {
        main: secondary_main,
        light: secondary_light,
      },
      common: {
        black: "#210D0E",
      },
      mode: mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              color: "inherit",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: 2.5,
                borderRadius: 10,
              },
              "&:hover fieldset": {
                borderColor: secondary_main,
              },
              "&.Mui-focused fieldset": {
                borderColor: secondary_main,
                borderWidth: 2.5,
              },
            },
          },
        },
      },
    },
  });

// export const dark_primary_main = "#F6F6F6";
// export const dark_primary_light = "#E8E8E8";
// export const getMuiTheme = (mode: PaletteMode) =>
//   createTheme({
//     palette: {
//       mode: mode,
//       ...(mode === "dark"
//         ? {
//             primary: {
//               main: dark_primary_main,
//               light: dark_primary_light,
//             },
//           }
//         : {
//             primary: {
//               main: primary_main,
//               light: primary_light,
//             },
//           }),
//       secondary: {
//         main: secondary_main,
//         light: secondary_light,
//       },
//       common: {
//         black: "#210D0E",
//       },
//     },
//     components: {
//       MuiTextField: {
//         styleOverrides: {
//           root: {
//             "& label.Mui-focused": {
//               color: "inherit",
//             },
//             "& .MuiOutlinedInput-root": {
//               "& fieldset": {
//                 borderWidth: 2.5,
//                 borderRadius: 10,
//               },
//               "&:hover fieldset": {
//                 borderColor: secondary_main,
//               },
//               "&.Mui-focused fieldset": {
//                 borderColor: secondary_main,
//                 borderWidth: 2.5,
//               },
//             },
//           },
//         },
//       },
//     },
//   });

export const palette_primary_medium = "#688CAD";
