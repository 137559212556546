import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ActionButton } from "../../styles";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/Check";
import NewVersionIcon from "@mui/icons-material/NewReleases";
import { AlertColor, Box, SxProps, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertSelector } from "../../redux/app/selectors";
import { IAlertMessage, IErrorResponse, IHttpRequestError } from "../../types";
import { consumeAlert } from "../../redux/app/actions";
import { isValidErrorMessage } from "../../helpers/functions";

const gridBox: SxProps = { display: "grid", alignItems: "center", justifyContent: "center", mb: 2 };
const msgBox: SxProps = { display: "grid", alignItems: "center", textAlign: "center" };
const lgIcon: SxProps = { width: 50, height: 50, justifySelf: "center", mb: 1 };

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useSelector(alertSelector);
  const [open, setOpen] = useState(false);

  const renderIcon = (severity?: AlertColor) => {
    switch (severity) {
      case "error":
        return <ErrorIcon color="error" sx={lgIcon} />;
      case "info":
        return <InfoIcon color="info" sx={lgIcon} />;
      case "success":
        return <SuccessIcon color="success" sx={lgIcon} />;
      default:
        return <NewVersionIcon color="secondary" sx={lgIcon} />;
    }
  };

  const renderError = (error: IHttpRequestError) => {
    const severity = alert?.severity || "error";
    const codeError = error.data as IErrorResponse;
    let title = "";
    let description = "";

    if (codeError?.message) {
      title = t(`errors.codes.${codeError.message}.title`);
      description = t(`errors.codes.${codeError.message}.description`, {
        username: `"${codeError.username}"`,
      });
    } else {
      if (error.code === -1) localStorage.clear();
      title = t(`errors.codes.${error.code}.title`);
      description = t(`errors.codes.${error.code}.description`);
    }

    if (!isValidErrorMessage(title)) title = t("errors.codes.-1.title");
    if (!isValidErrorMessage(description)) description = t("errors.codes.-1.description");

    return (
      <>
        <Box sx={gridBox}>
          {renderIcon(severity)}
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Typography textAlign={"center"}>{description}</Typography>;
      </>
    );
  };

  const renderMessage = (alert?: IAlertMessage) => {
    return (
      <Box sx={msgBox}>
        {renderIcon(alert?.severity)}
        <Typography variant="h5">{alert?.title}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {alert?.message}
        </Typography>
      </Box>
    );
  };

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;

    setOpen(false);
    dispatch(consumeAlert());
  };

  const handleAction = (action?: string) => {
    switch (action) {
      case "new-version":
        window.location.reload();
        break;

      default:
        handleClose();
        break;
    }
  };

  React.useEffect(() => {
    if (alert) {
      if (alert.error?.code === -1) {
        localStorage.clear();
        window.location.reload();
      } else setOpen(true);
    }
    // eslint-disable-next-line
  }, [alert]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={(e, reason) => handleClose(reason)}
      aria-describedby="description"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogContent>
        {alert?.error ? renderError(alert.error) : renderMessage(alert)}
      </DialogContent>
      <DialogActions sx={{ mx: 1, mb: 1, justifyContent: "center" }}>
        <ActionButton
          variant="contained"
          onClick={() => handleAction(alert?.action)}
          sx={{ width: "auto", px: 4 }}
        >
          {alert?.actionLabel || "OK"}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
