import { shiftApi } from "../config/axiosInstances";
import { PromiseResult, promiseResultWrapper } from "../helpers/functions";
import { IQueryParams, IDailyTotals, ISalesHoursRequest } from "../types";
import { ApiResponse } from "./interfaces";

export interface GetSalesResponse {
  data: IDailyTotals;
}

class SalesServices {
  static async getDailyTotals(params: IQueryParams): Promise<PromiseResult<GetSalesResponse>> {
    const { businessDate, storeAcronym, country } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.get("/sales", { params: { businessDate, storeAcronym, country } })
    );
  }

  static async updateSalesHours(
    params: IQueryParams,
    body: ISalesHoursRequest
  ): Promise<PromiseResult<ApiResponse>> {
    const { businessDate, storeAcronym, country } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.put("/sales_hours", { params: { businessDate, storeAcronym, country }, body })
    );
  }
}

export default SalesServices;
