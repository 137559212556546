import { IManager } from "../../../types";
import {
  ActionType,
  GetManagersRequestedAction,
  GetManagersDoneAction,
  GetManagersErrorAction,
  GetManagersPayload,
  AddManagerRequestedAction,
  AddManagerDoneAction,
  AddManagerErrorAction,
  AddManagerPayload,
  UpdateManagerRequestedAction,
  UpdateManagerDoneAction,
  UpdateManagerErrorAction,
  UpdateManagerPayload,
  DeleteManagerRequestedAction,
  DeleteManagerDoneAction,
  DeleteManagerErrorAction,
  DeleteManagerPayload,
  GetClearDataAction,
} from "./types";

/** MANAGERS */

export const getManagersRequested = (): GetManagersRequestedAction => {
  return { type: ActionType.getManagersRequested };
};

export const getManagersDone = (result: GetManagersPayload): GetManagersDoneAction => {
  return { type: ActionType.getManagersDone, payload: result };
};

export const getManagersError = (): GetManagersErrorAction => {
  return { type: ActionType.getManagersError };
};

export const addManagerRequested = (manager: IManager): AddManagerRequestedAction => {
  return { type: ActionType.addManagerRequested, payload: { manager } };
};

export const addManagerDone = (result: AddManagerPayload): AddManagerDoneAction => {
  return { type: ActionType.addManagerDone, payload: result };
};

export const addManagerError = (): AddManagerErrorAction => {
  return { type: ActionType.addManagerError };
};

export const updateManagerRequested = (manager: IManager): UpdateManagerRequestedAction => {
  return { type: ActionType.updateManagerRequested, payload: { manager } };
};

export const updateManagerDone = (result: UpdateManagerPayload): UpdateManagerDoneAction => {
  return { type: ActionType.updateManagerDone, payload: result };
};

export const updateManagerError = (): UpdateManagerErrorAction => {
  return { type: ActionType.updateManagerError };
};

export const deleteManagerRequested = (identifier: number): DeleteManagerRequestedAction => {
  return { type: ActionType.deleteManagerRequested, payload: { identifier } };
};

export const deleteManagerDone = (result: DeleteManagerPayload): DeleteManagerDoneAction => {
  return { type: ActionType.deleteManagerDone, payload: result };
};

export const deleteManagerError = (): DeleteManagerErrorAction => {
  return { type: ActionType.deleteManagerError };
};

/** CLEAR DATA */

export const clearData = (): GetClearDataAction => {
  return { type: ActionType.clearData };
};
