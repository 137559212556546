import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slider,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { numberFormatter } from "../helpers/functions";
import MenuIcon from "@mui/icons-material/Menu";
import SaveIcon from "@mui/icons-material/Save";
import CopyIcon from "@mui/icons-material/Difference";
import { ISalesHoursRequest, ISegmentItem } from "../types";
import NoRowsOverlay from "./NoRowsOverlay";
import { useDispatch } from "react-redux";
import { formattedSegmentsSelector } from "../redux/sales/selectors";
import { useSelector } from "react-redux";
import { setFormattedSegments, updateSalesHoursRequested } from "../redux/sales/actions";
import { grey } from "@mui/material/colors";

const headerStyle: SxProps = {
  width: "100%",
  flex: "auto",
  fontWeight: 500,
  opacity: 0.95,
};

const fieldCellStyle: SxProps = {
  flex: "auto",
  "& .MuiInputBase-root:before": {
    borderBottom: "0px !important",
  },
};

const horizontalSlider: SxProps<Theme> = (theme) => ({
  width: "80%",
  mb: -0.5,
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 2,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : grey[600],
    },
  },
  "& .MuiSlider-markLabel": {
    fontSize: 10,
    top: 20,
  },
});

const fields = ["hour", "hsReal", "hsProy", "ipReal", "ipProy"];
const editable_fields = ["hsReal", "hsProy"];
const calc_fields = ["ipReal", "ipProy"];

const headerMap: { [key: string]: string } = {
  hour: "Hora",
  hsReal: "Hs Real",
  hsProy: "Hs Proy.",
  ipReal: "IP Real",
  ipProy: "IP Proy.",
};

interface IData {
  [key: string]: number;
}

interface IFormData {
  [key: string]: IData;
}

function getFieldValue(item: ISegmentItem, field: string, formData: IFormData) {
  if (field === "hour") return `${item[field]}:00`;

  if (editable_fields.includes(field) && formData[item.hour]) return formData[item.hour][field];

  if (calc_fields.includes(field))
    if (formData[item.hour]) return numberFormatter(formData[item.hour][field] || 0, 1, 2);
    else return numberFormatter(0, 1, 2);

  return item[field] || 0;
}

function formatRequest(formData: IFormData, segments: string[]): ISalesHoursRequest {
  let request: ISalesHoursRequest = {};

  segments.forEach((s) => {
    request[s] = [];
    Object.keys(formData).forEach((hour) => {
      const item: Partial<ISegmentItem> = { hour, ...formData[hour] };
      request[s].push(item);
    });
  });

  return request;
}

function getFieldSize(field: string) {
  if (editable_fields.includes(field)) return 3;
  else return 2;
}

interface Props {
  hours: string[];
  rows: ISegmentItem[];
  segments: string[];
  selectedSegment: string;
  toggleCollapse: (view: "table" | "form") => void;
}

const SalesTableForm = ({ hours, rows, segments, selectedSegment, toggleCollapse }: Props) => {
  const dispatch = useDispatch();
  const formattedSegments = useSelector(formattedSegmentsSelector);
  const [data, setData] = useState<ISegmentItem[]>([]);
  const [formData, setFormData] = useState<IFormData>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const maxHours = 100;

  useEffect(() => {
    if (hours.length > 0 && rows.length > 0) {
      const filteredData = rows.filter((e) => hours.includes(`${e.hour}:00`));
      const newFormData: IFormData = {};

      filteredData.forEach((item) => {
        const { hour, hsReal, hsProy, ipReal, ipProy } = item;
        newFormData[hour] = { hsReal, hsProy, ipReal, ipProy };
      });

      setData(filteredData);
      setFormData(newFormData);
    } else {
      setData([]);
      setFormData({});
    }
  }, [hours, rows]);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value: number, field: string, item: ISegmentItem) => {
    let newFormData = { ...formData };
    let newData = { ...newFormData[item.hour] };
    newData[field] = value;

    if (field === "hsReal" && value > 0)
      newData["ipReal"] = Math.round((item.gcReal / value) * 100) / 100 || 0;
    if (field === "ipProy" && value > 0)
      newData["ipProy"] = Math.round((item.gcProy / value) * 100) / 100 || 0;

    newFormData[item.hour] = newData;
    setFormData(newFormData);
  };

  const handleSubmit = (copyAll?: boolean) => {
    setAnchorEl(null);

    let newData = [...rows];
    let newSegments = { ...formattedSegments.segments };
    Object.keys(formData).forEach((hour) => {
      const data = formData[hour];
      const index = newData.findIndex((e) => e.hour === hour);
      if (index !== -1) newData[index] = { ...newData[index], ...data };
    });

    if (copyAll) {
      segments.forEach((s) => (newSegments[s] = [...newData]));
      dispatch(updateSalesHoursRequested(formatRequest(formData, segments)));
    } else {
      newSegments[selectedSegment] = [...newData];
      dispatch(updateSalesHoursRequested(formatRequest(formData, [selectedSegment])));
    }

    dispatch(setFormattedSegments({ ...formattedSegments, segments: newSegments }));
    toggleCollapse("form");
  };

  if (data.length === 0)
    return (
      <Box height={310}>
        <NoRowsOverlay />
      </Box>
    );
  else
    return (
      <Box sx={{ p: 1.3, pt: 1.7, position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          {data[0] &&
            Object.keys(data[0]).map((field, index) => {
              if (fields.includes(field))
                return (
                  <Grid key={`${field}-${index}`} item xs={getFieldSize(field)}>
                    <Typography key={field} variant="body2" sx={headerStyle}>
                      {headerMap[field]}
                    </Typography>
                  </Grid>
                );
              else return null;
            })}
        </Grid>
        <Divider sx={{ mt: 1.5, mb: 2 }} />
        <Grid container justifyContent="center" alignItems="center">
          {data?.map((item, index) => (
            <React.Fragment key={`${item.id}-${index}`}>
              {Object.keys(item).map((field) => {
                if (editable_fields.includes(field)) {
                  return (
                    <Grid key={`${index}-${item.id}-${field}`} item my={0.5} xs={3}>
                      <Slider
                        aria-label="Small"
                        max={maxHours}
                        // marks={marks}
                        size="small"
                        valueLabelDisplay="on"
                        value={(getFieldValue(item, field, formData) as number) || 0}
                        sx={horizontalSlider}
                        onChange={(event, value) => handleChange(value as number, field, item)}
                      />
                    </Grid>
                  );
                } else if (fields.includes(field))
                  return (
                    <Grid key={`${index}-${item.id}-${field}`} item my={0.5} xs={2}>
                      <Box mr={5}>
                        <TextField
                          fullWidth
                          value={getFieldValue(item, field, formData) || "0"}
                          variant="standard"
                          size="small"
                          inputProps={{
                            readOnly: true,
                            style: {
                              fontSize: "0.9rem",
                            },
                          }}
                          sx={fieldCellStyle}
                        />
                      </Box>
                    </Grid>
                  );
                else return null;
              })}
            </React.Fragment>
          ))}
        </Grid>
        <IconButton
          size="small"
          onClick={handleMenu}
          color="primary"
          sx={{ position: "absolute", top: 6, right: 10 }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="table-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 12,
                  width: 12,
                  height: 12,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <MenuItem disableRipple onClick={() => handleSubmit()}>
            <ListItemIcon>
              <SaveIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>{"Guardar"}</ListItemText>
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleSubmit(true)}>
            <ListItemIcon>
              <CopyIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>{"Guardar en todos"}</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
};

export default SalesTableForm;
