import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IActivityItem, ICustomChecklistItem } from "../../types";
import {
  Box,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteForever";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const actContainer: SxProps = {
  overflow: "auto",
  maxHeight: "calc(100vh - 460px)",
  py: 2,
  pr: 1,
};

interface FormData extends ICustomChecklistItem {
  [key: string]: number | string | boolean | Partial<IActivityItem>[] | undefined;
}

export interface FetchActivities {
  newActivities: Partial<IActivityItem>[];
  deletedActivities: number[];
}

function isValid(value?: string) {
  if (!value || value.trim() === "") return false;
  return true;
}

function formIsValid(data: FormData) {
  return (
    isValid(data.title) && isValid(data.description) && data.checklist && data.checklist?.length > 0
  );
}

function newInstance(data: ICustomChecklistItem): FormData {
  const checklist = data.checklist.map((e) => ({ ...e }));
  return { ...data, checklist };
}

const initialData: FormData = {
  title: "",
  description: "",
  enabled: false,
  checklist: [],
};

interface Props {
  data: ICustomChecklistItem | null;
  maxActivities: number;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ICustomChecklistItem, fetchActivities?: FetchActivities) => void;
}

export const CustomChecklistFormModal = ({
  data,
  maxActivities,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState<FormData>({ ...initialData, checklist: [] });
  const [activityDescription, setActivityDescription] = React.useState<string>("");
  const [fetchActivities, setFetchActivities] = React.useState<FetchActivities>({
    newActivities: [],
    deletedActivities: [],
  });
  const count = formData.checklist?.length || 0;
  const disableSubmit = !formIsValid(formData);
  const disableNewActivities = count >= maxActivities;
  const isEdit = Boolean(data);

  const clearData = () => {
    setFormData({ ...initialData, checklist: [] });
    setFetchActivities({ newActivities: [], deletedActivities: [] });
    setActivityDescription("");
  };

  React.useEffect(() => {
    if (open && data) {
      const formData = newInstance(data);
      setFormData(formData);
      setFetchActivities({ newActivities: [], deletedActivities: [] });
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [data, open]);

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;
    onClose();
    clearData();
  };

  const handleChange = (value: string, field: string) => {
    let newData = { ...formData };
    newData[field] = value;

    setFormData(newData);
  };

  const handleNewActivity = () => {
    if (isValid(activityDescription)) {
      const newFormData = { ...formData };
      const newActivity = {
        ActivityTypesId: "CUS",
        AreaTypeId: "GRL",
        description: activityDescription,
      };
      newFormData.checklist?.push(newActivity);
      setFormData(newFormData);
      setActivityDescription("");

      if (isEdit)
        setFetchActivities((state) => ({
          ...state,
          newActivities: [...state.newActivities, newActivity],
        }));
    }
  };

  const handleDeleteActivity = (item: Partial<IActivityItem>, index: number) => {
    let newFormData = { ...formData };
    newFormData.checklist = newFormData.checklist.filter((e, i) => i !== index);
    setFormData(newFormData);

    if (isEdit) {
      let newFetchActivities = { ...fetchActivities };
      // delete from data
      item.id && newFetchActivities.deletedActivities.push(item.id);
      // delete from add if exist
      newFetchActivities.newActivities = newFetchActivities.newActivities.filter(
        (e) => e.description !== item.description
      );
      setFetchActivities(newFetchActivities);
    }
  };

  const handleSubmit = () => {
    if (isEdit) {
      onSubmit(formData, fetchActivities);
    } else {
      onSubmit(formData);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      onClose={(event, reason) => handleClose(reason)}
      aria-describedby="description"
      maxWidth={"md"}
      keepMounted={false}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              required
              autoFocus
              fullWidth
              id="title"
              placeholder={t("labels.title").toString()}
              variant="standard"
              value={formData.title || ""}
              onChange={(e) => handleChange(e.target.value, "title")}
              size="small"
              InputProps={{
                readOnly: isEdit,
                style: {
                  fontSize: "1.5rem",
                  fontWeight: 500,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              placeholder={t("labels.description").toString()}
              variant="standard"
              value={formData.description || ""}
              size="small"
              InputProps={{
                readOnly: isEdit,
              }}
              sx={{ mb: 2 }}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                handleNewActivity();
              }}
            >
              <FlexBox sx={{ opacity: 0.8, justifyContent: "space-between" }}>
                <Typography variant="h6">{t("labels.activities")}</Typography>
                <Typography variant="h6" sx={{ mr: 1.5 }}>{`${count}/${maxActivities}`}</Typography>
              </FlexBox>
              {disableNewActivities && (
                <SecondaryText variant="body2">
                  {t("pages.customChecklist.maxActivities")}
                </SecondaryText>
              )}

              <FlexBox my={1} sx={{ opacity: count < maxActivities ? 1 : 0.4 }}>
                <TextField
                  fullWidth
                  id="newActivity"
                  placeholder={count < maxActivities ? t("labels.activityPH").toString() : ""}
                  value={activityDescription}
                  size="small"
                  disabled={disableNewActivities}
                  onChange={(e) => setActivityDescription(e.target.value)}
                />
                <IconButton onClick={handleNewActivity} disabled={disableNewActivities}>
                  <AddIcon sx={{ height: 32, width: 32 }} />
                </IconButton>
              </FlexBox>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={actContainer}>
          {formData.checklist?.map((item, index) => (
            <Grid item key={index} xs={12}>
              <FlexBox sx={{ justifyContent: "space-between" }}>
                <FormControlLabel
                  control={<Checkbox checked={false} sx={{ py: 0 }} />}
                  label={item.description || ""}
                  sx={{ cursor: "default" }}
                />

                <IconButton size="small" onClick={() => handleDeleteActivity(item, index)}>
                  <DeleteIcon />
                </IconButton>
              </FlexBox>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box sx={{ alignSelf: "flex-end", justifySelf: "flex-end", my: 2, px: 1 }}>
          <ActionButton
            variant="outlined"
            onClick={() => handleClose()}
            sx={{ color: (theme) => theme.palette.text.primary, mr: 1 }}
          >
            {t("labels.close")}
          </ActionButton>
          <ActionButton
            type="submit"
            variant="contained"
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            {t("labels.confirm")}
          </ActionButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomChecklistFormModal;
