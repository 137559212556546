import { IHourlyReviewState, IHourlyReviewItem } from "../../../types";
import { doneRemoteResource, initialRemoteResource } from "../../utils";
import { ActionType, RevState, Actions } from "./types";

const initialHRState: IHourlyReviewState = {
  hour: new Date().getHours(),
  confirmed: false,
  completed: false,
};

const initialState: RevState = {
  hourlyReviewTypes: initialRemoteResource(null),
  hourlyReviews: initialRemoteResource([]),
  hourlyReviewState: initialHRState,
  loadingHourlyReview: false,
  hourlyReviewsSyncError: false,
};

const updateHourlyReviews = (
  hourlyReviews: IHourlyReviewItem[],
  item: IHourlyReviewItem,
  failed?: boolean
) => {
  const index = hourlyReviews.findIndex((i) => i.id === item.id);
  if (index !== -1) {
    if (failed) {
      let newHourlyReviews = [...hourlyReviews];
      newHourlyReviews[index] = { ...item, checked: false };
      return newHourlyReviews;
    } else {
      let newHourlyReviews = [...hourlyReviews];
      newHourlyReviews[index] = { ...item };
      return newHourlyReviews;
    }
  } else {
    return hourlyReviews;
  }
};

/** REDUCER */

const activityReducer = (state: RevState = initialState, action: Actions): RevState => {
  switch (action.type) {
    case ActionType.updateHourlyReviewRequested:
      return {
        ...state,
        loadingHourlyReview: true,
        hourlyReviewsSyncError: false,
      };
    case ActionType.updateHourlyReviewDone:
      return {
        ...state,
        hourlyReviews: doneRemoteResource(
          updateHourlyReviews(
            state.hourlyReviews.data || [],
            action.payload.hourlyReviews as IHourlyReviewItem
          )
        ),
        hourlyReviewState: { ...state.hourlyReviewState, completed: true },
        loadingHourlyReview: false,
      };
    case ActionType.updateHourlyReviewError:
      return {
        ...state,
        hourlyReviews: doneRemoteResource(
          updateHourlyReviews(
            state.hourlyReviews.data || [],
            action.payload.hourlyReviews as IHourlyReviewItem,
            true
          )
        ),
        hourlyReviewState: { ...state.hourlyReviewState, completed: false },
        loadingHourlyReview: false,
        hourlyReviewsSyncError: true,
      };
    case ActionType.setHourlyReview:
      return {
        ...state,
        hourlyReviews: doneRemoteResource(action.payload.value),
      };
    case ActionType.setHourlyReviewState:
      return {
        ...state,
        hourlyReviewState: action.payload.value,
      };

    default:
      return state;
  }
};

export default activityReducer;
