import { takeLatest, call, put } from "redux-saga/effects";
import { PromiseResult } from "../../helpers/functions";
import { IHttpRequestError } from "../../types";
import { getPermissionsDone, getPermissionsError } from "./actions";
import { ActionType, GetPermissionsRequestedAction } from "./types";
import StorePlanServices from "../../services/StorePlanServices";
import { setAlert } from "../app/actions";
import { GetPermissionsResponse } from "../../services/interfaces";

function* getPermissionsSagas(action: GetPermissionsRequestedAction) {
  try {
    const {
      result: getPermissionsResponse,
      hasError,
      error,
    }: PromiseResult<GetPermissionsResponse> = yield call(StorePlanServices.getPermissions);
    if (hasError || !getPermissionsResponse) throw error;
    const permissions: string[] = getPermissionsResponse.data;
    yield put(getPermissionsDone({ permissions }));
  } catch (error) {
    yield put(getPermissionsError());
    yield put(setAlert({ alert: { severity: "error", error: error as IHttpRequestError } }));
    console.log("Permissions", error);
  }
}

const userSagas = [takeLatest(ActionType.msalSignInSuccess, getPermissionsSagas)];

export default userSagas;
