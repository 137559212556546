import { ICommentItem, IObjectiveItem } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, ObjectivesState } from "./types";

const initialState: ObjectivesState = {
  objectives: initialRemoteResource([]),
  invokedServices: null,
  sosDtReport: initialRemoteResource(null),
  sosFcReport: initialRemoteResource(null),
  sosMfyReport: initialRemoteResource(null),
  objectiveError: false,
  commentError: false,
  loadingObjectives: false,
  loadingComment: false,
};

const handleInvokedServices = (objectives: IObjectiveItem[]) => {
  const invokedServices: string[] = [];
  objectives.forEach((o) => {
    if (o.invokedService && !invokedServices.includes(o.invokedService))
      invokedServices.push(o.invokedService);
  });
  return invokedServices;
};

const addObjective = (state: ObjectivesState, objective: IObjectiveItem): ObjectivesState => {
  const objectives: IObjectiveItem[] = [...state.objectives.data, objective];

  if (objective.isAutomatic) {
    const invokedServices: string[] = [];
    objectives.forEach((o) => {
      if (o.invokedService && !invokedServices.includes(o.invokedService))
        invokedServices.push(o.invokedService);
    });
    return { ...state, objectives: doneRemoteResource(objectives), invokedServices };
  } else return { ...state, objectives: doneRemoteResource(objectives) };
};

const updateObjectives = (state: ObjectivesState, objective: IObjectiveItem): ObjectivesState => {
  let newObjectives = [...state.objectives.data];
  const index = newObjectives.findIndex((i) => i.id === objective.id);
  if (index !== -1) {
    newObjectives[index] = { ...objective };
    if (objective.isAutomatic) {
      const invokedServices: string[] = [];
      newObjectives.forEach((o) => {
        if (o.invokedService && !invokedServices.includes(o.invokedService))
          invokedServices.push(o.invokedService);
      });
      return { ...state, objectives: doneRemoteResource(newObjectives), invokedServices };
    } else return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else {
    return { ...state };
  }
};

const deleteObjective = (state: ObjectivesState, objective: IObjectiveItem): ObjectivesState => {
  const newObjectives = [...state.objectives.data.filter((i) => i.id !== objective.id)];
  if (objective.isAutomatic) {
    const invokedServices: string[] = [];
    newObjectives.forEach((o) => {
      if (o.invokedService && !invokedServices.includes(o.invokedService))
        invokedServices.push(o.invokedService);
    });
    return { ...state, objectives: doneRemoteResource(newObjectives), invokedServices };
  } else return { ...state, objectives: doneRemoteResource(newObjectives) };
};

const handleAddComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        newCmts.push(comment);
      }
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

const handleUpdateComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        const cmtIndex = newCmts.findIndex((c) => c.id === comment.id);
        if (cmtIndex !== -1) newCmts[cmtIndex] = { ...comment };
      }
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

const handleDeleteComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) newCmts = newCmts.filter((c) => c.id !== comment.id);
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

/** REDUCER */

const activityReducer = (
  state: ObjectivesState = initialState,
  action: Actions
): ObjectivesState => {
  switch (action.type) {
    /** OBJECTIVES */
    case ActionType.getObjectivesRequested:
      return {
        ...state,
        objectives: requestedRemoteResource([]),
        invokedServices: null,
        loadingObjectives: true,
      };
    case ActionType.getObjectivesDone:
      return {
        ...state,
        objectives: doneRemoteResource(action.payload.objective),
        invokedServices: handleInvokedServices(action.payload.objective),
        loadingObjectives: false,
      };
    case ActionType.getObjectivesError:
      return {
        ...state,
        objectives: errorRemoteResource([]),
        invokedServices: null,
        loadingObjectives: false,
      };
    case ActionType.addObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.addObjectiveDone:
      return {
        ...addObjective(state, action.payload.objective as IObjectiveItem),
        loadingObjectives: false,
      };
    case ActionType.addObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    case ActionType.updateObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.updateObjectiveDone:
      return {
        ...updateObjectives(state, action.payload.objective as IObjectiveItem),
        loadingObjectives: false,
      };
    case ActionType.updateObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    case ActionType.deleteObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.deleteObjectiveDone:
      return {
        ...deleteObjective(state, action.payload.objective as IObjectiveItem),
        loadingObjectives: false,
      };
    case ActionType.deleteObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    /** COMMENTS */
    case ActionType.addCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.addCommentDone:
      return {
        ...handleAddComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.addCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.updateCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.updateCommentDone:
      return {
        ...handleUpdateComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.updateCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.deleteCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.deleteCommentDone:
      return {
        ...handleDeleteComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.deleteCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    /** SOS REPORT */
    case ActionType.setInvokedServices:
      return {
        ...state,
        invokedServices: action.payload.value,
      };
    case ActionType.getSosDtReportDone:
      return {
        ...state,
        sosDtReport: doneRemoteResource(action.payload.report),
      };
    case ActionType.getSosFcReportDone:
      return {
        ...state,
        sosFcReport: doneRemoteResource(action.payload.report),
      };
    case ActionType.getSosMfyReportDone:
      return {
        ...state,
        sosMfyReport: doneRemoteResource(action.payload.report),
      };
    case ActionType.clearObjectives:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default activityReducer;
