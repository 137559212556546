import * as React from "react";
import TimeIcon from "@mui/icons-material/AccessAlarm";
import CheckIcon from "@mui/icons-material/CheckCircle";
import FailIcon from "@mui/icons-material/Cancel";
import NoScheduleIcon from "@mui/icons-material/EventBusy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hourlyReviewConfirmedSelector } from "../redux/activities/rev/selectors";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FormattedHourlyReview,
  TimeRange,
  getTimeRangeByDate,
} from "../pages/activities/HourlyReview";

function getActiveStep(steps: FormattedHourlyReview[], currentHour: number) {
  return steps.findIndex((e) => e.item.reviewHour === currentHour);
}

function getStepState(step: FormattedHourlyReview) {
  const timeRange: TimeRange = getTimeRangeByDate(step);
  switch (timeRange) {
    case "beforeRange":
      return "completed";
    case "afterRange":
      return "pending";
    default:
      return null;
  }
}

interface Props {
  steps: FormattedHourlyReview[];
}

export const TourHistory = ({ steps }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const hourlyReviewConfirmed = useSelector(hourlyReviewConfirmedSelector);
  const [activeStep, setActiveStep] = useState(getActiveStep(steps, hourlyReviewConfirmed.hour));

  useEffect(() => {
    setActiveStep(getActiveStep(steps, hourlyReviewConfirmed.hour));
    // eslint-disable-next-line
  }, [hourlyReviewConfirmed]);

  function getChipState(step: FormattedHourlyReview) {
    const timeRange: TimeRange = getTimeRangeByDate(step);

    if (timeRange === "inRange" && !step.item.checked) return null;

    if (timeRange === "afterRange")
      return (
        <Chip
          icon={<TimeIcon />}
          color="default"
          label={isMobile ? "" : t("labels.pending")}
          size="small"
        />
      );

    if ((timeRange === "beforeRange" || timeRange === "inRange") && step.item.checked)
      return (
        <Chip
          icon={<CheckIcon />}
          color="success"
          label={isMobile ? "" : t("labels.completed")}
          size="small"
        />
      );

    if (timeRange === "beforeRange" && !step.item.checked)
      return (
        <Chip
          icon={<FailIcon />}
          color="error"
          label={isMobile ? "" : t("labels.failed")}
          size="small"
        />
      );

    return (
      <Chip
        icon={<TimeIcon />}
        color="default"
        label={isMobile ? "" : t("labels.pending")}
        size="small"
      />
    );
  }

  if (!steps || steps?.length === 0)
    return (
      <List dense>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "error.main" }}>{<NoScheduleIcon />}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("messages.noSchedules")} />
        </ListItem>
      </List>
    );

  return (
    <Box>
      <List dense>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}
            <ListItem
              secondaryAction={getChipState(step)}
              sx={getStepState(step) === "pending" ? { opacity: 0.4 } : null}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: activeStep === index ? "secondary.main" : "primary.main",
                  }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t("labels.tour")}
                secondary={`${step.item.reviewHour}:00 - ${step.item.reviewHour + 1}:00 hs.`}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default TourHistory;
