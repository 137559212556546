import React from "react";
import { Fab, styled } from "@mui/material";
import { setSelectedNote, toggleNoteModal } from "../redux/activities/notes/actions";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { isAppOnlineSelector } from "../redux/app/selectors";

const StyledFab = styled(Fab)({
  position: "absolute",
  bottom: "5%",
  right: "5%",
  width: 100,
});

const NoteFab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);

  const handleNote = () => {
    dispatch(setSelectedNote({ note: null }));
    dispatch(toggleNoteModal());
  };

  if (!isAppOnline) return null;

  return (
    <StyledFab color="primary" variant="extended" size="small" onClick={handleNote}>
      <AddIcon sx={{ width: 20, height: 20, mr: 0.5 }} />
      <span style={{ marginTop: 1 }}>{t("labels.note")}</span>
    </StyledFab>
  );
};

export default NoteFab;
