import { Action } from "@reduxjs/toolkit";
import { IAreaType } from "../../../types";
import { ActionPayload } from "../../actions";
import { RemoteResource } from "../../utils";

export interface AreasState {
  areas: RemoteResource<IAreaType[]>;
  loading: boolean;
}

export enum ActionType {
  getAreasRequested = "admin/get-areas-requested",
  getAreasDone = "admin/get-areas-done",
  getAreasError = "admin/get-areas-error",
  setAreasRequested = "admin/set-areas-requested",
  setAreasDone = "admin/set-areas-done",
  setAreasError = "admin/set-areas-error",
  clearData = "admin/clear-data",
}

/** GET AREAS */

export interface GetAreasPayload {
  areas: IAreaType[];
}

export interface GetAreasRequestedAction extends Action<ActionType.getAreasRequested> {}

export interface GetAreasDoneAction
  extends ActionPayload<ActionType.getAreasDone, GetAreasPayload> {}

export interface GetAreasErrorAction extends Action<ActionType.getAreasError> {}

/** SET AREAS */

export interface SetAreasPayload {
  areas: IAreaType[];
}
export interface SetAreasRequestedAction
  extends ActionPayload<ActionType.setAreasRequested, SetAreasPayload> {}

export interface SetAreasDoneAction
  extends ActionPayload<ActionType.setAreasDone, SetAreasPayload> {}

export interface SetAreasErrorAction extends Action<ActionType.setAreasError> {}

/** CLEAR DATA */

export interface GetClearDataAction extends Action<ActionType.clearData> {}

/** ACTION TYPES */

export type Actions =
  | GetAreasRequestedAction
  | GetAreasDoneAction
  | GetAreasErrorAction
  | SetAreasRequestedAction
  | SetAreasDoneAction
  | SetAreasErrorAction
  | GetClearDataAction;
