import { IActivityItem, ICustomChecklistItem } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, ActivitiesState } from "./types";

const initialState: ActivitiesState = {
  checklists: initialRemoteResource([]),
  loading: false,
};

const addChecklist = (checklists: ICustomChecklistItem[], checklist: ICustomChecklistItem) => {
  const newChecklists = [...checklists, checklist];
  return newChecklists;
};

const updateChecklists = (checklists: ICustomChecklistItem[], newItem: ICustomChecklistItem) => {
  const index = checklists.findIndex((i) => i.id === newItem.id);
  if (index !== -1) {
    let newChecklists = checklists.map((e) => ({ ...e }));
    if (newItem.enabled) {
      newChecklists.forEach((item) => {
        if (item.id === newItem.id) {
          item.enabled = newItem.enabled;
          item.checklist = [...newItem.checklist];
        } else {
          item.enabled = !newItem.enabled;
        }
      });
    } else {
      newChecklists[index] = { ...newItem };
    }
    return newChecklists;
  } else {
    return checklists;
  }
};

const deleteChecklist = (checklists: ICustomChecklistItem[], identifier: number) => {
  const newChecklists = [...checklists.filter((i) => i.id !== identifier)];
  return newChecklists;
};

const updateActivities = (checklists: ICustomChecklistItem[], activities: IActivityItem[]) => {
  const checklistId = activities[0].customChecklistId;
  const index = checklists.findIndex((i) => i.id === checklistId);
  if (index !== -1) {
    let newChecklists = checklists.map((e) => ({ ...e }));
    const newActivities = [...newChecklists[index].checklist, ...activities];
    newChecklists[index].checklist = [...newActivities];
    return newChecklists;
  } else {
    return checklists;
  }
};

const deleteActivities = (
  checklists: ICustomChecklistItem[],
  ids: number[],
  customChecklistId: number
) => {
  const index = checklists.findIndex((i) => i.id === customChecklistId);
  if (index !== -1) {
    let newChecklists = checklists.map((e) => ({ ...e }));
    const newActivities = newChecklists[index].checklist.filter((a) => a.id && !ids.includes(a.id));
    newChecklists[index].checklist = [...newActivities];
    return newChecklists;
  } else {
    return checklists;
  }
};

const activitiesReducer = (
  state: ActivitiesState = initialState,
  action: Actions
): ActivitiesState => {
  switch (action.type) {
    case ActionType.getChecklistsRequested:
      return {
        ...state,
        checklists: requestedRemoteResource([]),
        loading: true,
      };
    case ActionType.getChecklistsDone:
      return {
        ...state,
        checklists: doneRemoteResource(action.payload.checklists),
        loading: false,
      };
    case ActionType.getChecklistsError:
      return {
        ...state,
        checklists: errorRemoteResource([]),
        loading: false,
      };
    case ActionType.addChecklistRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.addChecklistDone:
      return {
        ...state,
        checklists: doneRemoteResource(
          addChecklist(
            state.checklists.data || [],
            action.payload.checklist as ICustomChecklistItem
          )
        ),
        loading: false,
      };
    case ActionType.addChecklistError:
      return {
        ...state,
        loading: false,
      };
    case ActionType.updateChecklistRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.updateChecklistDone:
      return {
        ...state,
        checklists: doneRemoteResource(
          updateChecklists(
            state.checklists.data || [],
            action.payload.checklist as ICustomChecklistItem
          )
        ),
        loading: false,
      };
    case ActionType.updateChecklistError:
      return {
        ...state,
        loading: false,
      };
    case ActionType.deleteChecklistRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.deleteChecklistDone:
      return {
        ...state,
        checklists: doneRemoteResource(
          deleteChecklist(state.checklists.data || [], action.payload.id)
        ),
        loading: false,
      };
    case ActionType.deleteChecklistError:
      return {
        ...state,
        loading: false,
      };

    case ActionType.addActivitiesRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.addActivitiesDone:
      return {
        ...state,
        checklists: doneRemoteResource(
          updateActivities(
            state.checklists.data || [],
            action.payload.activities as IActivityItem[]
          )
        ),
        loading: false,
      };
    case ActionType.addActivitiesError:
      return {
        ...state,
        loading: false,
      };

    case ActionType.deleteActivitiesRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.deleteActivitiesDone:
      return {
        ...state,
        checklists: doneRemoteResource(
          deleteActivities(
            state.checklists.data || [],
            action.payload.ids,
            action.payload.customChecklistId
          )
        ),
        loading: false,
      };
    case ActionType.deleteActivitiesError:
      return {
        ...state,
        loading: false,
      };

    case ActionType.clearData:
      return {
        ...state,
        checklists: initialRemoteResource([]),
        loading: false,
      };
    default:
      return state;
  }
};

export default activitiesReducer;
