import React from "react";
import { CircularProgress, SxProps } from "@mui/material";
import { useSelector } from "react-redux";
import { isSidebarOpenSelector } from "../redux/app/selectors";

const closedProgress: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  opacity: 0.8,
};

const openedProgress: SxProps = {
  position: "absolute",
  top: "50%",
  left: "55%",
  opacity: 0.8,
};

interface Props {
  loading: boolean;
  size?: number;
}

const ScreenLoader = ({ loading, size }: Props) => {
  const sidebarOpen = useSelector(isSidebarOpenSelector);
  return loading ? (
    <CircularProgress size={size || 40} sx={sidebarOpen ? openedProgress : closedProgress} />
  ) : null;
};

export default ScreenLoader;
