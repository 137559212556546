import { Action } from "@reduxjs/toolkit";
import { IDailyTotals, IFormattedSegments, ISalesHoursRequest, ISegmentItem, ISegments } from "../../types";
import { ActionPayload } from "../actions";
import { RemoteResource } from "../utils";

export interface SalesState {
  segments: RemoteResource<ISegments | null>;
  totals: RemoteResource<ISegmentItem[]>;
  dailyTotals: RemoteResource<ISegmentItem | null>;
  formattedSegments: IFormattedSegments;
  isLoading: boolean;
  isUpdating: boolean;
}

export enum ActionType {
  getDailyTotalsRequested = "sales/get-daily-totals-requested",
  getDailyTotalsDone = "sales/get-daily-totals-done",
  getDailyTotalsError = "sales/get-daily-totals-error",
  updateSalesHoursRequested = "sales/update-sales-hours-requested",
  updateSalesHoursDone = "sales/update-sales-hours-done",
  updateSalesHoursError = "sales/update-sales-hours-error",
  setFormattedSegments = "sales/set-formatted-segments",
}

/** DAILY TOTALS */
export interface DailyTotalsPayload {
  dailyTotals: IDailyTotals;
}

export interface GetDailyTotalsRequestedAction extends Action<ActionType.getDailyTotalsRequested> {}

export interface GetDailyTotalsDoneAction
  extends ActionPayload<ActionType.getDailyTotalsDone, DailyTotalsPayload> {}

export interface GetDailyTotalsErrorAction extends Action<ActionType.getDailyTotalsError> {}

/** SALES HOURS */

export interface SalesHoursPayload {
  data: ISalesHoursRequest;
}

export interface UpdateSalesHoursRequestedAction
  extends ActionPayload<ActionType.updateSalesHoursRequested, SalesHoursPayload> {}

export interface UpdateSalesHoursDoneAction
  extends ActionPayload<ActionType.updateSalesHoursDone, SalesHoursPayload> {}

export interface UpdateSalesHoursErrorAction extends Action<ActionType.updateSalesHoursError> {}

export interface SetFormattedSegmentsAction
  extends ActionPayload<ActionType.setFormattedSegments, IFormattedSegments> {}

export type Actions =
  | GetDailyTotalsRequestedAction
  | GetDailyTotalsDoneAction
  | GetDailyTotalsErrorAction
  | UpdateSalesHoursRequestedAction
  | UpdateSalesHoursDoneAction
  | UpdateSalesHoursErrorAction
  | SetFormattedSegmentsAction;
