import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IManager } from "../../types";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  SxProps,
  TextField,
} from "@mui/material";
import { ActionButton, SecondaryText } from "../../styles";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import IdentifierIcon from "@mui/icons-material/Badge";
import UserIcon from "@mui/icons-material/AccountCircle";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const initialState = {
  name: "",
  lastname: "",
  identifier: "",
  active: true,
};

interface Props {
  open: boolean;
  isAppOnline: boolean;
  onSubmit: (data?: IManager, action?: "edit" | "delete") => void;
  managers: IManager[];
  data?: IManager;
}

export const ManagerFormModal = ({ open, isAppOnline, onSubmit, data, managers }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState({ ...initialState });
  const [isDelete, setIsDelete] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);
  const [error, setError] = React.useState(false);
  const isEdit = Boolean(data);
  const disableSubmit = error || (!isDelete && isEdit && !isModified) || !isAppOnline;

  React.useEffect(() => {
    if (data) setFormData({ ...data, identifier: data.identifier.toString() });
  }, [data]);

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;
    if (isDelete) {
      setIsDelete(false);
    } else {
      onSubmit();
      setFormData({ ...initialState });
      setIsModified(false);
    }
    setError(false);
  };

  const handleSubmit = () => {
    if (isDelete && data) {
      onSubmit(data, "delete");
    } else {
      const action = isEdit ? "edit" : undefined;
      const newData: IManager = {
        ...formData,
        identifier: parseInt(formData.identifier),
      };
      onSubmit(newData, action);
    }
    setFormData({ ...initialState });
    setIsDelete(false);
    setError(false);
    setIsModified(false);
  };

  const handleIndentifier = (value: string) => {
    const regexp = /[^0-9]/g;
    if (!regexp.test(value)) {
      const isValid = Boolean(managers.find((m) => m.identifier.toString() === value));
      setFormData((state) => ({ ...state, identifier: value }));
      if ((!isValid && parseInt(value) > 0) || value === "") setError(false);
      else setError(true);
    }
  };

  const renderDeleteMsg = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1}>{t("pages.managers.deleteManager")}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.managers.deleteManagerDescription")}
          </DialogContentText>
        </DialogContent>
      </>
    );
  };

  const renderFormContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1} sx={isEdit ? { ml: 3 } : null}>
            {isEdit ? t("pages.managers.editManager") : t("pages.managers.newManager")}
          </Box>
          {isEdit && (
            <IconButton onClick={() => setIsDelete(true)}>
              <DeleteIcon color={"primary"} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {isEdit
              ? t("pages.managers.editManagerDescription")
              : t("pages.managers.newManagerDescription")}
          </DialogContentText>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                id="name"
                label={t("labels.name")}
                value={formData.name}
                onChange={(e) => setFormData((state) => ({ ...state, name: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                id="lastname"
                label={t("labels.lastname")}
                value={formData.lastname}
                onChange={(e) =>
                  setFormData((state) => ({
                    ...state,
                    lastname: e.target.value,
                  }))
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                error={error}
                fullWidth
                id="identifier"
                label={t("labels.identifier")}
                helperText={error ? t("labels.identifierError") : t("labels.identifierHelper")}
                value={formData.identifier}
                onChange={(e) => handleIndentifier(e.target.value)}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IdentifierIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                sx={{ ml: 1 }}
                control={
                  <Switch
                    color="primary"
                    onChange={(e, checked) => {
                      setFormData((state) => ({ ...state, active: checked }));
                      setIsModified(true);
                    }}
                    checked={formData.active}
                  />
                }
                label={<SecondaryText>{t("labels.activeManager")}</SecondaryText>}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  return (
    <Box mt={2}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={(event, reason) => handleClose(reason)}
        aria-describedby="description"
        maxWidth={"sm"}
      >
        <Box
          p={1}
          component="form"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {isDelete ? renderDeleteMsg() : renderFormContent()}
          <DialogActions sx={{ mx: 2 }}>
            <ActionButton
              variant="outlined"
              onClick={() => handleClose()}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {t("labels.cancel")}
            </ActionButton>
            <ActionButton type="submit" variant="contained" disabled={disableSubmit}>
              {t("labels.confirm")}
            </ActionButton>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ManagerFormModal;
