import { useMsal } from "@azure/msal-react";
import { Avatar, Box, Button, Container, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ReactComponent as MSLogo } from "../../img/ms-logo.svg";
import { Navigate } from "react-router-dom";
import { routes } from "../../router/routes";
import Copyright from "../../layout/Copyright";
import { useSelector } from "react-redux";
import { permissionsSelector } from "../../redux/user/selectors";
import { RemoteResourceState } from "../../redux/utils";
import { AppPermissions } from "../../types";

const ContainerMain = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const LoadingWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
}));

const ProgressAvatar = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  background: theme.palette.mode === "light" ? "#FFF" : "#2F2F2F",
  color: theme.palette.mode === "light" ? "#5E5E5E" : "#FFF",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "15px",
  fontWeight: 500,
}));

interface Props {
  isAuthenticated: boolean;
}

const Login = ({ isAuthenticated }: Props) => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();
  const permissions = useSelector(permissionsSelector);
  const loading =
    inProgress !== "none" || permissions.state === RemoteResourceState.REMOTE_RESOURCE_LOADING;

  const handleLogin = () => instance.loginRedirect();

  if (isAuthenticated) {
    if (!permissions.data.includes(AppPermissions.APP))
      return <Navigate to={routes.noAccess.path} />;
    return <Navigate to={routes.main.home.path} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <ContainerMain>
        <LoadingWrapper>
          {loading && <ProgressAvatar size={48} />}
          <AvatarStyled>
            <LockOutlinedIcon />
          </AvatarStyled>
        </LoadingWrapper>
        <Typography component="h1" variant="h5">
          Mi Plan de Turno
        </Typography>
        <ButtonStyled
          onClick={handleLogin}
          startIcon={<MSLogo />}
          variant="outlined"
          disabled={loading}
        >
          {t("pages.login.signInWithMS")}
        </ButtonStyled>
      </ContainerMain>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
