import Container from "@mui/material/Container";
import MRTable from "./MRTable";

const EmployeePositioning = () => {
  return (
    <Container maxWidth={false}>
      <MRTable />
    </Container>
  );
};

export default EmployeePositioning;
