import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { IObjectiveItem } from "../../types";
import ManualObjectiveCard from "../../components/cards/ManualObjectiveCard";
import AllIcon from "@mui/icons-material/LibraryBooks";
import AddIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import ObjectiveFormModal from "../../components/modals/ObjectiveFormModal";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  invokedServicesSelector,
  isLoadingObjectivesSelector,
  objectivesDataSelector,
  sosDtReportSelector,
  sosFcReportSelector,
  sosMfyReportSelector,
} from "../../redux/activities/objectives/selectors";
import {
  addObjectiveRequested,
  getSosDtReportRequested,
  getSosFcReportRequested,
  getSosMfyReportRequested,
  updateObjectiveRequested,
} from "../../redux/activities/objectives/actions";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { objectivesTypesDataSelector } from "../../redux/storePlan/selectors";
import ObjectiveModal from "../../components/modals/ObjectiveModal";
import { compareObjectives, getLocale, isBetweenShift } from "../../helpers/functions";
import ProgressCard, { IProgress } from "../../components/cards/ProgressCard";
import { formatDistanceToNow } from "date-fns";
import AppStorage from "../../services/AppStorage";
import AutomaticObjectiveCard from "../../components/cards/AutomaticObjectiveCard";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";

export function calcObjectivesProgress(
  objectives: IObjectiveItem[],
  dt?: number | null,
  fc?: number | null,
  mfy?: number | null
): IProgress {
  let progress: IProgress = {
    total: objectives?.length || 0,
    pending: 0,
    finished: 0,
  };

  objectives.forEach((o) => {
    const objValue = parseInt(o.objectiveDescription);
    switch (o.invokedService) {
      case "SOS_DT":
        if (!isNaN(objValue) && dt && objValue > dt) progress.finished++;
        else progress.pending++;
        break;
      case "SOS_FC":
        if (!isNaN(objValue) && fc && objValue > fc) progress.finished++;
        else progress.pending++;
        break;
      case "SOS_MFY":
        if (!isNaN(objValue) && mfy && objValue > mfy) progress.finished++;
        else progress.pending++;
        break;
      default:
        if (o.objectiveAchieved) progress.finished++;
        else progress.pending++;
        break;
    }
  });

  return progress;
}

function getLastRequest() {
  const timestamp = AppStorage.getSosRequestDate();
  if (timestamp) return formatDistanceToNow(new Date(timestamp), { locale: getLocale() });
  else return "-";
}

/**
 * Los objetivos son diarios y por turno, no se obtienen objetivos de otros días/turnos.
 * Utiliza los datos maestros de objetivos para completar el formulario.
 * Operaciones: alta y modificación de objetivos.
 * @activityType OBJ
 */

const Objectives = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:480px)");
  const isAppOnline = useSelector(isAppOnlineSelector);
  const filters = useSelector(filtersSelector);
  const objectiveTypes = useSelector(objectivesTypesDataSelector);
  const objectivesData = useSelector(objectivesDataSelector);
  const invokedServices = useSelector(invokedServicesSelector);
  const sosDtReport = useSelector(sosDtReportSelector);
  const sosFcReport = useSelector(sosFcReportSelector);
  const sosMfyReport = useSelector(sosMfyReportSelector);
  const loading = useSelector(isLoadingObjectivesSelector);

  const [lastRequest, setLastRequest] = React.useState<string>(getLastRequest());
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selected, setSelected] = React.useState<number>();
  const [selectedObj, setSelectedObj] = React.useState<IObjectiveItem | null>(null);
  const [autoObjectives, setAutoObjectives] = React.useState<IObjectiveItem[]>([]);
  const [manualObjectives, setManualObjectives] = React.useState<IObjectiveItem[]>([]);
  const [autoObjProgress, setAutoObjProgress] = React.useState<IProgress>(
    calcObjectivesProgress([])
  );
  const [manualObjProgress, setManualObjProgress] = React.useState<IProgress>(
    calcObjectivesProgress([])
  );

  React.useEffect(() => {
    const newState: IObjectiveItem[] = objectivesData.map((o) => ({ ...o }));
    const newObj = newState.find((o) => o.id === selectedObj?.id) || null;
    const newAutoObjectives = newState.filter((o) => o.isAutomatic).sort(compareObjectives);
    const newManalObjectives = newState.filter((o) => !o.isAutomatic).sort(compareObjectives);

    setManualObjProgress(calcObjectivesProgress(newManalObjectives));
    setAutoObjectives(newAutoObjectives);
    setManualObjectives(newManalObjectives);
    setSelectedObj(newObj);
    // eslint-disable-next-line
  }, [objectivesData]);

  React.useEffect(() => {
    if (invokedServices && isBetweenShift(new Date(), filters.businessDate, filters.shift)) {
      if (!Boolean(sosDtReport) && invokedServices.includes("SOS_DT"))
        dispatch(getSosDtReportRequested());
      if (!Boolean(sosFcReport) && invokedServices.includes("SOS_FC"))
        dispatch(getSosFcReportRequested());
      if (!Boolean(sosMfyReport) && invokedServices.includes("SOS_MFY"))
        dispatch(getSosMfyReportRequested());
    }
    // eslint-disable-next-line
  }, [invokedServices, sosDtReport, sosFcReport, sosMfyReport]);

  React.useEffect(() => {
    const autoObjectives = objectivesData.filter((o) => o.isAutomatic);
    setAutoObjProgress(
      calcObjectivesProgress(autoObjectives, sosDtReport?.oepe, sosFcReport?.r2p, sosMfyReport?.tts)
    );
    // eslint-disable-next-line
  }, [objectivesData, sosDtReport, sosFcReport, sosMfyReport]);

  React.useEffect(() => {
    const requestTimer = setInterval(() => {
      setLastRequest(getLastRequest());
    }, 30000);

    return () => clearInterval(requestTimer);
  }, []);

  const handleOpen = () => {
    setSelected(undefined);
    setOpen(true);
  };

  const handleSubmit = (newObj?: Partial<IObjectiveItem>, isDeleting?: boolean) => {
    if (isDeleting) {
      // todo delete
    } else if (newObj) {
      dispatch(addObjectiveRequested(newObj));
    }
    setOpen(false);
    setSelected(undefined);
  };

  const handleAchieved = (newData: Partial<IObjectiveItem>) => {
    setSelected(newData.id);
    dispatch(updateObjectiveRequested(newData));
  };

  const handleClick = (obj: IObjectiveItem | null) => {
    setOpenModal(Boolean(obj));
    setSelectedObj(obj);
  };

  return (
    <Container maxWidth={false}>
      <FlexBox sx={{ justifyContent: "space-between", mb: 2 }}>
        <ButtonGroup>
          <Button variant="contained" startIcon={<AllIcon />}>
            {t("pages.objectives.title")}
          </Button>
          {isMobile && (
            <Button
              variant="contained"
              startIcon={<AddIcon sx={{ my: 0, ml: 0.75, mr: -0.75 }} />}
              onClick={handleOpen}
              disabled={loading || !isAppOnline}
            />
          )}
        </ButtonGroup>

        {!isMobile && (
          <ActionButton
            variant="contained"
            size="medium"
            sx={{ ml: 2 }}
            onClick={handleOpen}
            loading={loading}
            disabled={loading || !isAppOnline}
          >
            {`+ ${t("labels.objective").toUpperCase()}`}
          </ActionButton>
        )}
      </FlexBox>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box component={Paper} p={2} pb={4}>
            <ProgressCard
              hideTotal
              title={t("labels.hourObjectives")}
              progress={autoObjProgress}
              finishedLabel={t("labels.reacheds").toString()}
            />
            <Grid
              container
              spacing={2}
              sx={{
                overflow: "auto",
                maxHeight: "calc(100vh - 320px)",
                mt: 0.5,
                pr: 2,
                pb: 3,
              }}
            >
              {autoObjectives.length === 0 && (
                <Box width={"100%"} mt={5.8} px={2}>
                  <Skeleton animation="wave" height={30} width="20%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <SecondaryText variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                    {t("labels.noObjectives")}
                  </SecondaryText>
                </Box>
              )}
              {autoObjectives.map((i, index) => (
                <Grid item xs={12} key={i.id}>
                  <Divider sx={{ mb: 2 }} flexItem />
                  <AutomaticObjectiveCard
                    data={i}
                    onClick={handleClick}
                    loading={loading && i.id === selected}
                    selected={selected}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <FlexBox sx={{ justifyContent: "flex-end", mt: 1 }}>
            {isBetweenShift(new Date(), filters.businessDate, filters.shift) ? (
              <Typography variant="caption">
                {`${t("labels.lastUpdatedAgo")} `}
                {lastRequest}
              </Typography>
            ) : (
              <Typography variant="caption">{t("labels.outOfShiftTimeRange")}</Typography>
            )}
          </FlexBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box component={Paper} p={2} pb={4}>
            <ProgressCard
              hideTotal
              title={t("labels.shiftObjectives")}
              progress={manualObjProgress}
              finishedLabel={t("labels.reacheds").toString()}
            />
            <Grid
              container
              spacing={2}
              sx={{
                overflow: "auto",
                maxHeight: "calc(100vh - 282px)",
                mt: 0.5,
                pr: 2,
                pb: 3,
              }}
            >
              {manualObjectives.length === 0 && (
                <Box width={"100%"} mt={5.8} px={2}>
                  <Skeleton animation="wave" height={30} width="20%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <SecondaryText variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                    {t("labels.noObjectives")}
                  </SecondaryText>
                </Box>
              )}
              {manualObjectives.map((i, index) => (
                <Grid item xs={12} key={i.id}>
                  <Divider sx={{ mb: 2 }} flexItem />
                  <ManualObjectiveCard
                    data={i}
                    onClick={handleClick}
                    onChangeAchieved={handleAchieved}
                    loading={loading && i.id === selected}
                    selected={selected}
                    isAppOnline={isAppOnline}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ObjectiveFormModal
        objectivesList={objectivesData}
        objectiveTypes={objectiveTypes}
        open={open}
        onSubmit={handleSubmit}
      />
      <ObjectiveModal open={openModal} objectiveData={selectedObj} setOpen={setOpenModal} />
    </Container>
  );
};

export default Objectives;
