import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { es, enUS, pt, fr } from "date-fns/locale";
import translations, { SupportedLanguages } from "./i18n/translations";

export const supportedLngs = Object.keys(translations);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: supportedLngs[0],
    nsSeparator: ".",
    debug: false,
    supportedLngs,
    cleanCode: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
  });

export const dateLocal: Record<SupportedLanguages, Locale> = {
  es,
  en: enUS,
  pt,
  fr,
};

export default i18n;
