import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import OnlineIcon from "@mui/icons-material/Wifi";
import OfflineIcon from "@mui/icons-material/WifiOff";
import SyncErrorIcon from "@mui/icons-material/SyncProblem";
import ChangelogIcon from "@mui/icons-material/Help";
import DevIcon from "@mui/icons-material/LogoDev";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Favicon from "../img/favicon.png";
import ProfileMenu from "./ProfileMenu";
import NotesMenu from "../pages/notes/NotesMenu";
import AppStorage from "../services/AppStorage";
import { Box, Chip, CircularProgress, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNavigateController, setSyncFailed, toggleSidebar } from "../redux/app/actions";
import { useSelector } from "react-redux";
import { ActivityType, AppPermissions, IManager, INoteItem, IStorePlan } from "../types";
import { navigateSelector, syncFailedSelector, syncRequiredSelector } from "../redux/app/selectors";
import { SYNCHRONIZABLE_ACTIVITIES } from "../helpers/constants";
import {
  setAreasChecklistRequested,
  setMyActivitiesRequested,
  setPosShiftRequested,
  setPreShiftRequested,
} from "../redux/activities/checklists/actions";
import { updateHourlyReviewRequested } from "../redux/activities/rev/actions";
import { isLoadingActivitySelector } from "../redux/activities/checklists/selectors";
import { loadingHourlyReviewSelector } from "../redux/activities/rev/selectors";
import { routes } from "../router/routes";
import { RemoteResource } from "../redux/utils";
import {
  AppBarStyled,
  IconButtonRight,
  iconStyle,
  offlineFx,
  onlineFx,
  StyledBox,
  syncErrorFx,
} from "./styles";
import { permissionsDataSelector } from "../redux/user/selectors";

const BackButtons = () => {
  const isModeModal = false;
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);

  return isModeModal ? (
    <IconButtonRight color="inherit" aria-label="back-button" edge="start" onClick={handleClick}>
      <ArrowBackIcon />
    </IconButtonRight>
  ) : null;
};

interface MenuButtonProps {
  user?: IManager;
}

const MenuButton = ({ user }: MenuButtonProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const handleClick = () => dispatch(toggleSidebar());

  if (!user || isMobile) return null;

  return (
    <IconButtonRight color="inherit" aria-label="back-button" edge="start" onClick={handleClick}>
      <MenuIcon />
    </IconButtonRight>
  );
};

const Logo = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return (
    <Box
      component="img"
      sx={isMobile ? { height: 35 } : { height: 50 }}
      alt={t("shared.title")}
      src={Favicon}
    />
  );
};

interface Props {
  isAppOnline: boolean;
  storePlan: RemoteResource<IStorePlan | null>;
  notesState: RemoteResource<INoteItem[]>;
  activitiesLoaded: boolean;
  user?: IManager;
  showSidebar?: boolean;
}

const Header = ({
  isAppOnline,
  user,
  activitiesLoaded,
  notesState,
  storePlan,
  showSidebar,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigateController = useSelector(navigateSelector);
  const permissions = useSelector(permissionsDataSelector);
  const loadingChecklists = useSelector(isLoadingActivitySelector);
  const loadingHR = useSelector(loadingHourlyReviewSelector);
  const syncRequired = useSelector(syncRequiredSelector);
  const syncFailed = useSelector(syncFailedSelector);
  const [synchronizing, setSynchronizing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const loadingActivities = loadingChecklists || loadingHR;

  const handleSync = () => {
    setSyncError(false);
    setSynchronizing(true);
    dispatch(setSyncFailed(false));
    SYNCHRONIZABLE_ACTIVITIES.forEach((activity) => {
      switch (activity) {
        case ActivityType.OPENING:
          const openingData = AppStorage.getOpening();
          if (openingData) {
            let areaFlag: string[] = Object.keys(openingData).map((key) => key);
            dispatch(setAreasChecklistRequested(openingData, ActivityType.OPENING, areaFlag));
          }
          break;
        case ActivityType.CLOSING:
          const closingData = AppStorage.getClosing();
          if (closingData) {
            let areaFlag: string[] = Object.keys(closingData).map((key) => key);
            dispatch(setAreasChecklistRequested(closingData, ActivityType.CLOSING, areaFlag));
          }
          break;
        case ActivityType.CHECKLIST:
          const checklistData = AppStorage.getChecklist();
          if (checklistData) {
            let areaFlag: string[] = Object.keys(checklistData).map((key) => key);
            dispatch(setAreasChecklistRequested(checklistData, ActivityType.CHECKLIST, areaFlag));
          }
          break;
        case ActivityType.PRE_SHIFT:
          const preData = AppStorage.getPreShift();
          preData && dispatch(setPreShiftRequested(preData));
          break;
        case ActivityType.POS_SHIFT:
          const posData = AppStorage.getPosShift();
          posData && dispatch(setPosShiftRequested(posData));
          break;
        case ActivityType.SHIFT_TOUR:
          const revData = AppStorage.getHourlyReviews();
          revData?.forEach((item) => {
            dispatch(updateHourlyReviewRequested(item));
          });
          break;
        case ActivityType.CUSTOM:
          const cusData = AppStorage.getMyActivities();
          cusData && dispatch(setMyActivitiesRequested(cusData));
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (isAppOnline && syncRequired) handleSync();
    // eslint-disable-next-line
  }, [isAppOnline, syncRequired]);

  useEffect(() => {
    if (synchronizing && syncRequired && !loadingActivities) {
      setSynchronizing(false);
      setSyncError(false);
    }
    // eslint-disable-next-line
  }, [synchronizing, syncRequired, loadingActivities]);

  useEffect(() => {
    if (synchronizing && syncFailed && !loadingActivities) {
      setSynchronizing(false);
      setSyncError(true);
    }
    // eslint-disable-next-line
  }, [synchronizing, syncFailed, loadingActivities]);

  return (
    <AppBarStyled position="fixed">
      <Toolbar>
        <MenuButton user={user} />
        <BackButtons />
        <Logo />
        <Typography variant="h5" noWrap ml={1}>
          {t("shared.title")}
        </Typography>
        {isAppOnline ? <OnlineIcon sx={onlineFx} /> : <OfflineIcon sx={offlineFx} />}
        {synchronizing && isAppOnline && (
          <Chip
            icon={<CircularProgress size={14} />}
            label={t("labels.synchronizing") + "..."}
            variant="filled"
            size="small"
            color="secondary"
            sx={{ mx: 2, px: 1, mt: 0.3, boxShadow: 3 }}
          />
        )}
        {syncError && isAppOnline && (
          <Chip
            icon={<SyncErrorIcon color="error" />}
            label={"Error al sincronizar! Reintentar?"}
            variant="filled"
            size="small"
            color="error"
            onClick={handleSync}
            sx={syncErrorFx}
          />
        )}
        {permissions.includes(AppPermissions.SUPPORT) && (
          <IconButton sx={{ ...iconStyle, mt: 0.5, ml: 1 }} onClick={() => console.log("click")}>
            <DevIcon sx={{ height: 26, width: 26 }} />
          </IconButton>
        )}
        <StyledBox />
        <IconButton
          size="small"
          sx={iconStyle}
          disabled={!showSidebar}
          onClick={() =>
            dispatch(
              setNavigateController({
                navigateController: { ...navigateController, path: routes.main.help.path },
              })
            )
          }
        >
          <ChangelogIcon sx={{ height: 26, width: 26 }} />
        </IconButton>
        <NotesMenu
          activitiesLoaded={activitiesLoaded}
          notesState={notesState}
          storePlan={storePlan}
        />
        <ProfileMenu />
      </Toolbar>
    </AppBarStyled>
  );
};

export default Header;
