export enum RemoteResourceState {
  REMOTE_RESOURCE_LOADED,
  REMOTE_RESOURCE_LOADING,
  REMOTE_RESOURCE_ERROR,
  REMOTE_RESOURCE_INITIAL
}
export interface RemoteResource<TData> {
  state: RemoteResourceState;
  data: TData;
}

export function initialRemoteResource<TData>(data: TData): RemoteResource<TData> {
  return { state: RemoteResourceState.REMOTE_RESOURCE_INITIAL, data };
}

export function requestedRemoteResource<TData>(data: TData): RemoteResource<TData> {
  return { state: RemoteResourceState.REMOTE_RESOURCE_LOADING, data };
}

export function doneRemoteResource<TData>(data: TData): RemoteResource<TData> {
  return { state: RemoteResourceState.REMOTE_RESOURCE_LOADED, data };
}

export function errorRemoteResource<TData>(data: TData): RemoteResource<TData> {
  return { state: RemoteResourceState.REMOTE_RESOURCE_ERROR, data };
}
