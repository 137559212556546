import { ICommentItem, IObjectiveItem } from "../../../types";
import {
  ActionType,
  AddCommentDoneAction,
  AddCommentErrorAction,
  AddCommentPayload,
  AddCommentRequestedAction,
  AddObjectiveDoneAction,
  AddObjectiveErrorAction,
  AddObjectivePayload,
  AddObjectiveRequestedAction,
  ClearObjectivesAction,
  DeleteCommentDoneAction,
  DeleteCommentErrorAction,
  DeleteCommentPayload,
  DeleteCommentRequestedAction,
  DeleteObjectiveDoneAction,
  DeleteObjectiveErrorAction,
  DeleteObjectivePayload,
  DeleteObjectiveRequestedAction,
  GetObjectivesDoneAction,
  GetObjectivesErrorAction,
  GetObjectivesPayload,
  GetObjectivesRequestedAction,
  GetSosDtReportDoneAction,
  GetSosDtReportErrorAction,
  GetSosDtReportPayload,
  GetSosDtReportRequestedAction,
  GetSosFcReportDoneAction,
  GetSosFcReportErrorAction,
  GetSosFcReportPayload,
  GetSosFcReportRequestedAction,
  GetSosMfyReportDoneAction,
  GetSosMfyReportErrorAction,
  GetSosMfyReportPayload,
  GetSosMfyReportRequestedAction,
  SetInvokedServicesAction,
  UpdateCommentDoneAction,
  UpdateCommentErrorAction,
  UpdateCommentPayload,
  UpdateCommentRequestedAction,
  UpdateObjectiveDoneAction,
  UpdateObjectiveErrorAction,
  UpdateObjectivePayload,
  UpdateObjectiveRequestedAction,
} from "./types";

/** OBJECTIVES */

export const getObjectivesRequested = (): GetObjectivesRequestedAction => {
  return { type: ActionType.getObjectivesRequested };
};

export const getObjectivesDone = (result: GetObjectivesPayload): GetObjectivesDoneAction => {
  return { type: ActionType.getObjectivesDone, payload: result };
};

export const getObjectivesError = (): GetObjectivesErrorAction => {
  return { type: ActionType.getObjectivesError };
};

export const addObjectiveRequested = (
  objective: Partial<IObjectiveItem>
): AddObjectiveRequestedAction => {
  return { type: ActionType.addObjectiveRequested, payload: { objective } };
};

export const addObjectiveDone = (result: AddObjectivePayload): AddObjectiveDoneAction => {
  return { type: ActionType.addObjectiveDone, payload: result };
};

export const addObjectiveError = (): AddObjectiveErrorAction => {
  return { type: ActionType.addObjectiveError };
};

export const updateObjectiveRequested = (
  objective: Partial<IObjectiveItem>
): UpdateObjectiveRequestedAction => {
  return { type: ActionType.updateObjectiveRequested, payload: { objective } };
};

export const updateObjectiveDone = (result: UpdateObjectivePayload): UpdateObjectiveDoneAction => {
  return { type: ActionType.updateObjectiveDone, payload: result };
};

export const updateObjectiveError = (): UpdateObjectiveErrorAction => {
  return { type: ActionType.updateObjectiveError };
};

export const deleteObjectiveRequested = (
  objective: Partial<IObjectiveItem>
): DeleteObjectiveRequestedAction => {
  return { type: ActionType.deleteObjectiveRequested, payload: { objective } };
};

export const deleteObjectiveDone = (result: DeleteObjectivePayload): DeleteObjectiveDoneAction => {
  return { type: ActionType.deleteObjectiveDone, payload: result };
};

export const deleteObjectiveError = (): DeleteObjectiveErrorAction => {
  return { type: ActionType.deleteObjectiveError };
};

/** COMMENTS */

export const addCommentRequested = (comment: Partial<ICommentItem>): AddCommentRequestedAction => {
  return { type: ActionType.addCommentRequested, payload: { comment } };
};

export const addCommentDone = (result: AddCommentPayload): AddCommentDoneAction => {
  return { type: ActionType.addCommentDone, payload: result };
};

export const addCommentError = (): AddCommentErrorAction => {
  return { type: ActionType.addCommentError };
};

export const updateCommentRequested = (
  comment: Partial<ICommentItem>
): UpdateCommentRequestedAction => {
  return { type: ActionType.updateCommentRequested, payload: { comment } };
};

export const updateCommentDone = (result: UpdateCommentPayload): UpdateCommentDoneAction => {
  return { type: ActionType.updateCommentDone, payload: result };
};

export const updateCommentError = (): UpdateCommentErrorAction => {
  return { type: ActionType.updateCommentError };
};

export const deleteCommentRequested = (
  comment: Partial<ICommentItem>
): DeleteCommentRequestedAction => {
  return { type: ActionType.deleteCommentRequested, payload: { comment } };
};

export const deleteCommentDone = (result: DeleteCommentPayload): DeleteCommentDoneAction => {
  return { type: ActionType.deleteCommentDone, payload: result };
};

export const deleteCommentError = (): DeleteCommentErrorAction => {
  return { type: ActionType.deleteCommentError };
};

export const clearObjectives = (): ClearObjectivesAction => {
  return { type: ActionType.clearObjectives };
};

/** SOS REPORT */

export const setInvokedServices = (result: { value: string[] }): SetInvokedServicesAction => {
  return { type: ActionType.setInvokedServices, payload: result };
};

export const getSosDtReportRequested = (): GetSosDtReportRequestedAction => {
  return { type: ActionType.getSosDtReportRequested };
};

export const getSosDtReportDone = (result: GetSosDtReportPayload): GetSosDtReportDoneAction => {
  return { type: ActionType.getSosDtReportDone, payload: result };
};

export const getSosDtReportError = (): GetSosDtReportErrorAction => {
  return { type: ActionType.getSosDtReportError };
};

export const getSosFcReportRequested = (): GetSosFcReportRequestedAction => {
  return { type: ActionType.getSosFcReportRequested };
};

export const getSosFcReportDone = (result: GetSosFcReportPayload): GetSosFcReportDoneAction => {
  return { type: ActionType.getSosFcReportDone, payload: result };
};

export const getSosFcReportError = (): GetSosFcReportErrorAction => {
  return { type: ActionType.getSosFcReportError };
};

export const getSosMfyReportRequested = (): GetSosMfyReportRequestedAction => {
  return { type: ActionType.getSosMfyReportRequested };
};

export const getSosMfyReportDone = (result: GetSosMfyReportPayload): GetSosMfyReportDoneAction => {
  return { type: ActionType.getSosMfyReportDone, payload: result };
};

export const getSosMfyReportError = (): GetSosMfyReportErrorAction => {
  return { type: ActionType.getSosMfyReportError };
};
