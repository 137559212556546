import { compareTimeFrames, getTimeFrameDetails } from "../../helpers/functions";
import {
  IEmployeeDetails,
  IEmployeeLocation,
  IEmployeePositioning,
  IPositioningDataByHour,
} from "../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../utils";
import { Actions, ActionType, OrquestState } from "./types";

function handleDataByHours(oldData: IPositioningDataByHour, newData: IPositioningDataByHour) {
  let result: IPositioningDataByHour = { ...oldData };
  Object.keys(newData).forEach((key) => {
    const hour = parseInt(key);
    if (result[hour]) result[hour] = result[hour] + newData[hour];
    else result[hour] = newData[hour];
  });
  return result;
}

function handlePositioningData(positioningData: IEmployeePositioning[]) {
  const employees: IEmployeeDetails[] = [];
  let totalEmployeesByHour: IPositioningDataByHour = {};
  let totalPositionsByHour: IPositioningDataByHour = {};
  const positioning: IEmployeePositioning[] = [];
  try {
    positioningData.forEach((e) => {
      const presence = {
        worked: e.presence && e.presence.worked ? e.presence.worked : false,
        timeFrames:
          e.presence && e.presence.timeFrames ? e.presence.timeFrames.sort(compareTimeFrames) : [],
      };
      const { range, employeesByHour, positionsByHour } = getTimeFrameDetails(presence.timeFrames);
      employees.push({ personId: e.personId, personName: e.personName });
      totalEmployeesByHour = { ...handleDataByHours(totalEmployeesByHour, employeesByHour) };
      totalPositionsByHour = { ...handleDataByHours(totalPositionsByHour, positionsByHour) };

      positioning.push({
        ...e,
        presence,
        timeRange: range,
      });
    });
  } catch (error) {
    console.log(error);
  }
  return {
    positioning: doneRemoteResource(positioning),
    employees,
    totalEmployeesByHour,
    totalPositionsByHour,
  };
}

/** falta actualizar DataByHour */
function updatePositioning(positioning: IEmployeePositioning[], data: IEmployeePositioning) {
  let newPositioning = [...positioning];
  const index = newPositioning.findIndex((e) => e.personId === data.personId);
  if (index !== -1) {
    newPositioning[index] = data;
  }
  // return doneRemoteResource(newPositioning);
  return handlePositioningData(newPositioning);
}

function getCategories(locations: IEmployeeLocation[]) {
  const categories: IEmployeeLocation[] = [];
  locations.forEach((l) => {
    const exist = Boolean(categories.find((e) => e.categoryId === l.categoryId));
    if (!exist) categories.push({ ...l });
  });

  return categories;
}

const initialState: OrquestState = {
  positioning: initialRemoteResource([]),
  locations: initialRemoteResource([]),
  employees: [],
  totalEmployeesByHour: {},
  totalPositionsByHour: {},
  categories: [],
  isLoading: false,
  isUpdating: false,
};

const orquestReducer = (state: OrquestState = initialState, action: Actions): OrquestState => {
  switch (action.type) {
    case ActionType.getPositioningRequested:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.getPositioningDone:
      return {
        ...state,
        ...handlePositioningData(action.payload.positioning),
        isLoading: false,
      };
    case ActionType.getPositioningError:
      return {
        ...state,
        positioning: errorRemoteResource([]),
        employees: [],
        isLoading: false,
      };

    case ActionType.updatePositioningRequested:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.updatePositioningDone:
      return {
        ...state,
        // positioning: updatePositioning(state.positioning.data, action.payload.positioning),
        ...updatePositioning(state.positioning.data, action.payload.positioning),
        isLoading: false,
      };
    case ActionType.updatePositioningError:
      return {
        ...state,
        isLoading: false,
      };

    case ActionType.getLocationsRequested:
      return {
        ...state,
        locations: requestedRemoteResource([]),
        categories: [],
        isLoading: true,
      };
    case ActionType.getLocationsDone:
      return {
        ...state,
        locations: doneRemoteResource(action.payload.locations),
        categories: getCategories(action.payload.locations),
        isLoading: false,
      };
    case ActionType.getLocationsError:
      return {
        ...state,
        locations: errorRemoteResource([]),
        categories: [],
        isLoading: false,
      };

    default:
      return state;
  }
};

export default orquestReducer;
