import React from "react";
import Container from "@mui/material/Container";
import CheckList from "../../components/CheckList";
import { useDispatch, useSelector } from "react-redux";
import { getActivityTypeDescription } from "../../helpers/functions";
import { setMyActivitiesRequested } from "../../redux/activities/checklists/actions";
import { myActivitiesDataSelector } from "../../redux/activities/checklists/selectors";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";
import { IActivityItem } from "../../types";

/**
 * Obtiene los checklist personalizados.
 * Se envía y guarda el checklist completo.
 * @activityType CUS
 */

const MyActivities = () => {
  const dispatch = useDispatch();
  const activityTypes = useSelector(activityTypesDataSelector);
  const myActivitiesData = useSelector(myActivitiesDataSelector);

  const handleSubmit = (data: IActivityItem[]) => {
    dispatch(setMyActivitiesRequested(data));
  };

  return (
    <Container maxWidth={false}>
      <CheckList
        title={getActivityTypeDescription(activityTypes["CUS"])}
        data={myActivitiesData}
        isCustom={true}
        onSubmit={handleSubmit}
        showProgress
      />
    </Container>
  );
};

export default MyActivities;
