import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  PromiseResult,
  activityTypesFormatted,
  getQueryParams,
  stepsFormatted,
} from "../../helpers/functions";
import {
  AlertCode,
  IHourlyReviewTypes,
  IHttpRequestError,
  IManager,
  IQueryParams,
} from "../../types";
import { setAlert, setSnackbar } from "../app/actions";
import {
  getActivityTypesDone,
  getActivityTypesError,
  getAppParametersDone,
  getAppParametersError,
  getHourlyReviewTypesDone,
  getHourlyReviewTypesError,
  getObjectivesTypesDone,
  getObjectivesTypesError,
  getStorePlanDone,
  getStorePlanError,
} from "./actions";
import {
  ActionType,
  GetActivityTypesRequestedAction,
  GetAppParametersRequestedAction,
  GetHourlyReviewTypesRequestedAction,
  GetObjectivesTypesRequestedAction,
  GetStorePlanRequestedAction,
} from "./types";
import StorePlanServices from "../../services/StorePlanServices";
import { filtersSelector } from "../admin/masterdata/selectors";
import { appUserSelector } from "../user/selectors";
import {
  GetStorePlanResponse,
  GetActivityTypesResponse,
  GetObjectivesTypesResponse,
  GetHourlyReviewTypesResponse,
  GetAppParametersResponse,
} from "../../services/interfaces";

function* getStorePlanSagas(action: GetStorePlanRequestedAction) {
  const manager: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    username: manager.username,
    activityTypesId: action.payload.activityTypesId,
  };
  try {
    const {
      result: response,
      hasError,
      error,
    }: PromiseResult<GetStorePlanResponse> = yield call(StorePlanServices.getStorePlan, params);
    if (hasError || !response) throw error;
    yield put(getStorePlanDone({ storePlan: response.data }));
  } catch (error) {
    yield put(getStorePlanError());
    yield put(setAlert({ alert: { severity: "error", error: error as IHttpRequestError } }));
    console.log("StorePlan error", error);
  }
}

function* getActivityTypesSagas(action: GetActivityTypesRequestedAction) {
  try {
    const {
      result: getAcvityTypesResponse,
      hasError,
      error,
    }: PromiseResult<GetActivityTypesResponse> = yield call(StorePlanServices.getActivityTypes);
    if (hasError || !getAcvityTypesResponse) throw error;
    const activityTypes = activityTypesFormatted(getAcvityTypesResponse.data);
    yield put(getActivityTypesDone({ activityTypes }));
  } catch (error) {
    yield put(getActivityTypesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.ACTIVITY_TYPES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getObjectivesTypesSagas(action: GetObjectivesTypesRequestedAction) {
  try {
    const {
      result: getObjectivesTypesResponse,
      hasError,
      error,
    }: PromiseResult<GetObjectivesTypesResponse> = yield call(StorePlanServices.getObjectivesTypes);
    if (hasError || !getObjectivesTypesResponse) throw error;
    const objectivesTypes = getObjectivesTypesResponse.data;
    yield put(getObjectivesTypesDone({ objectivesTypes }));
  } catch (error) {
    yield put(getObjectivesTypesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_TYPES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getHourlyReviewTypesSagas(action: GetHourlyReviewTypesRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getHourlyReviewTypesResponse,
      hasError,
      error,
    }: PromiseResult<GetHourlyReviewTypesResponse> = yield call(
      StorePlanServices.getHourlyReviewTypes,
      params
    );
    if (hasError || !getHourlyReviewTypesResponse) throw error;
    const hourlyReviewTypes: IHourlyReviewTypes = {
      steps: stepsFormatted(getHourlyReviewTypesResponse.data.steps),
      confirm: getHourlyReviewTypesResponse.data.confirm,
    };
    yield put(getHourlyReviewTypesDone({ hourlyReviewTypes }));
  } catch (error) {
    yield put(getHourlyReviewTypesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.HOURLY_REVIEW_TYPES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getAppParametersSagas(action: GetAppParametersRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getAppParametersResponse,
      hasError,
      error,
    }: PromiseResult<GetAppParametersResponse> = yield call(
      StorePlanServices.getAppParameters,
      params
    );
    if (hasError || !getAppParametersResponse) throw error;
    yield put(getAppParametersDone({ appParameters: getAppParametersResponse.data }));
  } catch (error) {
    yield put(getAppParametersError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.HOURLY_REVIEW_TYPES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const activitySagas = [
  takeLatest(ActionType.getStorePlanRequested, getStorePlanSagas),
  takeLatest(ActionType.getActivityTypesRequested, getActivityTypesSagas),
  takeLatest(ActionType.getObjectivesTypesRequested, getObjectivesTypesSagas),
  takeLatest(ActionType.getHourlyReviewTypesRequested, getHourlyReviewTypesSagas),
  takeLatest(ActionType.getAppParametersRequested, getAppParametersSagas),
];

export default activitySagas;
