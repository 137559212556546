import { OrquestState } from "./types";

interface State {
  orquest: OrquestState;
}

export const userStateSelector = (state: State) => state.orquest;
export const positioningDataSelector = (state: State) => state.orquest.positioning.data;
export const locationsDataSelector = (state: State) => state.orquest.locations.data;
export const categoriesSelector = (state: State) => state.orquest.categories;
export const employeesSelector = (state: State) => state.orquest.employees;
export const totalEmployeesByHourSelector = (state: State) => state.orquest.totalEmployeesByHour;
export const totalPositionsByHourSelector = (state: State) => state.orquest.totalPositionsByHour;
export const isLoadingSelector = (state: State) => state.orquest.isLoading;
