import { takeLatest, call, put, select } from "redux-saga/effects";
import StorePlanServices from "../../../services/StorePlanServices";
import { PromiseResult, getISOString, getQueryParams } from "../../../helpers/functions";
import { AlertCode, IHttpRequestError, IQueryParams } from "../../../types";
import { getAreasDone, getAreasError, setAreasDone, setAreasError } from "./actions";
import { GetAreasRequestedAction, ActionType, SetAreasRequestedAction } from "./types";
import { setSnackbar } from "../../app/actions";
import { GetAreasResponse, ApiResponse } from "../../../services/interfaces";
import { filtersSelector } from "../masterdata/selectors";

function* getAreasSagas(action: GetAreasRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: getAreasResponse,
      hasError,
      error,
    }: PromiseResult<GetAreasResponse> = yield call(StorePlanServices.getAreas, params);
    if (hasError || !getAreasResponse) throw error;
    yield put(getAreasDone({ areas: getAreasResponse.data }));
  } catch (error) {
    yield put(getAreasError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.AREAS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* setAreasSagas(action: SetAreasRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    fromDate: getISOString(),
  };
  try {
    const areas = action.payload.areas;
    const {
      result: setAreasResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(StorePlanServices.setAreas, areas, params);
    if (hasError || !setAreasResponse) throw error;
    yield put(setAreasDone({ areas }));
  } catch (error) {
    yield put(setAreasError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.AREAS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const adminSagas = [
  takeLatest(ActionType.getAreasRequested, getAreasSagas),
  takeLatest(ActionType.setAreasRequested, setAreasSagas),
];

export default adminSagas;
