import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ICommentItem } from "../types";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { getLocale, getUserFullName, stringAvatar } from "../helpers/functions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { appUserSelector } from "../redux/user/selectors";
import UserAvatar from "./UserAvatar";
import { commentErrorSelector } from "../redux/activities/notes/selectors";
import { FlexBox } from "../styles";
import { isAppOnlineSelector } from "../redux/app/selectors";

const StyledIconBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
}));

const mainContainer: SxProps = {
  display: "flex",
  alignItems: "start",
};

const commentContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

const userText: SxProps = {
  fontWeight: 800,
};

const smIcon: SxProps = {
  width: 20,
  height: 20,
  pl: 0.15,
};

type SubmitAction = "add" | "edit" | "delete";

interface Props {
  data?: ICommentItem;
  loading?: boolean;
  onSubmit: (action: SubmitAction, data: Partial<ICommentItem>) => void;
}

const ItemComment = ({ data, loading, onSubmit }: Props) => {
  const { t } = useTranslation();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const user = useSelector(appUserSelector);
  const commentError = useSelector(commentErrorSelector);
  const [formData, setFormData] = useState<Partial<ICommentItem>>({});
  const [isEdit, setIsEdit] = useState(false);
  const disableSend = !formData.description || formData.description === "";

  useEffect(() => {
    if (data) setFormData({ ...data });
    return () => setFormData({});
  }, [data]);

  useEffect(() => {
    if (commentError) handleCancel();
    if (!data && !loading) setFormData({});
    // eslint-disable-next-line
  }, [commentError, loading]);

  const handleSubmit = (action: SubmitAction) => {
    if (!disableSend) {
      onSubmit(action, formData);
      isEdit && setIsEdit(false);
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    data && setFormData({ ...data });
  };

  const handleChange = (value: string) => {
    const newData: Partial<ICommentItem> = { ...formData, description: value };
    setFormData(newData);
  };

  /** RENDERS */

  const headerComment = () => {
    const userComment = data?.manager?.username || "-";
    const date = data?.lastUpdate
      ? formatDistanceToNow(new Date(data.lastUpdate), { locale: getLocale() })
      : "-";

    return (
      <Box sx={commentContainer}>
        <FlexBox>
          <Typography variant="body1" sx={userText}>
            {getUserFullName(data?.manager)}
          </Typography>
          <Typography variant="body2" mx={1}>
            {date}
          </Typography>
          {data?.isEdited && (
            <Typography variant="body2">{t("labels.isEdited").toLowerCase()}</Typography>
          )}
        </FlexBox>
        {user?.username === userComment && !isEdit && (
          <FlexBox>
            <StyledIconBtn
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setIsEdit(true)}
              disabled={!isAppOnline}
            >
              <EditIcon color={isAppOnline ? "primary" : "disabled"} sx={smIcon} />
            </StyledIconBtn>
            <StyledIconBtn
              size="small"
              onClick={() => handleSubmit("delete")}
              disabled={!isAppOnline}
            >
              <DeleteIcon color={isAppOnline ? "primary" : "disabled"} sx={smIcon} />
            </StyledIconBtn>
          </FlexBox>
        )}
      </Box>
    );
  };

  const footerComment = () => {
    return (
      <Box sx={{ display: "flex", width: "100%", justifyContent: "end", mr: 1 }}>
        {isEdit && (
          <StyledIconBtn size="small" onClick={handleCancel} sx={{ mr: 1 }} disabled={!isAppOnline}>
            <CancelIcon color={isAppOnline ? "primary" : "disabled"} sx={smIcon} />
          </StyledIconBtn>
        )}
        <StyledIconBtn
          size="small"
          onClick={() => handleSubmit(isEdit ? "edit" : "add")}
          disabled={!isAppOnline}
        >
          <SendIcon color={isAppOnline ? "primary" : "disabled"} sx={smIcon} />
        </StyledIconBtn>
      </Box>
    );
  };

  return (
    <Box sx={{ ...mainContainer, opacity: loading ? 0.5 : 1 }}>
      <UserAvatar sx={{ mr: 1, width: 35, height: 35, fontSize: "1rem" }}>
        {data ? stringAvatar(data.manager) : stringAvatar(user)}
      </UserAvatar>
      <TextField
        fullWidth
        id="name"
        size="small"
        multiline
        rows={3}
        value={formData?.description || ""}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={t("labels.commentPH").toString()}
        InputProps={{
          readOnly: data && !isEdit,
          style: {
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          },
          startAdornment: data && !isEdit && (
            <InputAdornment position="start" sx={{ mt: 2, mb: 3, width: "100%" }}>
              {headerComment()}
            </InputAdornment>
          ),
          endAdornment: (isEdit || !data) && (
            <InputAdornment position="end" sx={{ my: 2, width: "100%" }}>
              {footerComment()}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ItemComment;
