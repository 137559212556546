import { MasterdataState } from "./types";

interface State {
  masterdata: MasterdataState;
}

export const masterdataStateSelector = (state: State) => state.masterdata.masterdata;
export const masterdataSelector = (state: State) => state.masterdata.masterdata.data;
export const filtersSelector = (state: State) => state.masterdata.filters;
export const filteredOptionsSelector = (state: State) => state.masterdata.filteredOptions;
export const loadingSelector = (state: State) => state.masterdata.loading;
