import PedalBike from "@mui/icons-material/PedalBike";
import SoupKitchen from "@mui/icons-material/SoupKitchen";
import Fastfood from "@mui/icons-material/Fastfood";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import LocalCafe from "@mui/icons-material/LocalCafe";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Engineering from "@mui/icons-material/Engineering";
import Deck from "@mui/icons-material/Deck";
import Storefront from "@mui/icons-material/Storefront";
import Icecream from "@mui/icons-material/Icecream";
import OtherIcon from "@mui/icons-material/Circle";
import { SxProps } from "@mui/material";

const xsIcon: SxProps = {
  height: 12,
  width: 12,
  mt: 0.25,
  ml: 0.3,
};

export const LOCATION_CATEGORY_ICONS: { [key: number]: JSX.Element } = {
  1: <PedalBike />, // DELIVERY
  2: <SoupKitchen />, // COCINA
  3: <Fastfood />, // MOSTRADOR
  4: <LocalCafe />, // MCCAFE
  5: <DirectionsCar />, // AUTOMAC
  6: <Deck />, // ENTREGA A LA MESA
  7: <SupervisorAccount />, // GERENTE
  8: <Engineering />, // TAREAS GENERALES
  9: <Storefront />, // CDP1
  10: <Icecream />, // KIOSCO & ISLA 1
  11: <Storefront />, // CDP2
  12: <Storefront />, // CDP3
  13: <Storefront />, // CDP4
  14: <Storefront />, // CDP5
  15: <Storefront />, // CDP6
  16: <Storefront />, // CDP7
  17: <SupervisorAccount />, // ADMINISTRATIVA
  18: <SupervisorAccount />, // TAREAS DE LIDER 1
  19: <SupervisorAccount />, // TAREAS DE LIDER 2
  20: <SupervisorAccount />, // TAREAS DE LIDER 3
  21: <SupervisorAccount />, // TAREAS DE LIDER 4
  22: <Engineering />, // MANTENIMIENTO Y EQUIPOS
  23: <Storefront />, // CDP Interno 1
  24: <Storefront />, // CDP Interno 2
  25: <Storefront />, // CDP Interno 3
  26: <LocalCafe />, // MCCAFE
  27: <Icecream />, // KIOSCO & ISLA 2
  28: <Icecream />, // KIOSCO & ISLA 3
  29: <Icecream />, // KIOSCO & ISLA 4
  30: <Icecream />, // KIOSCO & ISLA 5
  31: <Icecream />, // KIOSCO & ISLA 6
  32: <Icecream />, // KIOSCO & ISLA 7
  33: <Icecream />, // KIOSCO & ISLA 8
  34: <Icecream />, // KIOSCO & ISLA 9
  35: <Icecream />, // KIOSCO & ISLA 10
  36: <Icecream />, // KIOSCO & ISLA 11
  37: <Icecream />, // KIOSCO & ISLA 12
  38: <SoupKitchen />, // COCINA
  39: <Fastfood />, // MOSTRADOR
};

export function getLocationCategoryIcon(key?: number) {
  if (!key || !LOCATION_CATEGORY_ICONS[key]) return <OtherIcon sx={xsIcon} />;
  else return LOCATION_CATEGORY_ICONS[key];
}
