import React from "react";
import {
  Box,
  Chip,
  Grid,
  ListItem,
  Paper,
  SxProps,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import LinearProgressBar from "../LinearProgressBar";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import UncompleteIcon from "@mui/icons-material/Cancel";
import UserIcon from "@mui/icons-material/ManageAccounts";
import SchedulesIcon from "@mui/icons-material/PendingActions";
import StoreIcon from "@mui/icons-material/Fastfood";
import { useTranslation } from "react-i18next";
import IconAvatar from "../IconAvatar";
import { FlexBox } from "../../styles";

const ContainerBox = styled(Box)({
  height: "100%",
});

const ProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const StyleTitle = styled(Typography)({
  fontWeight: 600,
});

const lgIcon: SxProps = {
  width: 35,
  height: 35,
  ml: 0.25,
};

interface Props {
  title: string;
  progress: { managers: boolean; schedules: boolean; areas: boolean };
}

const AdminCard = ({ title, progress }: Props) => {
  const { t } = useTranslation();
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width:480px)");
  const { managers, schedules, areas } = progress;

  const getProgress = () => {
    let finished = 0;
    if (managers) finished++;
    if (schedules) finished++;
    if (areas) finished++;
    return { finished, value: (finished * 100) / 3 };
  };

  const { finished, value } = getProgress();

  return (
    <ContainerBox component={Paper} p={2} pt={1}>
      <Typography variant="h6">{title}</Typography>
      <ProgressBox mt={2}>
        <Typography>{t("labels.progress")}</Typography>
        <Typography sx={{ fontWeight: 500 }}>{`${finished}/${3}`}</Typography>
      </ProgressBox>
      <LinearProgressBar value={value} />
      <Grid container spacing={1} mt={2}>
        <Grid item md={4} xs={12}>
          <StatCard
            title={isTab && !isMobile ? t("labels.managers") : t("labels.leaderRegistry")}
            complete={managers}
            icon={<UserIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <StatCard
            title={isTab && !isMobile ? t("labels.schedule") : t("labels.scheduleConfiguration")}
            complete={schedules}
            icon={<SchedulesIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <StatCard
            title={isTab && !isMobile ? t("labels.areas") : t("labels.areaConfiguration")}
            complete={areas}
            icon={<StoreIcon sx={lgIcon} color="primary" />}
          />
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

interface SCProps {
  title: string;
  icon: JSX.Element;
  complete: boolean;
}

const StatCard = ({ title, complete, icon }: SCProps) => {
  const { t } = useTranslation();

  const renderChip = () => {
    if (complete)
      return (
        <Chip
          icon={<CompleteIcon />}
          label={t("labels.completed").toString()}
          variant="filled"
          size="small"
          color="success"
        />
      );
    else
      return (
        <Chip
          icon={<UncompleteIcon />}
          label={t("labels.pending").toString()}
          variant="filled"
          size="small"
          color="error"
        />
      );
  };

  return (
    <ListItem alignItems="center" sx={{ px: 0 }}>
      <FlexBox>
        <IconAvatar sx={{ mr: 1, width: 48, height: 48 }}>{icon}</IconAvatar>
        <Box>
          <StyleTitle>{title}</StyleTitle>
          {renderChip()}
        </Box>
      </FlexBox>
    </ListItem>
  );
};

export default AdminCard;
