import { Avatar, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { infoSelector } from "../../redux/user/selectors";
import { ActionButton, SecondaryText } from "../../styles";
import { useMsal } from "@azure/msal-react";
import LockIcon from "@mui/icons-material/LockPerson";

const NoAccess = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const session = useSelector(infoSelector);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} p={4}>
        <Grid item xs={9}>
          <Typography variant="h4" sx={{ my: 2 }}>
            {t("errors.codes.no_app_permission.title")}
          </Typography>
          <SecondaryText variant="h6">
            {t("errors.codes.no_app_permission.description", { username: session?.username })}
          </SecondaryText>
          <ActionButton
            variant="contained"
            size="medium"
            sx={{ mt: 4, px: 4 }}
            onClick={handleLogout}
          >
            {t("labels.changeUser")}
          </ActionButton>
        </Grid>
        <Grid item xs={3}>
          <Avatar sx={{ height: 300, width: 300 }}>
            <LockIcon color="primary" sx={{ height: 200, width: 200 }} />
          </Avatar>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoAccess;
