import React, { useState } from "react";
import { Box, IconButton, Menu, Paper, SxProps, Typography } from "@mui/material";
import { BoldTypography, FlexBox, LightTypography } from "../../styles";
import ReportIcon from "@mui/icons-material/Report";
import CloseIcon from "@mui/icons-material/Cancel";
import GcIcon from "@mui/icons-material/Sell";
import { useTranslation } from "react-i18next";

import { ISegmentItem } from "../../types";
import { red } from "@mui/material/colors";

const gcIcon: SxProps = {
  height: 16,
  width: 16,
  mb: 0.8,
  mr: 0.5,
};

const container: SxProps = {
  p: 1,
  height: "100%",
  margin: "auto",
  opacity: 0.6,
};

const errorStyle: SxProps = {
  cursor: "pointer",
  p: 1,
  height: "100%",
  margin: "auto",
  color: "#FFF",
  bgcolor: red[900],
  boxShadow: "inset 0px 0px 0.1em 1px grey, 0px 0px 0.1em 1px grey ",
  "@keyframes errorFx": {
    from: {
      transform: "scale(1)",
      boxShadow: "inset 0px 0px 0.3em 0px grey, 0px 0px 0.3em 0px grey",
      bgcolor: red[900],
    },
    to: {
      transform: "scale(1.05)",
      boxShadow: "inset 0px 0px 0.3em 2px red, 0px 0px 0.3em 2px red ",
      bgcolor: "red",
    },
  },
  animation: "errorFx 0.6s ease-in-out infinite alternate",
  "&:hover": {
    opacity: 0.8,
  },
};

interface Props {
  data?: ISegmentItem;
  segment: string;
}

const UndefinedGcCard = ({ data, segment }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const error = Boolean(data && data.gcReal > 0);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component={Paper}
        sx={error ? errorStyle : container}
        onClick={(e) => error && handleMenuClick(e)}
      >
        <FlexBox>
          <ReportIcon sx={{ mr: 1, width: 48, height: 48, color: error ? "#FFF" : undefined }} />
          <Box>
            <LightTypography variant="caption">
              {t("labels.segments.OTHERS").toUpperCase()}
            </LightTypography>
            <FlexBox>
              <GcIcon sx={{ ...gcIcon, color: error ? "#FFF" : undefined }} />
              <BoldTypography variant="h6" sx={{ lineHeight: 1, mb: 0.7 }}>
                {data?.gcReal || "0"}
              </BoldTypography>
            </FlexBox>
          </Box>
        </FlexBox>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 85,
                right: 14,
                width: 15,
                height: 15,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box mx={2} my={1} sx={{ position: "relative" }}>
          <FlexBox>
            <Typography variant="h6">
              {t("labels.thereAre")}
              <span style={{ color: red[900] }}>{` ${data?.gcReal || 0} `}</span>
              {t("labels.uncategorizedGc")}
            </Typography>
          </FlexBox>
          <Typography>{t("labels.uncategorizedGcMsg")}</Typography>
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: -8,
              right: -8,
              height: 20,
              width: 20,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Menu>
    </>
  );
};

export default UndefinedGcCard;
