import { takeLatest, call, put, select } from "redux-saga/effects";
import StorePlanServices, { ManagersResponse } from "../../../services/StorePlanServices";
import { PromiseResult, getQueryParams } from "../../../helpers/functions";
import { AlertCode, IHttpRequestError } from "../../../types";
import {
  getManagersDone,
  getManagersError,
  addManagerDone,
  addManagerError,
  deleteManagerDone,
  deleteManagerError,
  updateManagerDone,
  updateManagerError,
} from "./actions";
import {
  ActionType,
  GetManagersRequestedAction,
  AddManagerRequestedAction,
  DeleteManagerRequestedAction,
  UpdateManagerRequestedAction,
} from "./types";
import { setSnackbar } from "../../app/actions";
import { ApiResponse, GetManagersResponse } from "../../../services/interfaces";
import { filtersSelector } from "../masterdata/selectors";

function* getManagersSagas(action: GetManagersRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: getManagersResponse,
      hasError,
      error,
    }: PromiseResult<GetManagersResponse> = yield call(StorePlanServices.getManagers, params);
    if (hasError || !getManagersResponse) throw error;
    yield put(getManagersDone({ managers: getManagersResponse.data }));
  } catch (error) {
    yield put(getManagersError());
  }
}

function* addManagerSagas(action: AddManagerRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: addManagerResponse,
      hasError,
      error,
    }: PromiseResult<ManagersResponse> = yield call(
      StorePlanServices.addManager,
      action.payload.manager,
      params
    );
    if (hasError || !addManagerResponse) throw error;
    yield put(addManagerDone({ manager: addManagerResponse.data }));
  } catch (error) {
    yield put(addManagerError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.MANAGERS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateManagerSagas(action: UpdateManagerRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: updateManagerResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      StorePlanServices.updateManager,
      action.payload.manager,
      params
    );
    if (hasError || !updateManagerResponse) throw error;
    yield put(updateManagerDone({ manager: action.payload.manager }));
  } catch (error) {
    yield put(updateManagerError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.MANAGERS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteManagerSagas(action: DeleteManagerRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: deleteManagerResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      StorePlanServices.deleteManager,
      action.payload.identifier,
      params
    );
    if (hasError || !deleteManagerResponse) throw error;
    yield put(deleteManagerDone({ identifier: action.payload.identifier }));
  } catch (error) {
    yield put(deleteManagerError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.MANAGERS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const adminSagas = [
  takeLatest(ActionType.getManagersRequested, getManagersSagas),
  takeLatest(ActionType.addManagerRequested, addManagerSagas),
  takeLatest(ActionType.updateManagerRequested, updateManagerSagas),
  takeLatest(ActionType.deleteManagerRequested, deleteManagerSagas),
];

export default adminSagas;
