import React, { useState } from "react";
import ChangelogAccordion from "./ChangelogAccordion";
import { Grid, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { changelogsReportSelector } from "../../redux/app/selectors";
import { useTranslation } from "react-i18next";
import { SecondaryText } from "../../styles";

// const ratingFx: SxProps = {
//   "@keyframes ratingGlow": {
//     from: { mb: -1 },
//     to: { mb: 1 },
//   },
//   animation: "ratingGlow 1s ease-in-out infinite alternate",
// };

// const ratingPopupStyle: SxProps<Theme> = (theme) => ({
//   mt: 2,
//   display: "grid",
//   justifyItems: "center",
//   [theme.breakpoints.up("lg")]: {
//     width: 300,
//     position: "absolute",
//     bottom: 14,
//     right: 24,
//   },
// });

const HelpPage = () => {
  const { t } = useTranslation();
  // const isAppOnline = useSelector(isAppOnlineSelector);
  const changelogsReport = useSelector(changelogsReportSelector);
  const [selected, setSelected] = useState<string | null>(changelogsReport[0]?.id || null);
  // const [feedback, setFeedback] = useState("");
  // const [rating, setRating] = useState(false);
  // const [value, setValue] = React.useState<number | null>(2);

  const handleToggle = (id: string, expanded: boolean) => {
    if (expanded) setSelected(id);
    else setSelected(null);
  };

  // const handleFeedback = () => {
  //   setFeedback("");
  // };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item lg={7} md={12}>
          <Typography variant="h5">{t("pages.help.logOfChanges")}</Typography>
          <SecondaryText sx={{ lineHeight: 1, mt: 1, mb: 2 }}>
            {t("pages.help.logOfChangesSubtitle")}
          </SecondaryText>
          {changelogsReport.length === 0 && (
            <Typography variant="h6">{t("pages.changelogs.noData")}</Typography>
          )}
          {changelogsReport.map((item) => (
            <ChangelogAccordion
              key={item.version}
              item={item}
              isExpanded={selected === item.id}
              toggle={handleToggle}
            />
          ))}
        </Grid>
        {/* <Grid item lg={5} md={12}>
          <Typography variant="h5">{t("pages.help.leaveYourFeedback")}</Typography>
          <SecondaryText sx={{ lineHeight: 1, mt: 0.5, mb: 2 }}>
            {t("pages.help.leaveYourFeedbackDescription")}
            <span style={{ fontSize: "1.4rem" }}>😁</span>
          </SecondaryText>
          <TextField
            required
            fullWidth
            id="feedback"
            placeholder={t("pages.help.enterFeedback").toString()}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            size="small"
            multiline
            rows={5}
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: 2,
            }}
          />
          <SecondaryText variant="caption" sx={{ ml: 1, opacity: 0.6 }}>
            {`* ${t("pages.help.anonymousFeedback")}`}
          </SecondaryText>
          <ActionButton
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3 }}
            disabled={feedback === "" || !isAppOnline}
            onClick={handleFeedback}
          >
            {t("pages.help.sendFeedback")}
          </ActionButton>
        </Grid> */}
      </Grid>
      {/* <Box sx={ratingPopupStyle}>
        <Collapse in={!rating}>
          <ActionButton onClick={() => setRating(true)} sx={ratingFx}>
            <Typography variant="h6" sx={{ lineHeight: 1, mt: 0.5, mb: 2 }}>
              {"¿Disfrutando de Mi Turno?"}
              <span style={{ fontSize: "1.5rem" }}>🤔</span>
            </Typography>
          </ActionButton>
        </Collapse>

        <Collapse in={rating}>
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ p: 1, textAlign: "center", paddingBottom: "4px !important" }}>
              <Typography variant="h6">{"¿Disfrutando de Mi Turno?"}</Typography>
              <SecondaryText variant="body2" sx={{ px: 2, py: 2 }}>
                {"Toque una estrella para calificar la aplicación"}
              </SecondaryText>
              <Divider flexItem />
              <Rating
                name="simple-controlled"
                value={value}
                sx={{ py: 1 }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
              <Divider flexItem />
              <FlexBox>
                <Button fullWidth variant="text" onClick={() => setRating(false)}>
                  Cancelar
                </Button>
                <Divider flexItem orientation="vertical" />
                <Button fullWidth variant="text">
                  Enviar
                </Button>
              </FlexBox>
            </CardContent>
          </Card>
        </Collapse>
      </Box> */}
    </Container>
  );
};

export default HelpPage;
