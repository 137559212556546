import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ICommentItem, IManager, INoteItem } from "../../types";
import {
  Box,
  Checkbox,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ActionButton, SecondaryText } from "../../styles";
import { useTranslation } from "react-i18next";
import StateIcon from "@mui/icons-material/Circle";
import PrivateIcon from "@mui/icons-material/PendingActions";
import PublicIcon from "@mui/icons-material/Storefront";
import DateIcon from "@mui/icons-material/Today";
import ShiftIcon from "@mui/icons-material/PendingActions";
import ActivityIcon from "@mui/icons-material/Rule";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getActivityTypeByRoute,
  getActivityTypeDescription,
  getISOString,
  getLocale,
  getNoteShiftDescription,
  getScheduleDescription,
} from "../../helpers/functions";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { useLocation } from "react-router-dom";
import {
  loadingCommentSelector,
  noteModalOpenSelector,
  selectedNoteSelector,
} from "../../redux/activities/notes/selectors";
import { useDispatch } from "react-redux";
import {
  addNoteRequested,
  deleteNoteRequested,
  setSelectedNote,
  toggleNoteModal,
  updateNoteRequested,
} from "../../redux/activities/notes/actions";
import CommentList from "../CommentList";
import IconAvatar from "../IconAvatar";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const titleStyle: SxProps = {
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const smIcon: SxProps = {
  width: 20,
  height: 20,
  mr: 1,
  pb: 0.15,
};

const mdIcon: SxProps = {
  width: 25,
  height: 25,
  mr: 0.7,
  ml: -0.33,
  pb: 0.15,
};
const noteTypeColor: SxProps = {
  opacity: 0.8,
  color: "common.black",
};

export interface NoteFormData extends Partial<INoteItem> {
  [key: string]: number | string | boolean | IManager | ICommentItem[] | undefined;
}

const initialState: NoteFormData = {
  priority: false,
  private: false,
  state: "pending",
};

export const NoteFormModal = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const location = useLocation();
  const filters = useSelector(filtersSelector);
  const activityTypes = useSelector(activityTypesDataSelector);
  const open = useSelector(noteModalOpenSelector);
  const data = useSelector(selectedNoteSelector);
  const loadingComments = useSelector(loadingCommentSelector);
  const activityTypeId = getActivityTypeByRoute(location.pathname);
  const activityTypeDescription = getActivityTypeDescription(activityTypes[activityTypeId]);
  const [isModified, setIsModified] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [formData, setFormData] = React.useState<NoteFormData>(initialState);
  const isEdit = Boolean(data);
  const disableSubmit = error || (isEdit && !isModified) || !isAppOnline;

  React.useEffect(() => {
    if (data) {
      const formData = { ...data };
      setFormData(formData);
    }
    return () => setFormData(initialState);
    // eslint-disable-next-line
  }, [data]);

  const clearData = () => {
    setFormData(initialState);
    setError(false);
    setIsModified(false);
    setIsDelete(false);
    dispatch(toggleNoteModal());
    dispatch(setSelectedNote({ note: null }));
  };

  const onSubmit = (data?: Partial<INoteItem>, action?: "edit" | "delete") => {
    switch (action) {
      case "edit":
        data && dispatch(updateNoteRequested(data));
        break;
      case "delete":
        data?.id && dispatch(deleteNoteRequested(data.id));
        break;
      default:
        data && dispatch(addNoteRequested(data));
        break;
    }
    clearData();
  };

  /** HANDLERS */

  const handleSubmit = () => {
    if (formData.state === "delete") {
      setIsDelete(true);
    } else {
      const action = isEdit ? "edit" : undefined;
      const newData: Partial<INoteItem> = {
        ...formData,
        activityTypesId: activityTypeId,
        createdDate: isEdit ? formData.createdDate : getISOString(),
        lastUpdateDate: getISOString(),
      };
      onSubmit(newData, action);
    }
  };

  const handleDelete = () => {
    data && onSubmit(data, "delete");
  };

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;
    setFormData(initialState);
    setIsModified(false);
    dispatch(toggleNoteModal());
    dispatch(setSelectedNote({ note: null }));
    setError(false);
  };

  const handleChange = (value: string | boolean, field: string) => {
    if (
      !isEdit ||
      ["state", "priority"].includes(field) ||
      (field === "description" && data?.private)
    ) {
      let newData = { ...formData };
      switch (field) {
        case "private":
          newData[field] = value === "true" ? true : false;
          break;
        default:
          newData[field] = value;
          break;
      }
      setFormData(newData);
      !isModified && setIsModified(true);
    }
  };

  /** RENDERS */

  const renderDeleteMsg = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1}>{t("pages.managers.deleteNote")}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.managers.deleteNoteDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionButton
            variant="outlined"
            onClick={() => setIsDelete(false)}
            sx={{ color: (theme) => theme.palette.text.primary, mr: 1 }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" disabled={disableSubmit} onClick={handleDelete}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </>
    );
  };

  const renderDeleteOption = () => {
    if (data && data.private) return "inherit";
    return "none";
  };

  const getDateDescription = (timestamp?: string, user?: IManager) => {
    if (timestamp && user) {
      return `${format(new Date(timestamp), "PP", { locale: getLocale() })} - ${user.name} ${
        user.lastname
      }`;
    }
    return "-";
  };

  const renderStateOption = (text: string, color: "secondary" | "info" | "success") => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StateIcon sx={smIcon} color={color} />
        <Typography>{text}</Typography>
      </Box>
    );
  };

  const getPrivateDescription = () => {
    if (formData.private) {
      return <SecondaryText variant="body2">{t("labels.privacyShiftDescription")}</SecondaryText>;
    } else {
      return <SecondaryText variant="body2">{t("labels.privacyStoreDescription")}</SecondaryText>;
    }
  };

  /** FORM */

  const noteForm = () => {
    return (
      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <TextField
            required
            autoFocus
            fullWidth
            variant="standard"
            id="title"
            label={t("labels.title")}
            placeholder={t("labels.titlePH").toString()}
            value={formData.title || ""}
            onChange={(e) => handleChange(e.target.value, "title")}
            size="small"
            InputProps={{
              readOnly: isEdit,
              style: {
                fontSize: "1.5rem",
                fontWeight: 500,
              },
            }}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="private"
              name="private"
              value={formData.private ? "true" : "false"}
              onChange={(e, value) => handleChange(value, "private")}
            >
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label={
                  <IconAvatar size={40}>
                    <PublicIcon sx={noteTypeColor} />
                  </IconAvatar>
                }
              />
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label={
                  <IconAvatar size={40}>
                    <PrivateIcon sx={noteTypeColor} />
                  </IconAvatar>
                }
              />
            </RadioGroup>
          </FormControl>
          {getPrivateDescription()}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            overflow: "auto",
            maxHeight: isMobile ? "calc(100vh - 400px)" : "calc(100vh - 240px)",
            px: 2,
            paddingTop: "6px !important",
            mt: 3,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 500 }} mb={0.3}>
            {t("labels.description")}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <TextField
            required
            fullWidth
            variant="standard"
            id="description"
            placeholder={t("labels.descriptionPH").toString()}
            value={formData.description || ""}
            onChange={(e) => handleChange(e.target.value, "description")}
            size="small"
            multiline
            rows={3}
            InputProps={{
              readOnly: isEdit && !(isEdit && data?.private),
              disableUnderline: true,
            }}
            sx={{ mb: 2 }}
          />
          {data && isEdit && (
            <CommentList
              item="note"
              itemId={data.id}
              data={data?.comments || []}
              loading={loadingComments}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const noteDetails = () => {
    return (
      <Grid container spacing={3} mt={-1.6} sx={{ height: "fit-content" }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            id="state"
            label={t("labels.state_action")}
            value={formData.state || "pending"}
            onChange={(e) => handleChange(e.target.value, "state")}
            size="small"
            select
          >
            <MenuItem value={"pending"}>
              {renderStateOption(t("labels.pending"), "secondary")}
            </MenuItem>
            <MenuItem value={"active"}>
              {renderStateOption(t("labels.inProgress"), "info")}
            </MenuItem>{" "}
            <MenuItem value={"done"}>{renderStateOption(t("labels.done"), "success")}</MenuItem>
            <Divider sx={{ display: renderDeleteOption() }} />
            <MenuItem value={"delete"} sx={{ display: renderDeleteOption() }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DeleteIcon sx={mdIcon} color="action" />
                {t("labels.deleteNote")}
              </Box>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e, checked) => handleChange(checked, "priority")}
                checked={formData.priority || false}
                color="secondary"
              />
            }
            label={<Typography variant="body1">{t("labels.highPriority")}</Typography>}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            variant="standard"
            id="shift"
            label={t("labels.shift")}
            value={data ? getNoteShiftDescription(data) : getScheduleDescription(filters.shift)}
            size="small"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <ShiftIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            fullWidth
            id="activity"
            variant="standard"
            label={t("labels.activity")}
            value={data ? data.activityTypesId : activityTypeDescription}
            size="small"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <ActivityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {isEdit && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                id="createdDate"
                label={t("labels.createdDate")}
                value={getDateDescription(formData.createdDate, formData.manager)}
                size="small"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="lastUpdate"
                variant="standard"
                label={t("labels.lastUpdate")}
                value={getDateDescription(formData.lastUpdateDate, formData.lastUpdateManager)}
                size="small"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const renderFormContent = () => {
    return (
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item lg={8} xs={12}>
            {noteForm()}
          </Grid>
          <Grid item lg={4} xs={12} display={"grid"}>
            {noteDetails()}
            <Box sx={{ alignSelf: "flex-end", justifySelf: "flex-end", mt: 4 }}>
              <ActionButton
                variant="outlined"
                onClick={() => handleClose()}
                sx={{ color: (theme) => theme.palette.text.primary, mr: 1 }}
              >
                {t("labels.close")}
              </ActionButton>
              <ActionButton type="submit" variant="contained" disabled={disableSubmit}>
                {t("labels.confirm")}
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  return (
    <Box mt={2}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={(event, reason) => handleClose(reason)}
        aria-describedby="description"
        maxWidth={"lg"}
      >
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {isDelete ? renderDeleteMsg() : renderFormContent()}
        </Box>
      </Dialog>
    </Box>
  );
};

export default NoteFormModal;
