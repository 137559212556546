import { AppBar, Box, IconButton, ListItemButton, styled, SxProps, Theme } from "@mui/material";

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const IconButtonRight = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  paddingLeft: theme.spacing(0.5),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  marginLeft: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(2),
  },
}));

export const iconStyle: SxProps<Theme> = {
  opacity: 0.85,
  color: "inherit",
};

export const onlineFx: SxProps = {
  ml: 3,
  color: "#2ECA45",
  "@keyframes onlineGlow": {
    from: { color: "#2ECA45", transform: "scale(1)" },
    to: { color: "#4DF766", transform: "scale(1.08)" },
  },
  animation: "onlineGlow 1s ease-in-out infinite alternate",
};

export const offlineFx: SxProps = {
  mt: 0.5,
  ml: 3,
  width: 27,
  height: 27,
  color: "error.main",
  "@keyframes offlineFx": {
    from: { color: "error.main", transform: "scale(1)" },
    to: { color: "error.light", transform: "scale(1.08)" },
  },
  animation: "offlineFx 1s ease-in-out infinite alternate",
};

export const syncErrorFx: SxProps = {
  mx: 2,
  px: 1,
  mt: 0.3,
  cursor: "pointer",
  boxShadow: 3,
  "@keyframes syncErrorFx": {
    from: { bgcolor: "error.main", transform: "scale(1)" },
    to: { bgcolor: "error.light", transform: "scale(1.02)" },
  },
  animation: "syncErrorFx 500ms ease-in-out infinite alternate",
};

export const StyledListItemButton = styled(ListItemButton)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

export const syncFx: SxProps = {
  mt: 0.5,
  ml: -0.4,
  width: 27,
  height: 27,
  color: "error.main",
  "@keyframes syncGlow": {
    from: { color: "error.main" },
    to: { color: "error.light" },
  },
  animation: "syncGlow 0.5s ease-in-out infinite alternate",
};
