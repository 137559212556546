import { List, ListItem, ListItemIcon, ListItemText, SxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NotificationIcon from "@mui/icons-material/Warning";
import { FlexBox } from "../styles";

const msgStyle: SxProps = {
  textDecoration: "underline",
};

interface Props {
  show?: boolean;
}

const SettingsChangesMsg = ({ show }: Props) => {
  const { t } = useTranslation();

  const messagges = [t("messages.settingsChanges")];

  if (!show) return null;

  return (
    <FlexBox mt={3}>
      <List dense>
        {messagges.map((msg, index) => (
          <ListItem sx={{ py: 0 }} key={index}>
            <ListItemIcon sx={{ minWidth: 24 }}>
              <NotificationIcon color="error" sx={{ width: 16, height: 16 }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" sx={msgStyle}>
                  {msg}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </FlexBox>
  );
};

export default SettingsChangesMsg;
