import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  SxProps,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import AddIcon from "@mui/icons-material/Add";
import CustomChecklistPreview from "../../components/CustomChecklistPreview";
import CustomChecklistCard from "../../components/cards/CustomChecklistCard";
import CustomChecklistFormModal, {
  FetchActivities,
} from "../../components/modals/CustomChecklistFormModal";
import { ICustomChecklistItem } from "../../types";
import { useDispatch } from "react-redux";
import {
  addActivitiesRequested,
  addChecklistRequested,
  deleteActivitiesRequested,
  deleteChecklistRequested,
  updateChecklistRequested,
} from "../../redux/admin/activities/actions";
import { useSelector } from "react-redux";
import {
  customChecklistsDataSelector,
  loadingSelector,
} from "../../redux/admin/activities/selectors";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { appParametersDataSelector } from "../../redux/storePlan/selectors";
import { isAppOnlineSelector } from "../../redux/app/selectors";

const textFx: SxProps = {
  "@keyframes scaleFx": {
    from: { transform: "scale(1)", opacity: 0.8 },
    to: { transform: "scale(1.05)", opacity: 1 },
  },
  animation: "scaleFx 0.6s ease-in-out infinite alternate",
};

const subHeader: SxProps = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
};

const subHeaderMobile: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
};

interface SelectionSkeletonProps {
  actionText: string;
  onClick: () => void;
}

function SelectionSkeleton({ actionText, onClick }: SelectionSkeletonProps) {
  return (
    <Card sx={{ cursor: "pointer" }} onClick={onClick}>
      <CardHeader
        avatar={
          <Avatar>
            <AddIcon />
          </Avatar>
        }
        action={
          <Typography variant="body2" sx={textFx}>
            {actionText.toUpperCase()}
          </Typography>
        }
        title={<Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="80%" />}
      />
    </Card>
  );
}

function ChecklistSkeleton() {
  const count = ["80%", "60%", "60%", "90%", "50%", "80%", "60%"];
  return (
    <Card>
      <CardContent>
        <FlexBox sx={{ justifyContent: "space-between", mb: 2.2 }}>
          <Skeleton animation="wave" height={40} width="30%" />
          <Skeleton animation="wave" height={30} width="5%" />
        </FlexBox>
        {count.map((w, index) => (
          <Skeleton key={`${index}-${w}`} animation="wave" height={15} width={w} sx={{ my: 0.8 }} />
        ))}
      </CardContent>
    </Card>
  );
}

function newInstance(data: ICustomChecklistItem): ICustomChecklistItem {
  const checklist = data.checklist.map((e) => ({ ...e }));
  return { ...data, checklist };
}

function generateMaxItems(max: number): number[] {
  let result: number[] = [];
  let count = 0;
  while (count < max) {
    result.push(count);
    count++;
  }

  return result;
}

const CustomChecklist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isAppOnline = useSelector(isAppOnlineSelector);
  const dataSelector = useSelector(customChecklistsDataSelector);
  const appParameters = useSelector(appParametersDataSelector);
  const isLoading = useSelector(loadingSelector);
  const [checklists, setChecklist] = useState<ICustomChecklistItem[]>([]);
  const [selected, setSelected] = useState<ICustomChecklistItem | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [lastAction, setLastAction] = useState<string | null>(null);

  const { maxChecklists: maxChecklist, maxActivities } = appParameters;
  const count = generateMaxItems(maxChecklist);
  const disableNewChecklist = checklists.length === count.length || !isAppOnline || isLoading;

  function handleSelection(data: ICustomChecklistItem[]) {
    let found = false;
    data.forEach((e) => {
      if (e.enabled) {
        found = true;
        setSelected(e);
      }
    });
    if (!found && data[0]) setSelected(data[0]);
  }

  useEffect(() => {
    const newData = dataSelector.map((e) => newInstance(e));
    setChecklist(newData);

    if (lastAction === "add" && newData.length > 0) {
      setSelected(newData[newData.length - 1]);
    } else if (selected) {
      const index = newData.findIndex((e) => e.id === selected?.id);
      if (index !== -1) setSelected(newData[index]);
    } else handleSelection(newData);

    // eslint-disable-next-line
  }, [dataSelector]);

  const handleSubmit = async (data: ICustomChecklistItem, fetchActivities?: FetchActivities) => {
    if (fetchActivities && data.id) {
      const { newActivities, deletedActivities } = fetchActivities;
      if (newActivities.length > 0) dispatch(addActivitiesRequested(newActivities, data.id));
      if (deletedActivities.length > 0)
        dispatch(deleteActivitiesRequested(deletedActivities, data.id));
    } else {
      dispatch(addChecklistRequested(data));
      setLastAction("add");
    }
    setOpenModal(false);
  };

  const handleCloseForm = () => {
    if (!selected) handleSelection(dataSelector);
    setOpenModal(false);
  };

  const handleDelete = (confirm?: boolean) => {
    if (confirm && selected?.id) {
      dispatch(deleteChecklistRequested(selected.id));
      setSelected(null);
    }
    setOpenDelete(false);
  };

  const handleAction = (action: "edit" | "delete") => {
    switch (action) {
      case "edit":
        setOpenModal(true);
        setLastAction("edit");
        break;
      case "delete":
        setOpenDelete(true);
        setLastAction("delete");
        break;

      default:
        break;
    }
  };

  const handleClick = (item?: ICustomChecklistItem) => {
    if (item) {
      setSelected(item);
    } else {
      setSelected(null);
      setOpenModal(true);
    }
  };

  const handleCheck = (newData: ICustomChecklistItem) => {
    dispatch(updateChecklistRequested(newData));
    setLastAction("edit");
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box p={2} mb={4}>
          <Typography variant="h4" color="primary" textAlign={"center"}>
            {t("pages.customChecklist.title")}
          </Typography>
          <SecondaryText variant="body1" textAlign={"center"}>
            {t("pages.customChecklist.subtitle", { max: count.length })}
          </SecondaryText>
          <SecondaryText variant="body1" mb={5} textAlign={"center"}>
            {t("pages.customChecklist.subtitle2", { max: count.length })}
          </SecondaryText>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={isMobile ? subHeaderMobile : subHeader}>
              <Box>
                <Typography variant="h6" color="primary">
                  {t("pages.customChecklist.description")}
                </Typography>
                {checklists.length === 0 && (
                  <SecondaryText variant="body2">
                    {t("pages.customChecklist.noChecklist")}
                  </SecondaryText>
                )}
                {disableNewChecklist && (
                  <SecondaryText variant="body2">
                    {t("pages.customChecklist.maxChecklist")}
                  </SecondaryText>
                )}
              </Box>
              <ActionButton
                disabled={disableNewChecklist}
                startIcon={<AddIcon />}
                variant="contained"
                loading={isLoading}
                sx={{ pr: 2, display: isMobile ? "none" : "flex" }}
                onClick={() => handleClick()}
              >
                {t("labels.newChecklist")}
              </ActionButton>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Grid container spacing={2}>
                {count.map((i, index) => {
                  if (checklists[i]) {
                    const item = newInstance(checklists[i]);
                    return (
                      <Grid item xs={12} key={i}>
                        <CustomChecklistCard
                          data={item}
                          index={index + 1}
                          isSelected={selected?.id === item.id}
                          disabled={isLoading || !isAppOnline}
                          onClick={handleClick}
                          onChange={handleCheck}
                        />
                      </Grid>
                    );
                  } else
                    return (
                      <Grid item xs={12} key={i}>
                        <SelectionSkeleton
                          actionText={`${t("labels.new")}!`}
                          onClick={() => handleClick()}
                        />
                      </Grid>
                    );
                })}
              </Grid>
            </Grid>
            <Grid item lg={9} xs={12}>
              {checklists.length > 0 && selected ? (
                <CustomChecklistPreview
                  data={selected}
                  disabled={isLoading || !isAppOnline}
                  onClick={handleAction}
                />
              ) : (
                <ChecklistSkeleton />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <CustomChecklistFormModal
        data={selected}
        maxActivities={maxActivities}
        open={openModal}
        onClose={handleCloseForm}
        onSubmit={handleSubmit}
      />
      <ConfirmModal
        open={openDelete}
        onSubmit={handleDelete}
        title={t("pages.customChecklist.deleteChecklist")}
        content={t("pages.customChecklist.deleteChecklistDescription")}
      />
    </>
  );
};

export default CustomChecklist;
