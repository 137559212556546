import {
  ActionType,
  MsalSigninSuccessAction,
  GetPermissionsRequestedAction,
  GetPermissionsDoneAction,
  GetPermissionsErrorAction,
  PermissionsPayload,
  SetAppUserAction,
  UserPayload,
  PreferencesPayload,
  SetPreferencesAction,
} from "./types";

export const msalSigninSuccess = (name: string, username: string): MsalSigninSuccessAction => ({
  type: ActionType.msalSignInSuccess,
  name,
  username,
});

export const setAppUser = (result: UserPayload): SetAppUserAction => {
  return { type: ActionType.setAppUser, payload: result };
};

export const getPermissionsRequested = (): GetPermissionsRequestedAction => {
  return { type: ActionType.getPermissionsRequested };
};

export const getPermissionsDone = (result: PermissionsPayload): GetPermissionsDoneAction => {
  return { type: ActionType.getPermissionsDone, payload: result };
};

export const getPermissionsError = (): GetPermissionsErrorAction => {
  return { type: ActionType.getPermissionsError };
};

export const setPreferences = (result: PreferencesPayload): SetPreferencesAction => {
  return { type: ActionType.setPreferences, payload: result };
};
