import React from "react";
import { Avatar, AvatarProps, styled } from "@mui/material";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

interface Props extends AvatarProps {
  children: React.ReactNode;
  size?: number;
  fontSize?: string;
}

const UserAvatar = ({ children, size, fontSize, ...props }: Props) => {
  const calcSize = size || 44;

  return (
    <StyledAvatar sx={{ width: calcSize, height: calcSize, fontSize }} {...props}>
      {children}
    </StyledAvatar>
  );
};

export default UserAvatar;
