import { shiftApi } from "../config/axiosInstances";
import { PromiseResult, promiseResultWrapper } from "../helpers/functions";
import { IEmployeeLocation, IEmployeePositioning, IQueryParams } from "../types";

export interface GetPositioningResponse {
  data: { data: IEmployeePositioning[] };
}

export interface GetLocationsResponse {
  data: { data: IEmployeeLocation[] };
}

class OrquestServices {
  static async getPositioning(
    params: IQueryParams
  ): Promise<PromiseResult<GetPositioningResponse>> {
    const { businessDate, storeAcronym, country } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.get("/positioning", { params: { businessDate, storeAcronym, country } })
    );
  }

  static async updatePositioning(
    params: IQueryParams,
    body: IEmployeePositioning
  ): Promise<PromiseResult<IEmployeePositioning>> {
    const { storeAcronym, country } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.put(`/positioning/${body.personId}`, { params: { storeAcronym, country }, body })
    );
  }

  static async getLocations(params: IQueryParams): Promise<PromiseResult<GetLocationsResponse>> {
    const { country } = params;
    return promiseResultWrapper<any>(() => shiftApi.get("/locations", { params: { country } }));
  }
}

export default OrquestServices;
