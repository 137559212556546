import { takeLatest, call, put } from "redux-saga/effects";
import { PromiseResult } from "../../helpers/functions";
import { getChangelogsReportDone, getChangelogsReportError } from "./actions";
import { ActionType, GetChangelogsReportRequestedAction } from "./types";
import StorePlanServices, { ChangelogsReportResponse } from "../../services/StorePlanServices";

function* getChangelogsReportSagas(action: GetChangelogsReportRequestedAction) {
  try {
    const {
      result: getChangelogsReportResponse,
      hasError,
      error,
    }: PromiseResult<ChangelogsReportResponse> = yield call(StorePlanServices.getChangelogsReport);
    if (hasError || !getChangelogsReportResponse) throw error;
    yield put(getChangelogsReportDone({ changelogsReport: getChangelogsReportResponse.data.data }));
  } catch (error) {
    yield put(getChangelogsReportError());
  }
}

const appSagas = [takeLatest(ActionType.getChangelogsReportRequested, getChangelogsReportSagas)];

export default appSagas;
