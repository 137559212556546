import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ISegmentItem } from "../types";
import NoRowsOverlay from "./NoRowsOverlay";

function getGcDif(row: ISegmentItem) {
  const value = row.gcReal - row.gcProy;
  if (value > 0) return value;
  else return <span style={{ color: "red" }}>{value}</span>;
}

interface Props {
  rows: ISegmentItem[];
}

/**
 *
 * @GcDif resta de gcReal - gcProy
 * @IPReal gcReal / hsReal
 * @IPProy gcProy / hsPry
 */

const columns: GridColDef<ISegmentItem>[] = [
  { field: "hour", headerName: "Hora", flex: 1, renderCell: (params) => `${params.row.hour}:00` },
  {
    field: "gcProy",
    headerName: "Gc's proy.",
    type: "number",
    flex: 1,
  },
  {
    field: "gcReal",
    headerName: "Gc's Real",
    type: "number",
    flex: 1,
  },
  {
    field: "gcDif",
    headerName: "Gc's Dif.",
    type: "number",
    flex: 1,
    renderCell: (params) => getGcDif(params.row),
  },
  {
    field: "hsReal",
    headerName: "Hs Real",
    type: "number",
    flex: 1,
  },
  {
    field: "hsProy",
    headerName: "Hs Proy.",
    type: "number",
    flex: 1,
  },
  {
    field: "ipReal",
    headerName: "IP Real",
    type: "number",
    flex: 1,
  },
  {
    field: "ipProy",
    headerName: "IP Proy.",
    type: "number",
    flex: 1,
  },
];

const SalesTable = ({ rows }: Props) => {
  return (
    <DataGrid
      autoHeight
      density="compact"
      rows={rows}
      columns={columns}
      getRowId={(row) => `${row.segment}-${row.hour}`}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 6,
          },
        },
        sorting: {
          sortModel: [{ field: "hour", sort: "desc" }],
        },
      }}
      pageSizeOptions={[6, 12, 24]}
      disableRowSelectionOnClick
      slots={{
        noRowsOverlay: () => <NoRowsOverlay />,
      }}
      sx={{ "--DataGrid-overlayHeight": "216px" }}
    />
  );
};

export default SalesTable;
