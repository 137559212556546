import { Action } from "@reduxjs/toolkit";
import { IActivityItem, ICustomChecklistItem } from "../../../types";
import { ActionPayload } from "../../actions";
import { RemoteResource } from "../../utils";

export interface ActivitiesState {
  checklists: RemoteResource<ICustomChecklistItem[]>;
  loading: boolean;
}

export enum ActionType {
  getChecklistsRequested = "admin/get-checklists-requested",
  getChecklistsDone = "admin/get-checklists-done",
  getChecklistsError = "admin/get-checklists-error",
  addChecklistRequested = "admin/add-checklist-requested",
  addChecklistDone = "admin/add-checklist-done",
  addChecklistError = "admin/add-checklist-error",
  updateChecklistRequested = "admin/update-checklist-requested",
  updateChecklistDone = "admin/update-checklist-done",
  updateChecklistError = "admin/update-checklist-error",
  deleteChecklistRequested = "admin/delete-checklist-requested",
  deleteChecklistDone = "admin/delete-checklist-done",
  deleteChecklistError = "admin/delete-checklist-error",
  addActivitiesRequested = "admin/add-activities-requested",
  addActivitiesDone = "admin/add-activities-done",
  addActivitiesError = "admin/add-activities-error",
  deleteActivitiesRequested = "admin/delete-activities-requested",
  deleteActivitiesDone = "admin/delete-activities-done",
  deleteActivitiesError = "admin/delete-activities-error",
  clearData = "admin/clear-data",
}

/** CHECKLIST */

export interface GetChecklistsPayload {
  checklists: ICustomChecklistItem[];
}

export interface GetChecklistsRequestedAction extends Action<ActionType.getChecklistsRequested> {}

export interface GetChecklistsDoneAction
  extends ActionPayload<ActionType.getChecklistsDone, GetChecklistsPayload> {}

export interface GetChecklistsErrorAction extends Action<ActionType.getChecklistsError> {}

export interface AddChecklistPayload {
  checklist: ICustomChecklistItem;
}
export interface AddChecklistRequestedAction
  extends ActionPayload<ActionType.addChecklistRequested, AddChecklistPayload> {}

export interface AddChecklistDoneAction
  extends ActionPayload<ActionType.addChecklistDone, AddChecklistPayload> {}

export interface AddChecklistErrorAction extends Action<ActionType.addChecklistError> {}

export interface UpdateChecklistPayload {
  checklist: Partial<ICustomChecklistItem>;
  ignoreFetch?: boolean;
}
export interface UpdateChecklistRequestedAction
  extends ActionPayload<ActionType.updateChecklistRequested, UpdateChecklistPayload> {}

export interface UpdateChecklistDoneAction
  extends ActionPayload<ActionType.updateChecklistDone, UpdateChecklistPayload> {}

export interface UpdateChecklistErrorAction extends Action<ActionType.updateChecklistError> {}

export interface DeleteChecklistPayload {
  id: number;
}
export interface DeleteChecklistRequestedAction
  extends ActionPayload<ActionType.deleteChecklistRequested, DeleteChecklistPayload> {}

export interface DeleteChecklistDoneAction
  extends ActionPayload<ActionType.deleteChecklistDone, DeleteChecklistPayload> {}

export interface DeleteChecklistErrorAction extends Action<ActionType.deleteChecklistError> {}

/** ACTIVITIES */

export interface AddActivitiesRequestPayload {
  activities: Partial<IActivityItem>[];
  customChecklistId: number;
}

export interface AddActivitiesPayload {
  activities: Partial<IActivityItem>[];
}

export interface AddActivitiesRequestedAction
  extends ActionPayload<ActionType.addActivitiesRequested, AddActivitiesRequestPayload> {}

export interface AddActivitiesDoneAction
  extends ActionPayload<ActionType.addActivitiesDone, AddActivitiesPayload> {}

export interface AddActivitiesErrorAction extends Action<ActionType.addActivitiesError> {}

export interface DeleteActivitiesPayload {
  ids: number[];
  customChecklistId: number;
}
export interface DeleteActivitiesRequestedAction
  extends ActionPayload<ActionType.deleteActivitiesRequested, DeleteActivitiesPayload> {}

export interface DeleteActivitiesDoneAction
  extends ActionPayload<ActionType.deleteActivitiesDone, DeleteActivitiesPayload> {}

export interface DeleteActivitiesErrorAction extends Action<ActionType.deleteActivitiesError> {}

/** CLEAR DATA */

export interface GetClearDataAction extends Action<ActionType.clearData> {}

/** ACTION TYPES */

export type Actions =
  | GetChecklistsRequestedAction
  | GetChecklistsDoneAction
  | GetChecklistsErrorAction
  | AddChecklistRequestedAction
  | AddChecklistDoneAction
  | AddChecklistErrorAction
  | UpdateChecklistRequestedAction
  | UpdateChecklistDoneAction
  | UpdateChecklistErrorAction
  | DeleteChecklistRequestedAction
  | DeleteChecklistDoneAction
  | DeleteChecklistErrorAction
  | AddActivitiesRequestedAction
  | AddActivitiesDoneAction
  | AddActivitiesErrorAction
  | DeleteActivitiesRequestedAction
  | DeleteActivitiesDoneAction
  | DeleteActivitiesErrorAction
  | GetClearDataAction;
