import { RemoteResourceState } from "../../utils";
import { NotesState } from "./types";

interface State {
  notes: NotesState;
}

export const notesSelector = (state: State) => state.notes.notes;
export const notesDataSelector = (state: State) => state.notes.notes.data || [];
export const noteModalOpenSelector = (state: State) =>
  state.notes.noteModalOpen;
export const selectedNoteSelector = (state: State) => state.notes.selectedNote;
export const requestingNotesSelector = (state: State) =>
  state.notes.notes.state === RemoteResourceState.REMOTE_RESOURCE_LOADING;
export const loadingNoteSelector = (state: State) => state.notes.loadingNote;
export const loadingCommentSelector = (state: State) =>
  state.notes.loadingComment;
export const notesErrorSelector = (state: State) => state.notes.notesError;
export const commentErrorSelector = (state: State) => state.notes.commentError;
export const lastRequestSelector = (state: State) => state.notes.lastRequest;
