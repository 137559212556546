import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { dailyTotalsDataSelector, formattedSegmentsSelector } from "../../redux/sales/selectors";
import DailyTotalsDashboard from "../../components/DailyTotalsDashboard";
import SegmentsDataDashboard from "../../components/SegmentsDataDashboard";

const SalesDashboard = () => {
  const dailyTotalsSelector = useSelector(dailyTotalsDataSelector);
  const {
    segments,
    currentSegments,
    categories,
    lineGcsSeries,
    mixedGcsSeries,
    mixedHsSeries,
    mixedIpSeries,
  } = useSelector(formattedSegmentsSelector);

  return (
    <Container maxWidth={false}>
      <DailyTotalsDashboard
        categories={categories}
        currentSegments={currentSegments}
        dailyTotals={dailyTotalsSelector}
        series={lineGcsSeries}
      />
      <div style={{ marginBlock: "1%" }} />
      <SegmentsDataDashboard
        categories={categories}
        segments={segments}
        gcsSeries={mixedGcsSeries}
        hsSeries={mixedHsSeries}
        ipSeries={mixedIpSeries}
      />
    </Container>
  );
};

export default SalesDashboard;
