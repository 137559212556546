import { Action } from "@reduxjs/toolkit";
import { IManager } from "../../../types";
import { ActionPayload } from "../../actions";
import { RemoteResource } from "../../utils";

export interface AdminState {
  managers: RemoteResource<IManager[]>;
  loading: boolean;
}

export enum ActionType {
  getManagersRequested = "admin/get-managers-requested",
  getManagersDone = "admin/get-managers-done",
  getManagersError = "admin/get-managers-error",
  addManagerRequested = "admin/add-manager-requested",
  addManagerDone = "admin/add-manager-done",
  addManagerError = "admin/add-manager-error",
  updateManagerRequested = "admin/update-manager-requested",
  updateManagerDone = "admin/update-manager-done",
  updateManagerError = "admin/update-manager-error",
  deleteManagerRequested = "admin/delete-manager-requested",
  deleteManagerDone = "admin/add-delete-done",
  deleteManagerError = "admin/add-delete-error",
  clearData = "admin/clear-data",
}

/** GET MANAGERS */

export interface GetManagersPayload {
  managers: IManager[];
}

export interface GetManagersRequestedAction extends Action<ActionType.getManagersRequested> {}

export interface GetManagersDoneAction
  extends ActionPayload<ActionType.getManagersDone, GetManagersPayload> {}

export interface GetManagersErrorAction extends Action<ActionType.getManagersError> {}

/** ADD MANAGER */

export interface AddManagerPayload {
  manager: IManager;
}
export interface AddManagerRequestedAction
  extends ActionPayload<ActionType.addManagerRequested, AddManagerPayload> {}

export interface AddManagerDoneAction
  extends ActionPayload<ActionType.addManagerDone, AddManagerPayload> {}

export interface AddManagerErrorAction extends Action<ActionType.addManagerError> {}

/** UPDATE MANAGER */

export interface UpdateManagerPayload {
  manager: IManager;
}
export interface UpdateManagerRequestedAction
  extends ActionPayload<ActionType.updateManagerRequested, UpdateManagerPayload> {}

export interface UpdateManagerDoneAction
  extends ActionPayload<ActionType.updateManagerDone, UpdateManagerPayload> {}

export interface UpdateManagerErrorAction extends Action<ActionType.updateManagerError> {}

/** DELETE MANAGER */

export interface DeleteManagerPayload {
  identifier: number;
}
export interface DeleteManagerRequestedAction
  extends ActionPayload<ActionType.deleteManagerRequested, DeleteManagerPayload> {}

export interface DeleteManagerDoneAction
  extends ActionPayload<ActionType.deleteManagerDone, DeleteManagerPayload> {}

export interface DeleteManagerErrorAction extends Action<ActionType.deleteManagerError> {}

/** CLEAR DATA */

export interface GetClearDataAction extends Action<ActionType.clearData> {}

/** ACTION TYPES */

export type Actions =
  | GetManagersRequestedAction
  | GetManagersDoneAction
  | GetManagersErrorAction
  | AddManagerRequestedAction
  | AddManagerDoneAction
  | AddManagerErrorAction
  | UpdateManagerRequestedAction
  | UpdateManagerDoneAction
  | UpdateManagerErrorAction
  | DeleteManagerRequestedAction
  | DeleteManagerDoneAction
  | DeleteManagerErrorAction
  | GetClearDataAction;
