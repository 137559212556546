import { Action } from "@reduxjs/toolkit";
import { RemoteResource } from "../utils";
import {
  IActivityTypes,
  IAppParameters,
  IHourlyReviewTypes,
  IObjectivesTypes,
  IStorePlan,
} from "../../types";
import { ActionPayload } from "../actions";

export interface StorePlanState {
  storePlan: RemoteResource<IStorePlan | null>;
  activityTypes: RemoteResource<IActivityTypes>;
  objectivesTypes: RemoteResource<IObjectivesTypes>;
  hourlyReviewTypes: RemoteResource<IHourlyReviewTypes | null>;
  appParameters: RemoteResource<IAppParameters>;
  loadingStorePlan: boolean;
  loadingObjectives: boolean;
  loadingHourlyReview: boolean;
  loadingMasterdata: boolean;
}

export enum ActionType {
  getStorePlanRequested = "storePlan/get-store-plan-requested",
  getStorePlanDone = "storePlan/get-store-plan-done",
  getStorePlanError = "storePlan/get-store-plan-error",
  setStorePlan = "storePlan/set-store-plan-error",
  getActivityTypesRequested = "storePlan/get-objectives-types-requested",
  getActivityTypesDone = "storePlan/get-objectives-types-done",
  getActivityTypesError = "storePlan/get-objectives-types-error",
  getObjectivesTypesRequested = "storePlan/get-activity-types-requested",
  getObjectivesTypesDone = "storePlan/get-activity-types-done",
  getObjectivesTypesError = "storePlan/get-activity-types-error",
  getHourlyReviewTypesRequested = "storePlan/get-hourly-review-types-requested",
  getHourlyReviewTypesDone = "storePlan/get-hourly-review-types-done",
  getHourlyReviewTypesError = "storePlan/get-hourly-review-types-error",
  getAppParametersRequested = "storePlan/get-app-parameters-requested",
  getAppParametersDone = "storePlan/get-app-parameters-done",
  getAppParametersError = "storePlan/get-app-parameters-error",
}

/** STORE PLAN */

export interface GetStorePlanPayload {
  storePlan: IStorePlan;
}

export interface GetStorePlanRequestedAction
  extends ActionPayload<ActionType.getStorePlanRequested, { activityTypesId: string }> {}

export interface GetStorePlanDoneAction
  extends ActionPayload<ActionType.getStorePlanDone, GetStorePlanPayload> {}

export interface GetStorePlanErrorAction extends Action<ActionType.getStorePlanError> {}

export interface SetStorePlanAction
  extends ActionPayload<ActionType.setStorePlan, { storePlan: IStorePlan | null }> {}

/** GET ACTIVITY TYPES */

export interface GetActivityTypesPayload {
  activityTypes: IActivityTypes;
}

export interface GetActivityTypesRequestedAction
  extends Action<ActionType.getActivityTypesRequested> {}

export interface GetActivityTypesDoneAction
  extends ActionPayload<ActionType.getActivityTypesDone, GetActivityTypesPayload> {}

export interface GetActivityTypesErrorAction extends Action<ActionType.getActivityTypesError> {}

/** GET OBJECTIVES TYPES */

export interface GetObjectivesTypesPayload {
  objectivesTypes: IObjectivesTypes;
}

export interface GetObjectivesTypesRequestedAction
  extends Action<ActionType.getObjectivesTypesRequested> {}

export interface GetObjectivesTypesDoneAction
  extends ActionPayload<ActionType.getObjectivesTypesDone, GetObjectivesTypesPayload> {}

export interface GetObjectivesTypesErrorAction extends Action<ActionType.getObjectivesTypesError> {}

/** GET HOURLY REVIEW TYPES */

export interface GetHourlyReviewTypesPayload {
  hourlyReviewTypes: IHourlyReviewTypes;
}

export interface GetHourlyReviewTypesRequestedAction
  extends Action<ActionType.getHourlyReviewTypesRequested> {}

export interface GetHourlyReviewTypesDoneAction
  extends ActionPayload<ActionType.getHourlyReviewTypesDone, GetHourlyReviewTypesPayload> {}

export interface GetHourlyReviewTypesErrorAction
  extends Action<ActionType.getHourlyReviewTypesError> {}

/** GET APP PARAMETERS */

export interface GetAppParametersPayload {
  appParameters: IAppParameters;
}

export interface GetAppParametersRequestedAction
  extends Action<ActionType.getAppParametersRequested> {}

export interface GetAppParametersDoneAction
  extends ActionPayload<ActionType.getAppParametersDone, GetAppParametersPayload> {}

export interface GetAppParametersErrorAction extends Action<ActionType.getAppParametersError> {}

/** ACTIONS TYPES */

export type Actions =
  | GetStorePlanRequestedAction
  | GetStorePlanDoneAction
  | GetStorePlanErrorAction
  | SetStorePlanAction
  | GetActivityTypesRequestedAction
  | GetActivityTypesDoneAction
  | GetActivityTypesErrorAction
  | GetObjectivesTypesRequestedAction
  | GetObjectivesTypesDoneAction
  | GetObjectivesTypesErrorAction
  | GetHourlyReviewTypesRequestedAction
  | GetHourlyReviewTypesDoneAction
  | GetHourlyReviewTypesErrorAction
  | GetAppParametersRequestedAction
  | GetAppParametersDoneAction
  | GetAppParametersErrorAction;
