import { IHourlyReviewState, IHourlyReviewItem } from "../../../types";
import {
  ActionType,
  UpdateHourlyReviewDoneAction,
  UpdateHourlyReviewErrorAction,
  UpdateHourlyReviewPayload,
  UpdateHourlyReviewRequestedAction,
  SetHourlyReviewStateAction,
  SetHourlyReviewAction,
} from "./types";

/** HOURLY REVIEW */

export const updateHourlyReviewRequested = (
  hourlyReviews: IHourlyReviewItem
): UpdateHourlyReviewRequestedAction => {
  return { type: ActionType.updateHourlyReviewRequested, payload: { hourlyReviews } };
};

export const updateHourlyReviewDone = (
  result: UpdateHourlyReviewPayload
): UpdateHourlyReviewDoneAction => {
  return { type: ActionType.updateHourlyReviewDone, payload: result };
};

export const updateHourlyReviewError = (
  result: UpdateHourlyReviewPayload
): UpdateHourlyReviewErrorAction => {
  return { type: ActionType.updateHourlyReviewError, payload: result };
};

export const setHourlyReview = (result: {
  value: IHourlyReviewItem[];
}): SetHourlyReviewAction => {
  return { type: ActionType.setHourlyReview, payload: result };
};

export const setHourlyReviewState = (result: {
  value: IHourlyReviewState;
}): SetHourlyReviewStateAction => {
  return { type: ActionType.setHourlyReviewState, payload: result };
};
