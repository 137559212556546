import { Action } from "@reduxjs/toolkit";
import { IManager, IUserPreferences } from "../../types";
import { ActionPayload } from "../actions";
import { RemoteResource } from "../utils";

export interface UserState {
  appUser: IManager | undefined;
  isAuthenticated: boolean;
  info: { username: string; name: string } | undefined;
  permissions: RemoteResource<string[]>;
  preferences: IUserPreferences | null;
}

export enum ActionType {
  msalSignInSuccess = "user/msalSignInSuccess",
  setAppUser = "user/set-app-user",
  getPermissionsRequested = "user/get-permissions-requested",
  getPermissionsDone = "user/get-permissions-done",
  getPermissionsError = "user/get-permissions-error",
  setPreferences = "user/set-preferences",
}

export type MsalSigninSuccessAction = Action<ActionType.msalSignInSuccess> & {
  username: string;
  name: string;
};

export interface UserPayload {
  appUser: IManager;
}

export interface SetAppUserAction extends ActionPayload<ActionType.setAppUser, UserPayload> {}

export interface PermissionsPayload {
  permissions: string[];
}

export interface GetPermissionsRequestedAction extends Action<ActionType.getPermissionsRequested> {}

export interface GetPermissionsDoneAction
  extends ActionPayload<ActionType.getPermissionsDone, PermissionsPayload> {}

export interface GetPermissionsErrorAction extends Action<ActionType.getPermissionsError> {}

export interface PreferencesPayload {
  preferences: IUserPreferences;
}

export interface SetPreferencesAction
  extends ActionPayload<ActionType.setPreferences, PreferencesPayload> {}

export type Actions =
  | MsalSigninSuccessAction
  | SetAppUserAction
  | GetPermissionsRequestedAction
  | GetPermissionsDoneAction
  | GetPermissionsErrorAction
  | SetPreferencesAction;
