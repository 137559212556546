import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects";
import { PromiseResult, getQueryParams } from "../../../helpers/functions";
import ActivityServices, {
  GetObjectivesResponse,
  ObjectiveCommentsResponse,
  ObjectivesResponse,
} from "../../../services/ActivityServices";
import { AlertCode, IHttpRequestError, IManager, IQueryParams, IStorePlan } from "../../../types";
import { setSnackbar } from "../../app/actions";
import {
  addCommentDone,
  addCommentError,
  addObjectiveDone,
  addObjectiveError,
  deleteCommentDone,
  deleteCommentError,
  deleteObjectiveDone,
  deleteObjectiveError,
  getObjectivesDone,
  getObjectivesError,
  getSosDtReportDone,
  getSosDtReportError,
  getSosFcReportDone,
  getSosFcReportError,
  getSosMfyReportDone,
  getSosMfyReportError,
  updateCommentDone,
  updateCommentError,
  updateObjectiveDone,
  updateObjectiveError,
} from "./actions";
import {
  ActionType,
  AddCommentRequestedAction,
  AddObjectiveRequestedAction,
  DeleteCommentRequestedAction,
  DeleteObjectiveRequestedAction,
  GetObjectivesRequestedAction,
  GetSosDtReportRequestedAction,
  GetSosFcReportRequestedAction,
  GetSosMfyReportRequestedAction,
  UpdateCommentRequestedAction,
  UpdateObjectiveRequestedAction,
} from "./types";
import { filtersSelector } from "../../admin/masterdata/selectors";
import { appUserSelector } from "../../user/selectors";
import { storePlanDataSelector } from "../../storePlan/selectors";
import { ApiResponse } from "../../../services/interfaces";
import SoSServices, {
  ISoSDtReportResponse,
  ISoSFcReportResponse,
  ISoSMfyReportResponse,
} from "../../../services/SoSService";

function* getObjectivesSagas(action: GetObjectivesRequestedAction) {
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    storePlanId: storePlan.storePlanId,
  };
  try {
    const {
      result: getObjectivesResponse,
      hasError,
      error,
    }: PromiseResult<GetObjectivesResponse> = yield call(ActivityServices.getObjectives, params);
    if (hasError || !getObjectivesResponse) throw error;
    yield put(getObjectivesDone({ objective: getObjectivesResponse.data }));
  } catch (error) {
    yield put(getObjectivesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* addObjectiveSagas(action: AddObjectiveRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    username: user.username,
    storePlanId: storePlan.storePlanId,
  };
  try {
    const {
      result: addObjectiveResponse,
      hasError,
      error,
    }: PromiseResult<ObjectivesResponse> = yield call(
      ActivityServices.addObjective,
      action.payload.objective,
      params
    );
    if (hasError || !addObjectiveResponse) throw error;
    yield put(addObjectiveDone({ objective: addObjectiveResponse.data }));
  } catch (error) {
    yield put(addObjectiveError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateObjectiveSagas(action: UpdateObjectiveRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  try {
    const {
      result: updateObjectiveResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.updateObjective,
      action.payload.objective,
      params
    );
    if (hasError || !updateObjectiveResponse) throw error;
    yield put(updateObjectiveDone({ objective: action.payload.objective }));
  } catch (error) {
    yield put(updateObjectiveError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteObjectiveSagas(action: DeleteObjectiveRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const storePlan: IStorePlan = yield select(storePlanDataSelector);
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
    username: user.username,
    storePlanId: storePlan.storePlanId,
  };
  try {
    const {
      result: deleteObjectiveResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.deleteObjective,
      action.payload.objective.id!,
      params
    );
    if (hasError || !deleteObjectiveResponse) throw error;
    yield put(deleteObjectiveDone({ objective: action.payload.objective }));
  } catch (error) {
    yield put(deleteObjectiveError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* addCommentSagas(action: AddCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));
  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
  };
  try {
    const {
      result: addCommentResponse,
      hasError,
      error,
    }: PromiseResult<ObjectiveCommentsResponse> = yield call(
      ActivityServices.addObjectiveComment,
      action.payload.comment,
      params
    );
    if (hasError || !addCommentResponse) throw error;
    yield put(addCommentDone({ comment: addCommentResponse.data }));
  } catch (error) {
    yield put(addCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateCommentSagas(action: UpdateCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  try {
    const {
      result: updateCommentResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.updateObjectiveComment,
      action.payload.comment,
      params
    );
    if (hasError || !updateCommentResponse) throw error;
    yield put(updateCommentDone({ comment: action.payload.comment }));
  } catch (error) {
    yield put(updateCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteCommentSagas(action: DeleteCommentRequestedAction) {
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  try {
    const {
      result: deleteCommentResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      ActivityServices.deleteObjectiveComment,
      action.payload.comment,
      params
    );
    if (hasError || !deleteCommentResponse) throw error;
    yield put(deleteCommentDone({ comment: action.payload.comment }));
  } catch (error) {
    yield put(deleteCommentError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.COMMENTS_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getSosDtReportSagas(action: GetSosDtReportRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getSOSReportResponse,
      hasError,
      error,
    }: PromiseResult<ISoSDtReportResponse> = yield call(SoSServices.getSoSReport, params, "dt");
    if (hasError || !getSOSReportResponse) throw error;
    yield put(getSosDtReportDone({ report: getSOSReportResponse.data.result_totals[0] }));
  } catch (error) {
    yield put(getSosDtReportError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getSosFcReportSagas(action: GetSosFcReportRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getSOSReportResponse,
      hasError,
      error,
    }: PromiseResult<ISoSFcReportResponse> = yield call(SoSServices.getSoSReport, params, "fc");
    if (hasError || !getSOSReportResponse) throw error;
    yield put(getSosFcReportDone({ report: getSOSReportResponse.data.result_totals[0] }));
  } catch (error) {
    yield put(getSosFcReportError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* getSosMfyReportSagas(action: GetSosMfyReportRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getSOSReportResponse,
      hasError,
      error,
    }: PromiseResult<ISoSMfyReportResponse> = yield call(SoSServices.getSoSReport, params, "mfy");
    if (hasError || !getSOSReportResponse) throw error;
    yield put(getSosMfyReportDone({ report: getSOSReportResponse.data.result_totals[0] }));
  } catch (error) {
    yield put(getSosMfyReportError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.OBJECTIVES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const activitySagas = [
  takeLatest(ActionType.getObjectivesRequested, getObjectivesSagas),
  takeEvery(ActionType.addObjectiveRequested, addObjectiveSagas),
  takeEvery(ActionType.updateObjectiveRequested, updateObjectiveSagas),
  takeEvery(ActionType.deleteObjectiveRequested, deleteObjectiveSagas),
  takeEvery(ActionType.addCommentRequested, addCommentSagas),
  takeEvery(ActionType.updateCommentRequested, updateCommentSagas),
  takeEvery(ActionType.deleteCommentRequested, deleteCommentSagas),
  takeEvery(ActionType.getSosDtReportRequested, getSosDtReportSagas),
  takeEvery(ActionType.getSosFcReportRequested, getSosFcReportSagas),
  takeEvery(ActionType.getSosMfyReportRequested, getSosMfyReportSagas),
];

export default activitySagas;
