import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ShiftIcon from "@mui/icons-material/PendingActions";
import NoShiftIcon from "@mui/icons-material/EventBusy";
import LoginIcon from "@mui/icons-material/Login";
import { ActionButton, FlexBox } from "../../styles";
import { DAY_OF_WEEK } from "../../helpers/constants";
import { IScheduleItem, IShiftSchedules } from "../../types";
import { useTranslation } from "react-i18next";
import { getScheduleDescription } from "../../helpers/functions";
import IconAvatar from "../../components/IconAvatar";
import { useDispatch } from "react-redux";
import { setCurrentSchedule } from "../../redux/admin/schedules/actions";

const StyleTitle = styled(Typography)({
  fontWeight: 600,
});

const StyleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
  },
});

function haveShifts(data: IScheduleItem[]) {
  let result = false;
  data.forEach((e) => {
    if (e.enabled) result = true;
  });

  return result;
}

interface Props {
  onChange: (item: IScheduleItem) => void;
  onSubmit: () => void;
  selectedDate: Date | null;
  schedules: IShiftSchedules;
  selectedShift?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ShiftSelector = ({
  onChange,
  onSubmit,
  selectedDate,
  schedules,
  selectedShift,
  disabled,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:480px)");

  const [selectedSchedule, setSelectedSchedule] = useState<IScheduleItem[]>([]);

  const noSchedules =
    selectedSchedule.length === 0 || !selectedSchedule[0]?.weekday || !haveShifts(selectedSchedule);

  useEffect(() => {
    const currentDay = selectedDate ? DAY_OF_WEEK[selectedDate.getDay()] : "";
    const newSchedule: IScheduleItem[] = schedules[currentDay] || [];
    setSelectedSchedule(newSchedule);
    dispatch(setCurrentSchedule({ schedule: newSchedule }));
    // eslint-disable-next-line
  }, [selectedDate, schedules]);

  const noSchedule = (description: string) => {
    return (
      <FlexBox mt={2}>
        <ListItemIcon>
          <IconAvatar>
            <NoShiftIcon sx={{ width: 35, height: 35 }} color="primary" />
          </IconAvatar>
        </ListItemIcon>
        <ListItemText
          sx={{ ml: 1 }}
          primary={<StyleTitle>{t("pages.home.shifts.noShiftsTitle")}</StyleTitle>}
          secondary={description}
        />
      </FlexBox>
    );
  };

  if (!selectedDate) return noSchedule(t("pages.home.calendar.title"));
  if (noSchedules) return noSchedule(t("pages.home.shifts.noShifts"));

  return (
    <List>
      {selectedSchedule.map(
        (item) =>
          item.enabled &&
          item.weekday &&
          item.shiftTypeId !== "RH" && (
            <React.Fragment key={item.shiftTypeId}>
              <ListItem
                alignItems="center"
                secondaryAction={
                  <ActionButton
                    variant="contained"
                    onClick={onSubmit}
                    startIcon={<LoginIcon />}
                    sx={{ px: isMobile ? 0 : 2, minWidth: 45 }}
                    loading={selectedShift === item.shiftTypeId && loading}
                    disabled={selectedShift !== item.shiftTypeId || loading || disabled}
                  >
                    {!isMobile && t("labels.start")}
                  </ActionButton>
                }
                sx={{ paddingLeft: 0 }}
                disabled={loading || disabled}
              >
                <StyleBox onClick={() => onChange(item)}>
                  <ListItemIcon>
                    <Radio
                      checked={selectedShift === item.shiftTypeId}
                      value={item.shiftTypeId}
                      name={item.shiftTypeId}
                      inputProps={{ "aria-label": item.shiftTypeId }}
                    />
                    <IconAvatar>
                      <ShiftIcon sx={{ width: 35, height: 35 }} color="primary" />
                    </IconAvatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{ ml: 1 }}
                    primary={<StyleTitle>{getScheduleDescription(item)}</StyleTitle>}
                    secondary={`${item.shiftStart} - ${item.shiftEnd}`}
                  />
                </StyleBox>
              </ListItem>
              <Divider component="li" sx={{ marginX: 2 }} />
            </React.Fragment>
          )
      )}
    </List>
  );
};

export default ShiftSelector;
