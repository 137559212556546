import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDailyTotalsRequested } from "../redux/sales/actions";
import { filtersSelector } from "../redux/admin/masterdata/selectors";
import { isAppOnlineSelector } from "../redux/app/selectors";
import { storePlanIdSelector } from "../redux/storePlan/selectors";
import { permissionsDataSelector } from "../redux/user/selectors";
import { AppPermissions } from "../types";
import {
  getSosDtReportRequested,
  getSosFcReportRequested,
  getSosMfyReportRequested,
} from "../redux/activities/objectives/actions";
import { invokedServicesSelector } from "../redux/activities/objectives/selectors";
import { isAfter } from "date-fns";
import { isBetweenShift } from "../helpers/functions";
import AppStorage from "../services/AppStorage";

const ApiRequestsTimer = () => {
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const permissions = useSelector(permissionsDataSelector);
  const storePlanId = useSelector(storePlanIdSelector);
  const filters = useSelector(filtersSelector);
  const invokedServices = useSelector(invokedServicesSelector);

  const [sosReportRequested, setSosReportRequested] = React.useState(false);

  const objectivesInterval = [0, 30];
  const salesInterval = [0, 15, 30, 45];
  const isValidBusinessDate =
    filters.businessDate !== undefined && !isAfter(new Date(filters.businessDate), new Date());

  const disableSalesFetch =
    !storePlanId ||
    !isValidBusinessDate ||
    !permissions.includes(AppPermissions.SALES) ||
    !isAppOnline;

  const disableObjFetch = !storePlanId || !isValidBusinessDate || !isAppOnline;

  React.useEffect(() => {
    setSosReportRequested(false);
  }, [storePlanId]);

  React.useEffect(() => {
    if (!disableObjFetch && !sosReportRequested && invokedServices) {
      setSosReportRequested(true);
      invokedServices.includes("SOS_DT") && dispatch(getSosDtReportRequested());
      invokedServices.includes("SOS_FC") && dispatch(getSosFcReportRequested());
      invokedServices.includes("SOS_MFY") && dispatch(getSosMfyReportRequested());
      AppStorage.setSosRequesteDate(new Date().toISOString());
    }

    const timer = setInterval(() => {
      const now = new Date();
      const minutes = now.getMinutes();
      /** Objectives */
      if (
        objectivesInterval.includes(minutes) &&
        !disableObjFetch &&
        invokedServices?.some((e) => invokedServices.includes(e)) &&
        isBetweenShift(now, filters.businessDate, filters.shift)
      ) {
        invokedServices.includes("SOS_DT") && dispatch(getSosDtReportRequested());
        invokedServices.includes("SOS_FC") && dispatch(getSosFcReportRequested());
        invokedServices.includes("SOS_MFY") && dispatch(getSosMfyReportRequested());
        AppStorage.setSosRequesteDate(now.toISOString());
      }
      /** Sales */
      if (salesInterval.includes(minutes) && !disableSalesFetch) {
        dispatch(getDailyTotalsRequested());
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [
    disableSalesFetch,
    disableObjFetch,
    objectivesInterval,
    invokedServices,
    sosReportRequested,
    filters.shift,
    filters.businessDate,
  ]);

  return null;
};

export default ApiRequestsTimer;
