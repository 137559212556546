import { IAreaChecklist, IActivityItem, IStorePlan } from "../../../types";
import {
  ActionType,
  PreShiftPayload,
  SetPreShiftDoneAction,
  SetPreShiftErrorAction,
  SetPreShiftRequestedAction,
  AreasChecklistPayload,
  SetAreasChecklistDoneAction,
  SetAreasChecklistErrorAction,
  SetAreasChecklistRequestedAction,
  PosShiftPayload,
  SetPosShiftDoneAction,
  SetPosShiftErrorAction,
  SetPosShiftRequestedAction,
  SetChecklistsDataAction,
  MyActivitiesPayload,
  SetMyActivitiesDoneAction,
  SetMyActivitiesErrorAction,
  SetMyActivitiesRequestedAction,
} from "./types";

/** PRESHIFT */

export const setPreShiftRequested = (data: IActivityItem[]): SetPreShiftRequestedAction => {
  return { type: ActionType.setPreShiftRequested, payload: { data } };
};

export const setPreShiftDone = (result: PreShiftPayload): SetPreShiftDoneAction => {
  return { type: ActionType.setPreShiftDone, payload: result };
};

export const setPreShiftError = (): SetPreShiftErrorAction => {
  return { type: ActionType.setPreShiftError };
};

/** POSTSHIFT */

export const setPosShiftRequested = (data: IActivityItem[]): SetPosShiftRequestedAction => {
  return { type: ActionType.setPosShiftRequested, payload: { data } };
};

export const setPosShiftDone = (result: PosShiftPayload): SetPosShiftDoneAction => {
  return { type: ActionType.setPosShiftDone, payload: result };
};

export const setPosShiftError = (): SetPosShiftErrorAction => {
  return { type: ActionType.setPosShiftError };
};

/** AREAS CHECKLIST */

export const setAreasChecklistRequested = (
  data: IAreaChecklist,
  activityTypesId: string,
  areas: string[]
): SetAreasChecklistRequestedAction => {
  return { type: ActionType.setAreasChecklistRequested, payload: { data, activityTypesId, areas } };
};

export const setAreasChecklistDone = (
  result: AreasChecklistPayload
): SetAreasChecklistDoneAction => {
  return { type: ActionType.setAreasChecklistDone, payload: result };
};

export const setAreasChecklistError = (activityTypesId: string): SetAreasChecklistErrorAction => {
  return { type: ActionType.setAreasChecklistError, payload: { activityTypesId } };
};

/** CHECKLISTS DATA */

export const setChecklistsData = (data: IStorePlan): SetChecklistsDataAction => {
  return { type: ActionType.setChecklistsData, payload: { data } };
};

/** MY ACTIVITIES */

export const setMyActivitiesRequested = (data: IActivityItem[]): SetMyActivitiesRequestedAction => {
  return { type: ActionType.setMyActivitiesRequested, payload: { data } };
};

export const setMyActivitiesDone = (result: MyActivitiesPayload): SetMyActivitiesDoneAction => {
  return { type: ActionType.setMyActivitiesDone, payload: result };
};

export const setMyActivitiesError = (): SetMyActivitiesErrorAction => {
  return { type: ActionType.setMyActivitiesError };
};
