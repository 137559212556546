import { AppState } from "./types";

interface State {
  app: AppState;
}

export const appStateSelector = (state: State) => state.app;
export const isAppOnlineSelector = (state: State) => state.app.isAppOnline;
export const isSidebarOpenSelector = (state: State) => state.app.isSidebarOpen;
export const syncRequiredSelector = (state: State) => state.app.syncRequired;
export const syncFailedSelector = (state: State) => state.app.syncFailed;
export const alertSelector = (state: State) => state.app.alert;
export const snackbarSelector = (state: State) => state.app.snackbar;
export const navigateSelector = (state: State) => state.app.navitateController;
export const changelogsReportSelector = (state: State) => state.app.changelogsReport.data || [];
