import { Resource } from "i18next";

import es from "./es.json";
import en from "./en.json";
import pt from "./pt.json";
import fr from "./fr.json";

export type SupportedLanguages = "es" | "en" | "pt" | "fr";

const translations: Record<SupportedLanguages, Resource> = {
  es: { translation: es },
  en: { translation: en },
  pt: { translation: pt },
  fr: { translation: fr },
};

export default translations;
