import { Avatar, Checkbox, Typography, Box, Grid, Paper, useMediaQuery } from "@mui/material";
import { SecondaryText, selectedStyle } from "../../styles";
import { ICustomChecklistItem } from "../../types";
import { sliceText } from "../../helpers/functions";
import { useEffect, useState } from "react";

function getMaxByWidth(width?: number, value?: number) {
  if (!width || !value) return 9999;
  return Math.floor((width * value) / 105);
}

interface Props {
  data: ICustomChecklistItem;
  index: number;
  isSelected: boolean;
  disabled?: boolean;
  onClick: (item?: ICustomChecklistItem) => void;
  onChange: (newData: ICustomChecklistItem) => void;
}

const CustomChecklistCard = ({ data, index, isSelected, disabled, onClick, onChange }: Props) => {
  const isMobile = useMediaQuery("(max-width:480px)");

  const [el, setEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setEl(document.getElementById(`MID-GRID-${index}`));
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    onClick(data);
  };

  const handleChange = (value: boolean) => {
    onChange({ ...data, enabled: value });
  };

  return (
    <Box
      component={Paper}
      p={2}
      sx={isSelected ? selectedStyle : { cursor: "pointer" }}
      onClick={handleClick}
    >
      <Grid container sx={{ opacity: disabled ? 0.6 : 1 }}>
        <Grid item xl={2} lg={2.25} md={2.5}>
          <Avatar>{index}</Avatar>
        </Grid>
        <Grid
          id={`MID-GRID-${index}`}
          item
          xs
          sx={{ overflow: "hidden", flexGrow: 1, pl: isMobile ? 1.5 : 0 }}
        >
          <Typography variant="body1" sx={{ lineHeight: 1.4 }}>
            {sliceText(data.title, getMaxByWidth(el?.clientWidth, 12))}
          </Typography>
          <SecondaryText variant="body2" sx={{ lineHeight: 1.2, fontSize: "0.75rem" }}>
            {sliceText(data.description, getMaxByWidth(el?.clientWidth, 16))}
          </SecondaryText>
        </Grid>
        <Grid item xs={1.2}>
          <Checkbox
            color="secondary"
            checked={data.enabled}
            disabled={disabled}
            onChange={(e, checked) => handleChange(!data.enabled)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomChecklistCard;
