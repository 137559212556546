import React from "react";
import Container from "@mui/material/Container";
import CheckList from "../../components/CheckList";
import { useDispatch, useSelector } from "react-redux";
import { getActivityTypeDescription } from "../../helpers/functions";
import { setPreShiftRequested } from "../../redux/activities/checklists/actions";
import { preShiftDataSelector } from "../../redux/activities/checklists/selectors";
import { activityTypesDataSelector } from "../../redux/storePlan/selectors";
import { IActivityItem } from "../../types";

/**
 * Obtiene los checklist de pre-turno.
 * Se envía y guarda el checklist completo.
 * @activityType PRE
 */

const PreShift = () => {
  const dispatch = useDispatch();
  const activityTypes = useSelector(activityTypesDataSelector);
  const preShiftData = useSelector(preShiftDataSelector);

  const handleSubmit = (data: IActivityItem[]) => {
    dispatch(setPreShiftRequested(data));
  };

  return (
    <Container maxWidth={false}>
      <CheckList
        title={getActivityTypeDescription(activityTypes["PRE"])}
        data={preShiftData}
        onSubmit={handleSubmit}
        showProgress
      />
    </Container>
  );
};

export default PreShift;
