import { IAppParameters } from "../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../utils";
import { Actions, ActionType, StorePlanState } from "./types";

const initialAppParameters: IAppParameters = {
  maxChecklists: 3,
  maxActivities: 10,
};

const initialState: StorePlanState = {
  storePlan: initialRemoteResource(null),
  activityTypes: initialRemoteResource({}),
  objectivesTypes: initialRemoteResource({ objectiveType: [], objectiveItem: [] }),
  hourlyReviewTypes: initialRemoteResource(null),
  appParameters: initialRemoteResource({ ...initialAppParameters }),
  loadingMasterdata: false,
  loadingStorePlan: false,
  loadingHourlyReview: false,
  loadingObjectives: false,
};

/** REDUCER */

const activityReducer = (state: StorePlanState = initialState, action: Actions): StorePlanState => {
  switch (action.type) {
    /** STORE PLAN */
    case ActionType.getStorePlanRequested:
      return {
        ...state,
        storePlan: requestedRemoteResource(null),
        loadingStorePlan: true,
      };
    case ActionType.getStorePlanDone:
      return {
        ...state,
        storePlan: doneRemoteResource(action.payload.storePlan),
        loadingStorePlan: false,
      };
    case ActionType.getStorePlanError:
      return {
        ...state,
        storePlan: errorRemoteResource(null),
        loadingStorePlan: false,
      };
    case ActionType.setStorePlan:
      return {
        ...state,
        storePlan: doneRemoteResource(action.payload.storePlan),
      };
    /** ACTIVITY TYPES */
    case ActionType.getActivityTypesRequested:
      return {
        ...state,
        activityTypes: requestedRemoteResource({}),
        loadingMasterdata: true,
      };
    case ActionType.getActivityTypesDone:
      return {
        ...state,
        activityTypes: doneRemoteResource(action.payload.activityTypes),
        loadingMasterdata: false,
      };
    case ActionType.getActivityTypesError:
      return {
        ...state,
        activityTypes: errorRemoteResource({}),
        loadingMasterdata: false,
      };
    /** OBJECTIVES TYPES */
    case ActionType.getObjectivesTypesRequested:
      return {
        ...state,
        objectivesTypes: requestedRemoteResource({ objectiveType: [], objectiveItem: [] }),
        loadingObjectives: true,
      };
    case ActionType.getObjectivesTypesDone:
      return {
        ...state,
        objectivesTypes: doneRemoteResource(action.payload.objectivesTypes),
        loadingObjectives: false,
      };
    case ActionType.getObjectivesTypesError:
      return {
        ...state,
        objectivesTypes: errorRemoteResource({ objectiveType: [], objectiveItem: [] }),
        loadingObjectives: false,
      };
    /** HOURLY REVIEW TYPES */
    case ActionType.getHourlyReviewTypesRequested:
      return {
        ...state,
        hourlyReviewTypes: requestedRemoteResource(null),
        loadingHourlyReview: true,
      };
    case ActionType.getHourlyReviewTypesDone:
      return {
        ...state,
        hourlyReviewTypes: doneRemoteResource(action.payload.hourlyReviewTypes),
        loadingHourlyReview: false,
      };
    case ActionType.getHourlyReviewTypesError:
      return {
        ...state,
        hourlyReviewTypes: errorRemoteResource(null),
        loadingHourlyReview: false,
      };
    /** APP PARAMETERS */
    case ActionType.getAppParametersRequested:
      return {
        ...state,
        appParameters: requestedRemoteResource({...initialAppParameters}),
      };
    case ActionType.getAppParametersDone:
      return {
        ...state,
        appParameters: doneRemoteResource(action.payload.appParameters),
      };
    case ActionType.getAppParametersError:
      return {
        ...state,
        appParameters: errorRemoteResource({...initialAppParameters}),
      };

    default:
      return state;
  }
};

export default activityReducer;
