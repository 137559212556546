import { IEmployeePositioning } from "../../types";
import {
  ActionType,
  GetLocationsDoneAction,
  GetLocationsErrorAction,
  GetLocationsRequestedAction,
  GetPositioningDoneAction,
  GetPositioningErrorAction,
  GetPositioningRequestedAction,
  LocationsPayload,
  PositioningPayload,
  UpdatePositioningDoneAction,
  UpdatePositioningErrorAction,
  UpdatePositioningPayload,
  UpdatePositioningRequestedAction,
} from "./types";

export const getPositioningRequested = (): GetPositioningRequestedAction => {
  return { type: ActionType.getPositioningRequested };
};

export const getPositioningDone = (result: PositioningPayload): GetPositioningDoneAction => {
  return { type: ActionType.getPositioningDone, payload: result };
};

export const getPositioningError = (): GetPositioningErrorAction => {
  return { type: ActionType.getPositioningError };
};

export const updatePositioningRequested = (
  positioning: IEmployeePositioning
): UpdatePositioningRequestedAction => {
  return { type: ActionType.updatePositioningRequested, payload: { positioning } };
};

export const updatePositioningDone = (
  result: UpdatePositioningPayload
): UpdatePositioningDoneAction => {
  return { type: ActionType.updatePositioningDone, payload: result };
};

export const updatePositioningError = (): UpdatePositioningErrorAction => {
  return { type: ActionType.updatePositioningError };
};

export const getLocationsRequested = (): GetLocationsRequestedAction => {
  return { type: ActionType.getLocationsRequested };
};

export const getLocationsDone = (result: LocationsPayload): GetLocationsDoneAction => {
  return { type: ActionType.getLocationsDone, payload: result };
};

export const getLocationsError = (): GetLocationsErrorAction => {
  return { type: ActionType.getLocationsError };
};
