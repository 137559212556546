import { format } from "date-fns";
import { sosApi } from "../config/axiosInstances";
import { getFrequency, PromiseResult, promiseResultWrapper } from "../helpers/functions";
import { IQueryParams, ISoSDtReport, ISoSFcReport, ISoSMfyReport } from "../types";

export interface ISoSDtReportResponse {
  data: { result_totals: ISoSDtReport[] };
}

export interface ISoSFcReportResponse {
  data: { result_totals: ISoSFcReport[] };
}

export interface ISoSMfyReportResponse {
  data: { result_totals: ISoSMfyReport[] };
}

class SoSServices {
  static async getSoSReport(
    params: IQueryParams,
    service: string
  ): Promise<PromiseResult<ISoSDtReportResponse | ISoSFcReportResponse | ISoSMfyReportResponse>> {
    const { division, country, store, businessDate, shiftStart, shiftEnd } = params;
    const formattedDate = format(businessDate ? new Date(businessDate) : new Date(), "yyyy-MM-dd");
    const queryParams = {
      division,
      country,
      store,
      service,
      groupBy: "country,hour",
      fromDate: formattedDate,
      toDate: formattedDate,
      frequency: getFrequency(shiftStart, shiftEnd),
    };
    return promiseResultWrapper<any>(() => sosApi.get(`${service}`, { params: queryParams }));
  }
}

export default SoSServices;
